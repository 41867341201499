import { createSlice } from "@reduxjs/toolkit";
import { addJadwalAbsensi, addJadwalKhusus, getAllHeaderExcel, getAllJadwalAbsensi,getAllJadwalAbsensiByLembaga, getAllJadwalKhusus } from "../actions/actionJadwalAbsensi";
import { ApiStatus } from "../../constants/apiStatus";

type jadwalState ={
    status : ApiStatus
    isLoading : boolean
    message :any
    allJadwal : TJadwalAbsensi[]
    headerExcel : any
}

const initialState : jadwalState = {
    status : "IDLE",
    isLoading : false,
    message : "",
    allJadwal : [],
    headerExcel : {}
}

export const jadwalAbsensiStore = createSlice({
    name:"jadwalAbsensi",
    initialState,
    reducers : {
        clearJadwal : (state)=>{
            state.allJadwal = []
            state.message = ""
        }
    },
    extraReducers : builder =>{
        builder 
        .addCase(getAllJadwalAbsensi.pending,(state)=>{
            state.isLoading = true
            state.status = "PENDING"
        })
        .addCase(getAllJadwalAbsensi.fulfilled,(state,action)=>{
            state.isLoading = false
            state.status = "SUCCESS"
            state.allJadwal = action.payload
        })
        .addCase(getAllJadwalAbsensi.rejected,(state,action)=>{
            state.isLoading = false
            state.status = "ERROR"
            state.allJadwal = []
            state.message = action.payload
        })
        .addCase(getAllJadwalAbsensiByLembaga.pending,(state)=>{
            state.isLoading = true
            state.status = "PENDING"
        })
        .addCase(getAllJadwalAbsensiByLembaga.fulfilled,(state,action)=>{
            state.isLoading = false
            state.status = "SUCCESS"
            state.allJadwal = action.payload
        })
        .addCase(getAllJadwalAbsensiByLembaga.rejected,(state,action)=>{
            state.isLoading = false
            state.status = "ERROR"
            state.allJadwal = []
            state.message = action.payload
        })
        .addCase(addJadwalAbsensi.pending,(state)=>{
            state.isLoading = true
            state.status = "PENDING"
        })
        .addCase(addJadwalAbsensi.fulfilled,(state,action)=>{
            state.isLoading = false
            state.status = "SUCCESS"
            state.message = action.payload.msg
        })
        .addCase(addJadwalAbsensi.rejected,(state,action)=>{
            state.isLoading = false
            state.status = "ERROR"
            state.message = action.payload
        })
        .addCase(getAllHeaderExcel.pending,(state)=>{
            state.isLoading = true
            state.status = "PENDING"
        })
        .addCase(getAllHeaderExcel.fulfilled,(state,action)=>{
            state.isLoading = false
            state.status = "SUCCESS"
            state.headerExcel = action.payload
        })
        .addCase(getAllHeaderExcel.rejected,(state,action)=>{
            state.isLoading = false
            state.status = "ERROR"
            state.headerExcel = []
            state.message = action.payload
        })
        .addCase(addJadwalKhusus.pending,(state)=>{
            state.isLoading = true
            state.status = "PENDING"
        })
        .addCase(addJadwalKhusus.fulfilled,(state,action)=>{
            state.isLoading = false
            state.status = "SUCCESS"
            state.message=action.payload.msg
        })
        .addCase(addJadwalKhusus.rejected,(state,action)=>{
            state.isLoading = false
            state.status = "ERROR"
            state.message = action.payload
        })
        .addCase(getAllJadwalKhusus.pending,(state)=>{
            state.isLoading = true
            state.status = "PENDING"
        })
        .addCase(getAllJadwalKhusus.fulfilled,(state,action)=>{
            state.isLoading = false
            state.status = "SUCCESS"
            state.allJadwal = action.payload
        })
        .addCase(getAllJadwalKhusus.rejected,(state,action)=>{
            state.isLoading = false
            state.status = "ERROR"
            state.message = action.payload
        })

    }
})

export const jadwalAbsensiActions = jadwalAbsensiStore.actions
export default jadwalAbsensiStore.reducer