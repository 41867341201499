import React, { useEffect, useState } from "react";
import InputSelect from "../elements/InputSelect";
import Message from "../Message";
import Modal from "../Modal";
import { AppThunkDispatch, useAppSelector } from "../../store";
import { useDispatch } from "react-redux";
import {
  getListPenggajian,
  getTaskStatus,
  postApprovalGaji,
} from "../../store/actions/actionGaji";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

function FormApproveGaji({ show, onClose }: TModalProps) {
  const [isMessage, setIsMessage] = useState(false);

  const { status, message } = useAppSelector((state) => state.gaji);
  const { listPenggajian } = useAppSelector((state) => state.gaji);

  const dispatch = useDispatch<AppThunkDispatch>();

  const bulanOpts = listPenggajian.map((i) => {
    return { value: i.bulan, label: i.bulan };
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        bulan: yup.string().required("Bulan belum diisi"),
      })
    ),
  });

  function submitData(data: any) {
    dispatch(postApprovalGaji(data));
    onClose();
    setIsMessage(true);
    setTimeout(() => {
      setIsMessage(false);
    }, 2000);
    setTimeout(() => {
      dispatch(getTaskStatus());
    }, 15000);
  }
  useEffect(() => {
    dispatch(getListPenggajian());
    dispatch(getTaskStatus());
  }, [show, isMessage]);
  return (
    <div>
      <Message status={status} message={message} show={isMessage} />
      <Modal modalName="Persetujuan" show={show} onClose={onClose}>
        <form className="w-full " onSubmit={handleSubmit(submitData)}>
          <InputSelect
            control={control}
            options={bulanOpts}
            Name="bulan"
            labelName="Bulan:"
            placeholder="pilih bulan ..."
            error={errors.bulan?.message}
          />
          <div className=" flex justify-end">
            <button className="btn " type="submit">
              Simpan
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default FormApproveGaji;
