import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiInformasi } from "../../api/apiInformasi";

export const getAllInformasi = createAsyncThunk(
    'ptk/informasi/get',
    async(_,{rejectWithValue})=>{
        try{
            const res = await ApiInformasi.getAll()
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue(err.response.data.msg)
        }
    }
)

export const addInformasi = createAsyncThunk(
    'ptk/informasi/add',
    async(data:any,{rejectWithValue})=>{
        try{
            const res = await ApiInformasi.postInformasi(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue(err.response.data.msg)
        }
    }
)

export const updateInformasi = createAsyncThunk(
    'ptk/informasi/update',
    async(data:any,{rejectWithValue})=>{
        try{
            const res = await ApiInformasi.putInformasi(data.uuid,data.update)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue(err.response.data.msg)
        }
    }
)

export const deleteInformasi = createAsyncThunk(
    'ptk/informasi/delete',
    async(uuid:string,{rejectWithValue})=>{
        try{
            const res = await ApiInformasi.destroyInformasi(uuid)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue(err.response.data.msg)
        }
    }
)