import React, { useEffect, useState } from "react";
import HeaderUI from "../components/HeaderUI";
import TableGaji from "../components/tables/TableGaji";
import { AppThunkDispatch, useAppSelector } from "../store";
import Loading from "../components/Loading";
import { formatCurrency } from "../helpers/formatCurrency";
import { useDispatch } from "react-redux";
import { getTaskStatus } from "../store/actions/actionGaji";

function SimulaiGaji() {
  const stateGaji = useAppSelector((state) => state.gaji);
  const isLoading = stateGaji.isLoading;
  const totalUang = stateGaji?.keuangan?.total;
  const ptk = stateGaji?.keuangan?.ptk
  const [tunjangan, setTunjangan] = useState([])
  const [totalTunjangan, setTotalTunjangan] = useState(0)

  const dispatch = useDispatch<AppThunkDispatch>();

  useEffect(() => {
    dispatch(getTaskStatus());
  }, []);
  useEffect(() => {
    if (tunjangan.length > 0 && ptk && ptk?.length > 0) {
      setTotalTunjangan(0)
      ptk.map((d) => {
        let tunjangan_: any = tunjangan.filter((e: any) => `${e.nupy}` === d.nupy)
        tunjangan_.length > 0 && setTotalTunjangan((e) => e + parseInt(tunjangan_[0]?.nominal))
      })
    }
  }, [tunjangan, ptk])
  return (
    <div>
      <Loading show={isLoading} />
      <HeaderUI
        title="Simulasi Gaji"
        rightTitle={totalUang && tunjangan.length > 0 ? formatCurrency(totalUang + totalTunjangan) : totalUang ? formatCurrency(totalUang) : 0}
      />
      <TableGaji setTunjangan={setTunjangan} />
    </div>
  );
}

export default SimulaiGaji;
