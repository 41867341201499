import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputText from "../elements/InputText";
import { schemaLogin } from "../../constants/schemaInput";
import { useDispatch } from "react-redux";
import { AppThunkDispatch, useAppSelector } from "../../store";
import { onLogin } from "../../store/actions/actionsAuth";
import React, { useState } from "react";
import { HiEye, HiEyeSlash } from "react-icons/hi2";
import Loading from "../Loading";
import Message from "../Message";

function FormLogin() {
  const dispatch = useDispatch<AppThunkDispatch>();
  const [showPassword, setShowPassword] = useState(false);
  const authState = useAppSelector((state) => state.auth);
  const [errMsg, setErrMsg] = useState<string>("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TLoginData>({
    resolver: yupResolver(schemaLogin),
    defaultValues:{sistem:"redagsi"}
  });

  const submitLogin = (data: TLoginData) => {
    dispatch(onLogin(data));
    setErrMsg(authState.message);
    setTimeout(() => {
      setErrMsg("");
    }, 2000);
  };

  return (
    <div className="lg:min-w-[30%] lg:min-h-[500px] rounded-md p-8 shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
      <Loading show={authState.isLoading} />
      <h1 className="text-xl text-center font-semibold font-poppins">
        Redagsi
      </h1>
      <hr className="mt-12" />
      <form
        onSubmit={handleSubmit(submitLogin)}
        className="p-8 flex flex-col gap-2 relative"
      >
        {errMsg && <p className="error">{errMsg}</p>}
        <InputText
          labelName="Username:"
          Name="username"
          type="text"
          {...register("username")}
          error={errors.username?.message}
        />
        <div className="flex relative">
          <InputText
            labelName="Password:"
            Name="password"
            type={showPassword ? "text" : "password"}
            error={errors.password?.message}
            {...register("password")}
          />
          {showPassword ? (
            <HiEye
              className="w-6 h-6 -ml-16 cursor-pointer absolute right-2 mt-8"
              onClick={() => setShowPassword(!showPassword)}
            />
          ) : (
            <HiEyeSlash
              className="w-6 h-6 my-auto -ml-16 cursor-pointer absolute right-2 mt-8"
              onClick={() => setShowPassword(!showPassword)}
            />
          )}
        </div>
        <button className="btn py-1 mt-3" type="submit">
          Sign In
        </button>
        <div>
          <p className="cursor-pointer hover:text-gray-500">Forgot password?</p>
        </div>
      </form>
    </div>
  );
}

export default FormLogin;
