import api from "./api"


export const ApiAbsensi = {
    getRekapAbsensi : ()=>{
        return api.get(`/ptk/absensi/rekap`)
    },
    getRekapAbsensiByKey : (kode_satker:any)=>{
        return api.get(`/ptk/absensi/rekap/${kode_satker}`)
    },
    postUpdateAbsensiByExcel : (data:any)=>{
        return api.post(`/ptk/absensi/download`,data)
    },
    getAbsensi : (data:any) =>{
        return api.post(`/ptk/absensi/daftar-kehadiran`,data)
    },
    getRekapAbsensiAll : (data:any)=>{
        return api.post(`/ptk/rekapBulanan`,data)
    }
}