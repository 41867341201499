import api from "./api"

export const ApiJadwalAbsensi = {
    getJadwal : ()=>{
        return api.get(`/ptk/jadwal`)
    },
    getJadwalByLembaga : (kode_lembaga:string|undefined)=>{
        return api.get(`/ptk/jadwal-lembaga/${kode_lembaga}`)
    },
    postJadwal : (data:any) =>{
        return api.post(`/ptk/jadwal-lembaga`,data)
    },
    getHeaderExcel : (bulan:string,lembaga:string) =>{
        return api.get(`/ptk/jadwal-ptk/days/${bulan}/${lembaga}`)
    },
    addJadwalKhusus : (data:any) => {
        return api.post("/ptk/jadwal-ptk/upload", data)
    },
    getJadwalKhusus:(data:any)=>{
        console.log(data)
        return api.post(`/ptk/jadwal-ptk/jadwal/khusus`,data)
    }
}