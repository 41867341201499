import React, { useEffect, useState } from "react";
import HeaderUI from "../components/HeaderUI";
import Select from "react-select";
import TableContainer from "../components/TableContainer";
import Button from "../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { AppThunkDispatch, useAppSelector } from "../store";
import { allSakter } from "../store/actions/actionSatker";
import FormAddJadwalAbsensi from "../components/forms/FormAddJadwalAbsensi";
import { allLembaga } from "../store/actions/actionOptions";
import { getAllJadwalAbsensiByLembaga } from "../store/actions/actionJadwalAbsensi";
import { dayOptions } from "../constants/options";
import { useNavigate } from "react-router-dom";

function JadwalAbsensi() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppThunkDispatch>();
  const [isOneData, setIsOneData] = useState<any>(null);
  const [saveSatker, setSaveSatker] = useState<any>({});
  const [lembagaOpt, setLembagaOpt] = useState<any>([]);
  const [showFormAddJadwal, setShowFormAddJadwal] = useState(false);
  const { lembagaAll } = useAppSelector((state) => state.options);
  const authState = useAppSelector((state) => state.auth);
  const { allJadwal, status, isLoading } = useAppSelector(
    (state) => state.jadwalAbsensi
  );
  let exceptLembaga: any = [
    "DAPUR",
    "KEAMANAN",
    "UKS",
    "ASRAMA PUTRA",
    "ASRAMA PUTRI",
  ];

  useEffect(() => {
    let lembaga: any = [];
    lembagaAll.map((d: any) => {
      if (!exceptLembaga.includes(d.nama)) {
        lembaga.push({ value: d.kode_lembaga, label: d.nama });
      }
    });
    setLembagaOpt(lembaga);
    lembaga = [];
  }, [lembagaAll]);
  useEffect(() => {
    dispatch(allLembaga());
  }, []);

  useEffect(() => {
    if (
      authState.role === "admin-lembaga" ||
      authState.role === "eksekutif-lembaga"
    ) {
      dispatch(getAllJadwalAbsensiByLembaga("-"));
    } else {
      if (saveSatker) {
        dispatch(getAllJadwalAbsensiByLembaga(saveSatker.value));
      }
    }
  }, [saveSatker, showFormAddJadwal, isOneData]);
  return (
    <div>
      <FormAddJadwalAbsensi
        show={showFormAddJadwal}
        onClose={() => {
          setShowFormAddJadwal(false);
          setIsOneData(null);
        }}
        oneData={isOneData}
        idToEdit={saveSatker.value}
      />
      <HeaderUI title="Jadwal Absensi" />
      <div className=" ">
        <div className="bg-white m-2 py-3 px-2  rounded-md shadow-lg mb-6">
          <div className="flex flex-col md:flex-row justify-between ">
            {authState.role === "superAdmin" ||
            authState.role === "personalia"
             ?
              <div className="w-full md:max-w-[300px] flex flex-col gap-1 ">
                <label htmlFor="bulan">Pilih Lembaga:</label>
                <Select
                  id="satker"
                  placeholder="Pilih Satker"
                  options={lembagaOpt}
                  value={saveSatker}
                  onChange={(i: any) => setSaveSatker(i)}
                />
              </div>
              :
              <></>
            }
            <div className="mx-2 mt-4 flex items-end justify-end gap-3">
              <div>
                <Button
                  title="Jadwal Khusus"
                  type="button"
                  onClick={() => navigate(`/home/absensi/jadwal-khusus`)}
                />
              </div>
              <div>
                <Button
                  title="Tambah Jadwal"
                  type="button"
                  onClick={() => setShowFormAddJadwal(true)}
                />
              </div>
              {allJadwal.length !== 0 ? (
                <div className={``}>
                  <Button
                    title="Update Jadwal"
                    type="button"
                    onClick={() => {
                      setIsOneData(allJadwal);
                      setShowFormAddJadwal(true);
                    }}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <TableContainer>
          <table className="table">
            <thead className="thead">
              <tr>
                <th className="th">No</th>
                <th className="th">Hari</th>
                <th className="th">Masuk</th>
                <th className="th">Pulang</th>
                <th className="th">Aktif</th>
              </tr>
            </thead>
            <tbody className="tbody">
              {allJadwal?.map((d, i) => (
                <tr className="tr" key={i}>
                  <td className="th">{i + 1}</td>
                  <td className="th">{dayOptions[d.day]?.label}</td>
                  <td className="th">{d.start}</td>
                  <td className="th">{d.end}</td>
                  <td className="th">{d.active ? "Aktif" : "Tidak Aktif"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableContainer>
      </div>
    </div>
  );
}

export default JadwalAbsensi;
