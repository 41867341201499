import { createAsyncThunk } from "@reduxjs/toolkit";
import apiListTugasTambahan from "../../api/apiListTugasTambahan";

export const getListTugasTambahan = createAsyncThunk(
    "listTugasTambahan",
    async(_,{rejectWithValue}:any)=>{
        try{
            const res = await apiListTugasTambahan.get()
            if (res.status===200){
                return res.data
            }
        }catch(r){
            return rejectWithValue(r)
        }
    }
)

export const postListTugasTambahan = createAsyncThunk(
    "listTugasTambahan/post",
    async(data:any,{rejectWithValue}:any)=>{
        try{
            const res = await apiListTugasTambahan.post(data)
            if (res.status===200){
                return res.data
            }
        }catch(r){
            return rejectWithValue(r)
        }
    }
)


export const deleteListTugasTambahan = createAsyncThunk(
    "listTugasTambahan/delete",
    async(id:string,{rejectWithValue}:any)=>{
        try{
            const res = await apiListTugasTambahan.delete(id)
            if (res.status===200){
                return res.data
            }
        }catch(r){
            return rejectWithValue(r)
        }
    }
)


export const updateListTugasTambahan = createAsyncThunk(
    "listTugasTambahan/update",
    async(data:any,{rejectWithValue}:any)=>{
        try{
            const res = await apiListTugasTambahan.put(data.id,data.data)
            if (res.status===200){
                return res.data
            }
        }catch(r){
            return rejectWithValue(r)
        }
    }
)