import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import InputText from "../elements/InputText";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Message from "../Message";
import { AppThunkDispatch, useAppSelector } from "../../store";
import { useDispatch } from "react-redux";
import { schemaJadwal } from "../../constants/schemaInput";
import InputSelect from "../elements/InputSelect";
import { dayOptions } from "../../constants/options";
import InputRadio from "../elements/InputRadio";
import {
  addJadwalAbsensi,
  getAllJadwalAbsensiByLembaga,
} from "../../store/actions/actionJadwalAbsensi";

function FormAddJadwalAbsensi({
  show,
  onClose,
  oneData,
  idToEdit,
}: TModalProps) {
  const dispatch = useDispatch<AppThunkDispatch>();
  const [isMessage, setIsMessage] = useState(false);
  const { message, status } = useAppSelector((state) => state.jadwalAbsensi);
  const { lembagaAll } = useAppSelector((state) => state.options);
  const lembagaOpt = lembagaAll.map((d: any) => {
    return { value: d.kode_lembaga, label: d.nama };
  });
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaJadwal),
  });
  const { fields, append, remove, replace, update } = useFieldArray({
    control,
    name: "jadwal",
  });
  const submitData = (e: any) => {
    for (let i = 0; i < e.jadwal.length; i++) {
      const spliterStart = e.jadwal[i].start.split(":");
      const spliterEnd = e.jadwal[i].end.split(":");
      if (spliterStart.length === 2) {
        e.jadwal[i].start = e.jadwal[i].start + ":59";
      }
      if (spliterEnd.length === 2) {
        e.jadwal[i].end = e.jadwal[i].end + ":59";
      }
    }
    dispatch(addJadwalAbsensi(e));
    onClose();
    setIsMessage(true);
    setTimeout(() => {
      setIsMessage(false);
      dispatch(getAllJadwalAbsensiByLembaga(idToEdit));
    }, 2000);
  };
  useEffect(() => {
    if (oneData) {
      replace(oneData);
      for (let i = 0; i < oneData.length; i++) {
        setValue(`jadwal.${i}.active`, oneData[i].active.toString());
      }
    } else {
      replace([]);
    }
  }, [show]);

  return (
    <div>
      <Message message={message} show={isMessage} status={status} />
      <Modal
        modalName="Jadwal Absensi"
        show={show}
        onClose={onClose}
        customWidth="w-[50vw]"
      >
        <form
          action=""
          className="flex flex-col gap-2"
          onSubmit={handleSubmit(submitData)}
        >
          {fields.map((d: any, i) => (
            <div className="grid grid-cols-2 gap-4" key={d.uuid}>
              <InputSelect
                control={control}
                disable={oneData !== null ? true : false}
                options={lembagaOpt}
                placeholder="Pilih lembaga"
                Name={`jadwal.${i}.kode_lembaga`}
                labelName="Lembaga:"
                // error={errors?.jadwal[i]?.kode_lembaga?.message}
              />
              <InputSelect
                control={control}
                disable={oneData !== null ? true : false}
                options={dayOptions}
                placeholder="Pilih hari"
                Name={`jadwal.${i}.day`}
                labelName="Hari:"
                // error={errors?.jadwal[i]?.kode_lembaga?.message}
              />
              <InputText
                type="time"
                step={oneData !== null ? "1" : "0"}
                Name="Datang"
                labelName="Datang"
                {...register(`jadwal.${i}.start`)}
                // error={errors.jadwal[i]?.start?.message}
              />
              <InputText
                type="time"
                step={oneData !== null ? "1" : "0"}
                Name="Pulang"
                labelName="Pulang"
                {...register(`jadwal.${i}.end`)}
                // error={errors.jadwal[i]?.end?.message}
              />
              <div className="">
                <div className="flex flex-col">
                  <label className="label">Hari aktif:</label>
                  <div className="flex flex-row">
                    <InputRadio
                      Name={`jadwal.${i}.active`}
                      control={control}
                      value="true"
                      label="Aktif"
                    />
                    <InputRadio
                      Name={`jadwal.${i}.active`}
                      control={control}
                      value="false"
                      label="Tidak"
                    />
                  </div>
                </div>
              </div>
              <button className="btn" type="button" onClick={() => remove(i)}>
                Hapus
              </button>
            </div>
          ))}
          <div className="mt-3 flex gap-3">
            <button
              className={`${oneData !== null ? "hidden" : ""} btn w-full`}
              type="button"
              onClick={() =>
                append({
                  kode_lembaga: 0,
                  day: 0,
                  start: "",
                  end: "",
                  active: "false",
                })
              }
            >
              Tambah
            </button>
            <button className="btn w-full" type="submit">
              Simpan
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default FormAddJadwalAbsensi;
