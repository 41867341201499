import React from "react";

type ModalProps = {
  modalName: string;
  show: boolean;
  onClose: () => void;
  children: React.ReactNode;
  customWidth?: string
};

function Modal({ modalName, show, onClose, children,customWidth }: ModalProps) {
  const modalRef = React.useRef<HTMLDivElement | null>(null);
  React.useEffect(() => {
    function handleOutsideClick(event: MouseEvent) {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    }

    if (show) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [show, onClose]);
  return (
    <div
      className={`${
        show ? "fixed" : "hidden"
      } inset-0 flex justify-center overflow-y-auto max-w-screen bg-black bg-opacity-50 items-center py-8 p-6 z-20`}
    >
      <div
        ref={modalRef}
        className={`relative  p-4 bg-white  flex flex-col rounded-md my-auto ${customWidth} w-[40vw]`}
      >
        <div className="bg-white absolute left-0 top-0 w-full h-12 z-20 border-b  border-gray-200 rounded-t-md">
          <p className="font-semibold text-lg pl-5 pt-3 text-gray-700">
            {modalName}
          </p>
          <span
            className="cursor-pointer text-gray-700 text-3xl absolute right-5 top-1"
            onClick={onClose}
          >
            &times;
          </span>
        </div>
        <div className="flex flex-col px-3 py-3 h-auto w-full pt-14 scrollbar-hide">
          {children}
        </div>
      </div>
    </div>
  );
}

export default Modal;
