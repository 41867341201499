import React from "react";
import HeaderUI from "../components/HeaderUI";
import Loading from "../components/Loading";
import TableUser from "../components/tables/TableUser";

function UserPage() {
  return (
    <div>
      <Loading show="" />
      <HeaderUI title="List User" />
      <TableUser />
    </div>
  );
}

export default UserPage;
