import React from 'react'

function Input(props: any) {
    return (
        <div className={`flex flex-col w-full relative`}>
            <label className='font-sans text-gray-700'>{props.label}</label>
            <div className='flex'>
                <input max={props.max} step={props.step} type={props.type} className={`py-[7px] px-2 outline-none border border-gray-400 rounded-md mt-1 text-gray-800 hover:border-gray-400 ${props.className}`} readOnly={props.read} value={props.value} onChange={props.onChange} />
                {props.icon}
            </div>
        </div>)
}

export default Input