import React from "react";
type confirmProps = {
  text: string;
  show: boolean;
  onClose: () => void;
  children: React.ReactNode;
};

function Confirm({ text, show, onClose, children }: confirmProps) {
  return (
    <div
      className={`${
        show ? "fixed" : "hidden"
      } inset-0 flex justify-center h-full max-w-screen bg-black bg-opacity-50 items-center p-6 z-50`}
    >
      <div className="relative max-h-full overflow-hidden p-4 bg-white flex flex-col min-w-[200px] max-w-[230px] rounded-md shadow-xl">
        <div className=" absolute left-0 top-0 w-full h-12 z-20 border-b">
          <p className="font-semibold text-lg pl-5 pt-3 ">Konfirmasi</p>
          <span
            className="cursor-pointer text-3xl absolute right-5 top-1"
            onClick={onClose}
          >
            &times;
          </span>
        </div>
        <div className="flex flex-col h-auto w-full pt-14 gap-2 overflow-y-scroll scrollbar-hide">
          <p className="font-normal text-base min-w-full">{text}</p>
          {children}
        </div>
      </div>
    </div>
  );
}

export default Confirm;
