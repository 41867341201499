import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { AppThunkDispatch, useAppSelector } from "../store";
import {
  getAllTitipan,
  getTitipanByFilter,
  postTitipanFromExcel,
} from "../store/actions/actionTitipan";
import TableContainer from "../components/TableContainer";
import { CloudArrowUpIcon, DocumentTextIcon } from "@heroicons/react/24/solid";
import { formatCurrency } from "../helpers/formatCurrency";
import HeaderUI from "../components/HeaderUI";
import Select from "react-select";
import { optionsBulan } from "../constants/options";
import { allSakter } from "../store/actions/actionSatker";
import { getListPenggajian } from "../store/actions/actionGaji";
import FormEditTitipan from "../components/forms/FormEditTitipan";
import Button from "../components/Button";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import Message from "../components/Message";
import Loading from "../components/Loading";

function TitipanPotongan() {
  const refFileInput = useRef<HTMLInputElement>(null);
  const [satker, setSatker] = useState<any>({});
  const [bulan, setBulan] = useState<any>({});
  const [nama, setNama] = useState<string>("");
  const { pegawai, status, message, isLoading } = useAppSelector(
    (state) => state.titipan
  );
  const { satkerAll } = useAppSelector((state) => state.satker);
  const { listPenggajian } = useAppSelector((state) => state.gaji);
  const dispatch = useDispatch<AppThunkDispatch>();
  const [showFormEditTitipan, setShowFormEditTitipan] = useState(false);
  const [isMessage, setIsMessage] = useState(false);
  const [saveOne, setSaveOne] = useState<any>({});
  const [jsonFromExcel, setJsonFromExcel] = useState<any>([]);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const bulanOpt = listPenggajian.map((d: any) => {
    return { value: d.bulan, label: d.bulan };
  });
  const optionSatker = satkerAll.map((i) => {
    return { value: i.kode_satker, label: i.nama_satker };
  });
  optionSatker.splice(0, 0, { value: 0, label: "SEMUA" });
  const downloadExcel = () => {
    let emptyField: any = [
      {
        no: "",
        nupy: "",
        nama: "",
        satker: "",
        nominal: "",
        bulan: bulan.value,
        ket: "",
      },
    ];
    const ws = XLSX.utils.json_to_sheet(emptyField);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(
      data,
      `Rekap titipan ${satker.label}_${bulan.value}` + fileExtension
    );
  };
  const readExcel = (e: any) => {
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (i) => {
        const data = i.target?.result;
        const wb = XLSX.read(data, { type: "array" });
        const sheetName = wb.SheetNames[0];
        const ws = wb.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(ws);
        setJsonFromExcel(json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };
  const uploadExcel = () => {
    dispatch(postTitipanFromExcel({ data: jsonFromExcel }));
    setIsMessage(true);
    setTimeout(() => {
      setIsMessage(false);
    }, 2000);
  };
  useEffect(() => {
    dispatch(allSakter());
    dispatch(getListPenggajian());
  }, []);
  useEffect(() => {
    if (Object.keys(bulan).length !== 0 && Object.keys(satker).length !== 0) {
      dispatch(
        getTitipanByFilter({
          bulan: bulan.value,
          satker: satker.value,
          nama: nama,
        })
      );
    }
  }, [bulan, satker, nama, showFormEditTitipan]);
  return (
    <div>
      <Loading show={isLoading} />
      <Message show={isMessage} message={message} status={status} />
      <FormEditTitipan
        show={showFormEditTitipan}
        onClose={() => setShowFormEditTitipan(false)}
        oneData={saveOne}
      />
      <HeaderUI title="Titipan Potongan" />
      <div className="shadow-[0_3px_10px_rgb(0,0,0,0.2)] mx-7 mt-5 rounded-md flex flex-col md:flex-row">
        <div className="flex flex-1 justify-between gap-4 p-2 w-[100%]">
          <div className=" mb-2 flex flex-col md:flex-row gap-2 md:gap-3 w-full">
            <div className="w-full ">
              <label htmlFor="bulan">Pilih bulan:</label>
              <Select
                id="bulan"
                placeholder="Pilih bulan"
                options={bulanOpt}
                value={bulan}
                onChange={(i) => setBulan(i)}
              />
            </div>
            <div className="w-full ">
              <label htmlFor="satker">Pilih satker:</label>
              <Select
                id="satker"
                placeholder="Pilih satker"
                options={optionSatker}
                value={satker}
                onChange={(i) => setSatker(i)}
              />
            </div>
            <div className="w-full flex flex-col">
              <label htmlFor="nama">Cari nama:</label>
              <input
                id="nama"
                className="border border-gray-300 py-[6px] px-3 rounded-md"
                type="text"
                value={nama}
                onChange={(i) => setNama(i.target.value)}
              />
            </div>
          </div>
        </div>
        <div className=" flex-1 p-3 flex gap-5 items-end justify-end">
          <Button
            type="button"
            title="Donwload Excel"
            onClick={downloadExcel}
          />
        </div>
      </div>
      <div
        className={`mx-6 mt-4 ${jsonFromExcel.length > 0 ? "hidden" : "block"}`}
      >
        <div
          className="p-4 mb-3 flex flex-col items-center gap-2 bg-violet-50 text-slate-800 rounded-lg hover:bg-violet-100 cursor-pointer"
          onClick={() => refFileInput.current?.click()}
        >
          <CloudArrowUpIcon className="w-6 h-6" />
          <span>Upload Excel Titipan</span>
          <input
            type="file"
            ref={refFileInput}
            className="hidden"
            onChange={readExcel}
          />
        </div>
      </div>
      <div className="container-table">
        {jsonFromExcel.length > 0 ? (
          <TableContainer>
            <div className="mb-3">
              <Button
                type="button"
                title="Upload Excel"
                onClick={uploadExcel}
              />
              <Button
                type="button"
                title="Clear Excel"
                onClick={() => setJsonFromExcel([])}
              />
            </div>
            <table className="table">
              <thead className="thead">
                <tr>
                  <th className="th">No</th>
                  <th className="th">Nupy</th>
                  <th className="th">Nama</th>
                  <th className="th">Satker</th>
                  <th className="th">Nominal</th>
                  <th className="th">Bulan</th>
                  <th className="th">Keterangan</th>
                  <th className="th">Edit</th>
                </tr>
              </thead>
              <tbody className="tbody">
                {jsonFromExcel.map((d: any, i: number) => (
                  <tr className="tr" key={i}>
                    <td className="td">{i + 1}</td>
                    <td className="td">{d.nupy}</td>
                    <td className="td">{d.nama}</td>
                    <td className="td">{d?.satker !== "" ? d.satker : "-"}</td>
                    <td className="td">
                      {d?.nominal ? formatCurrency(d.nominal) : "-"}
                    </td>
                    <td className="td">{d?.bulan}</td>
                    <td className="td">{d?.ket}</td>
                    <td className="td">
                      <button>
                        <DocumentTextIcon
                          className="icon"
                          onClick={() => {
                            setShowFormEditTitipan(true);
                            setSaveOne(d);
                          }}
                        />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TableContainer>
        ) : (
          <TableContainer>
            <table className="table">
              <thead className="thead">
                <tr>
                  <th className="th">No</th>
                  <th className="th">Nupy</th>
                  <th className="th">Nama</th>
                  <th className="th">Satker</th>
                  <th className="th">Nominal</th>
                  <th className="th">Bulan</th>
                  <th className="th">Keterangan</th>
                  <th className="th">Edit</th>
                </tr>
              </thead>
              <tbody className="tbody">
                {pegawai?.map((d, i) => (
                  <tr className="tr" key={i}>
                    <td className="td">{i + 1}</td>
                    <td className="td">{d.ptk?.nupy}</td>
                    <td className="td">{d.ptk?.nama}</td>
                    <td className="td">
                      {d?.ptk?.statusPtk
                        ? d.ptk?.statusPtk?.listSatker?.nama_satker
                        : "-"}
                    </td>
                    <td className="td">
                      {d?.nominal ? formatCurrency(d.nominal) : "-"}
                    </td>
                    <td className="td">{d?.bulan}</td>
                    <td className="td">{d?.ket}</td>
                    <td className="td">
                      <button>
                        <DocumentTextIcon
                          className="icon"
                          onClick={() => {
                            setShowFormEditTitipan(true);
                            setSaveOne(d);
                          }}
                        />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TableContainer>
        )}
      </div>
    </div>
  );
}

export default TitipanPotongan;
