import api from "./api"


export const ApiApprovalGaji = {
    getByFilter : (data:TPostApprovalGaji)=>{
        return api.post(`/ptk/gaji-approval`,data)
    },
    postShowMobile : (bulan:string)=>{
        return api.get(`/ptk/gaji-approval/show-mobile/${bulan}`)
    }
}