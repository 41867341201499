import React from "react";
import FormLogin from "../components/forms/FormLogin";
import { useAppSelector } from "../store";

function Login() {
  const state = useAppSelector((state) => state.auth);
  return (
    <div className="min-w-screen min-h-screen flex items-center justify-center">
      <FormLogin />
    </div>
  );
}

export default Login;
