import { createAsyncThunk } from "@reduxjs/toolkit";
import apiApproval from "../../api/apiApproval";

export const getApprovalTugasPokok = createAsyncThunk(
    "approval/getTugasPokok",
    async(_, {rejectWithValue})=>{
        try{
            const res = await apiApproval.pokokGet()
            if(res.status === 200){
                return res.data
            }
        }catch(r){
            return rejectWithValue(r)
        }
    }
)
export const postApprovalTugasPokok = createAsyncThunk(
    "approval/postTugasPokok",
    async(data:any, {rejectWithValue})=>{
        try{
            const res = await apiApproval.pokokPost(data)
            if(res.status === 200){
                return res.data
            }
        }catch(r){
            return rejectWithValue(r)
        }
    }
)

export const getApprovalTugasTambahan = createAsyncThunk(
    "approval/getTugahTambahan",
    async(_, {rejectWithValue})=>{
        try{
            const res = await apiApproval.tambahanGet()
            if(res.status === 200){
                return res.data
            }
        }catch(r){
            return rejectWithValue(r)
        }
    }
)
export const postApprovalTugasTambahan = createAsyncThunk(
    "approval/postTugasTambahan",
    async(data:any, {rejectWithValue})=>{
        try{
            const res = await apiApproval.tambahanPost(data)
            if(res.status === 200){
                return res.data
            }
        }catch(r){
            return rejectWithValue(r)
        }
    }
)