import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiJadwalAbsensi } from "../../api/apiJadwalAbsensi";

export const getAllJadwalAbsensi = createAsyncThunk(
    'ptk/absensi/jadwal/all',
    async(_,{rejectWithValue}:any)=>{
        try{
            const res = await ApiJadwalAbsensi.getJadwal()
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const getAllJadwalAbsensiByLembaga = createAsyncThunk(
    '/ptk/absensi/jadwal/lembaga',
    async(kode_lembaga:string|undefined,{rejectWithValue}:any)=>{
        try{
            const res = await ApiJadwalAbsensi.getJadwalByLembaga(kode_lembaga)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue(err.response.data.msg)
        }
    }
)

export const addJadwalAbsensi = createAsyncThunk(
    'ptk/absensi/jadwa/post',
    async(data:any,{rejectWithValue}:any)=>{
        try{
            const res = await ApiJadwalAbsensi.postJadwal(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue(err.response.data.msg)
        }
    }
)

export const getAllHeaderExcel = createAsyncThunk(
    'ptk/absensi/header/excel',
    async(data:any,{rejectWithValue}:any)=>{
        try{
            const res = await ApiJadwalAbsensi.getHeaderExcel(data.bulan,data.lembaga)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue(err.response.data.msg)
        }
    }
)

export const addJadwalKhusus = createAsyncThunk(
    "ptk/absensi/addJadwalKhusus",
    async(data:any,{rejectWithValue}:any)=>{
        try{
            const res = await ApiJadwalAbsensi.addJadwalKhusus(data)
            if (res.status === 200){
                return res.data
            }
        }catch(r:any){
            return rejectWithValue(r.response.data.msg)
        }
    }
)

export const getAllJadwalKhusus = createAsyncThunk(
    'ptk/jadwal-khusus/by-lembaga',
    async(data:any,{rejectWithValue})=>{
        try{
            console.log(data)
            const res = await ApiJadwalAbsensi.getJadwalKhusus(data)
            if(res.status === 200){
                return res.data
            }
        }catch(r:any){
            return rejectWithValue(r.response?.data?.msg)
        }
    }
)