import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemaApprovalPost } from "../../constants/schemaInput";
import InputRadio from "../elements/InputRadio";
import { useDispatch } from "react-redux";
import { AppThunkDispatch, useAppSelector } from "../../store";
import {
  getApprovalTugasPokok,
  getApprovalTugasTambahan,
  postApprovalTugasPokok,
  postApprovalTugasTambahan,
} from "../../store/actions/actionApproval";
import Message from "../Message";

function FormApproval({ show, onClose, oneData }: TModalProps) {
  const [isMessage, setIsMessage] = useState(false);

  const { message, status } = useAppSelector((state) => state.approval);
  const dispatch = useDispatch<AppThunkDispatch>();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaApprovalPost),
  });
  function submitData(data: any) {
    if ("kode_tugasTambahan" in oneData) {
      dispatch(postApprovalTugasTambahan(data));
    } else {
      dispatch(postApprovalTugasPokok(data));
    }
    onClose();
    setIsMessage(true);
    setTimeout(() => {
      setIsMessage(false);
    }, 2000);
  }
  useEffect(() => {
    reset({
      uuid: oneData?.uuid,
    });
    dispatch(getApprovalTugasPokok());
    dispatch(getApprovalTugasTambahan());
  }, [show, isMessage]);
  return (
    <div>
      <Message status={status} message={message} show={isMessage} />
      <Modal modalName="Persetujuan" show={show} onClose={onClose}>
        <form className="w-full " onSubmit={handleSubmit(submitData)}>
          <div className="flex flex-col gap-2 mb-4">
            <label htmlFor="radio" className="label">
              Persetujuan:
            </label>
            <div id="radio" className="flex flex-row">
              <InputRadio
                Name="status"
                value="true"
                label="Setujui"
                control={control}
              />
              <InputRadio
                Name="status"
                value="false"
                label="Tolak"
                control={control}
              />
            </div>
          </div>
          <div className=" flex justify-end">
            <button className="btn " type="submit">
              Simpan
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default FormApproval;
