import api from "./api";

const apiTitpan = {
  all: () => {
    return api.get("/cakepout/titipan/");
  },
  getByFilter: (data: any) => {
    //data: bulan, satker nama
    return api.post("/cakepout/titipan/filter", data);
  },
  post: (data: any) => {
    return api.post("/cakepout/titipan", data);
  },
  postFromExcel: (data: any) => {
    return api.post("/cakepout/titipan/excel", data);
  },
  update : (uuid:string,data:any)=>{
    return api.update(`/cakepout/titipan/${uuid}`,data)
  }
};

export default apiTitpan;
