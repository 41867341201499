import { createSlice } from "@reduxjs/toolkit"
import { ApiStatus } from "../../constants/apiStatus"
import { getApprovalTugasPokok, getApprovalTugasTambahan, postApprovalTugasPokok, postApprovalTugasTambahan } from "../actions/actionApproval"

type approvalState ={
    isLoading:boolean
    status: ApiStatus
    message:any
    approvalTugasPokok:TApprovalPokok[]
    approvalTugasTambahan:TApprovalTambahan[]
}

const initialState:approvalState = {
    isLoading:false,
    status:"IDLE",
    message:"",
    approvalTugasPokok:[],
    approvalTugasTambahan:[]
}

const approvalSlice = createSlice({
    name:"approval",
    initialState,
    reducers:{},
    extraReducers: builder =>{
        builder
        .addCase(getApprovalTugasPokok.pending,(state)=>{
            state.isLoading = true
            state.status="PENDING"
        })
        .addCase(getApprovalTugasPokok.fulfilled,(state, action)=>{
            state.isLoading = false
            state.status="SUCCESS"
            state.approvalTugasPokok= action.payload
        })
        .addCase(getApprovalTugasPokok.rejected,(state, action)=>{
            state.isLoading = false
            state.status="ERROR"
            state.message = action.error.message
        })
        .addCase(postApprovalTugasPokok.pending,(state)=>{
            state.isLoading = true
            state.status="PENDING"
        })
        .addCase(postApprovalTugasPokok.fulfilled,(state, action)=>{
            state.isLoading = false
            state.status="SUCCESS"
            state.message = action.payload.msg
        })
        .addCase(postApprovalTugasPokok.rejected,(state, action)=>{
            state.isLoading = false
            state.status="ERROR"
            state.message = action.error.message
        })
        .addCase(getApprovalTugasTambahan.pending,(state)=>{
            state.isLoading = true
            state.status="PENDING"
        })
        .addCase(getApprovalTugasTambahan.fulfilled,(state, action)=>{
            state.isLoading = false
            state.status="SUCCESS"
            state.approvalTugasTambahan= action.payload
        })
        .addCase(getApprovalTugasTambahan.rejected,(state, action)=>{
            state.isLoading = false
            state.status="ERROR"
            state.message = action.error.message
        })
        .addCase(postApprovalTugasTambahan.pending,(state)=>{
            state.isLoading = true
            state.status="PENDING"
        })
        .addCase(postApprovalTugasTambahan.fulfilled,(state, action)=>{
            state.isLoading = false
            state.status="SUCCESS"
            state.message=action.payload.msg
        })
        .addCase(postApprovalTugasTambahan.rejected,(state, action)=>{
            state.isLoading = false
            state.status="ERROR"
            state.message = action.error.message
        })
    }
})

export default approvalSlice.reducer