import { createSlice } from "@reduxjs/toolkit"
import { ApiStatus } from "../../constants/apiStatus"
import { onLogin, onLogout } from "../actions/actionsAuth"

// type AuthState = {
//     isLoading:boolean,
//     message:any,
//     status:ApiStatus
//     data: IAuth | null
// }

const initialState = {
    isLoading: false,
    message:"",
    status:"IDLE",
    token:"",
    nama:"",
    username:"",
    role:"",
    sistem:"",
    super_admin:false,
    general_user:false,
}

export const authSlice = createSlice({
    name:"auh",
    initialState,
    reducers:{
        isLogout:(state)=>{
            state.token = ""
            state.nama = ""
            state.username = ""
            state.role = ""
            state.super_admin = false
            state.sistem = ""
            state.general_user = false
        }
    },
    extraReducers:builder=>{
        builder
        .addCase(onLogin.pending,(state)=>{
            state.isLoading=true
            state.status="PENDING"
        })
        .addCase(onLogin.fulfilled,(state,action)=>{
            state.isLoading=false
            state.status= "SUCCESS"
            state.message=""
            state.token = action.payload.token
            state.nama = action.payload.user.nama
            state.username = action.payload.user.username
            state.role = action.payload.user.sistems.find((e:any)=>e.sistem === "redagsi")?.role
            state.super_admin = action.payload.user.sistems.find((e:any)=>e.sistem === "redagsi")?.super_admin
            state.sistem = action.payload.user.sistems.find((e:any)=>e.sistem === "redagsi")?.sistem
            state.general_user = action.payload.user.general_user

        })
        .addCase(onLogin.rejected,(state)=>{
            state.isLoading=false
            state.status="ERROR"
            state.message = "Username or password salah"
        })
        .addCase(onLogout.pending,(state)=>{
            state.isLoading=true
            state.status="PENDING"
        })
        .addCase(onLogout.fulfilled,(state)=>{
            state.isLoading=false
            state.status="SUCCESS"
            state.token = ""
            state.nama = ""
            state.username = ""
            state.role = ""
            state.super_admin = false
            state.sistem = ""
            state.general_user = false
        })
        .addCase(onLogout.rejected,(state)=>{
            state.isLoading=false
            state.status="ERROR"
            state.token = ""
            state.nama = ""
            state.username = ""
            state.role = ""
            state.super_admin = false
            state.sistem = ""
            state.general_user = false
        })

    }
})



export const authActions = authSlice.actions

export default authSlice.reducer