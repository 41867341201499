import { createAsyncThunk } from "@reduxjs/toolkit";
import apiTugasTambahan from "../../api/apiTugasTambahan";

export const getTugasTambahanByUuidPtk = createAsyncThunk(
    "tugasTambahan",
    async(uuid_ptk:any,{rejectWithValue}:any)=>{
        try{
            const res = await apiTugasTambahan.getByUuidPtk(uuid_ptk)
            if(res.status === 200){
                return res.data
            }
        }catch(r){
            return rejectWithValue(r)
        }
    }
)
export const postTugasTambahan = createAsyncThunk(
    "tugasTambahan/post",
    async(data:TTugasTambahan,{rejectWithValue}:any)=>{
        try{
            const res = await apiTugasTambahan.post(data)
            if(res.status === 200){
                return res.data
            }
        }catch(r){
            return rejectWithValue(r)
        }
    }
)
export const updateTugasTambahan = createAsyncThunk(
    "tugasTambahan/update",
    async(data:any,{rejectWithValue}:any)=>{
        try{
            const res = await apiTugasTambahan.put(data)
            if(res.status === 200){
                return res.data
            }
        }catch(r){
            return rejectWithValue(r)
        }
    }
)
export const deleteTugasTambahan = createAsyncThunk(
    "tugasTambahan/delete",
    async(id:string,{rejectWithValue}:any)=>{
        try{
            const res = await apiTugasTambahan.delete(id)
            if(res.status === 200){
                return res.data
            }
        }catch(r){
            return rejectWithValue(r)
        }
    }
)