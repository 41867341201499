import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Modal from "../Modal";
import InputText from "../elements/InputText";
import {
  schemaListTugasTambahan,
  schemaTugasTambahan,
} from "../../constants/schemaInput";
import { useDispatch } from "react-redux";
import { AppThunkDispatch, useAppSelector } from "../../store";
import {
  getListTugasTambahan,
  postListTugasTambahan,
  updateListTugasTambahan,
} from "../../store/actions/actionListTugasTambahan";
import { useEffect, useState } from "react";
import Message from "../Message";

type FormListTugasTambahanProps = {
  show: boolean;
  onClose: () => void;
  dataToUpdate: any;
};

function FormListTugasTambahan({
  show,
  onClose,
  dataToUpdate,
}: FormListTugasTambahanProps) {
  const [isMessage, setIsMessage] = useState(false);
  const dispatch = useDispatch<AppThunkDispatch>();
  const stateData = useAppSelector((state) => state.listTugasTambahan);

  const {
    register,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaListTugasTambahan),
  });

  const onSubmitData = (data: any) => {
    if (dataToUpdate) {
      dispatch(
        updateListTugasTambahan({ id: dataToUpdate.kode_tugasTambahan, data })
      );
    } else {
      dispatch(postListTugasTambahan(data));
    }
    onClose();
    setIsMessage(true);
    setTimeout(() => {
      setIsMessage(false);
    }, 2000);
  };

  useEffect(() => {
    dispatch(getListTugasTambahan());
    if (dataToUpdate) {
      reset(dataToUpdate);
    } else {
      setValue("nama_tugasTambahan", "");
      setValue("nominal", 0);
    }
  }, [isMessage, show]);

  return (
    <div>
      <Modal onClose={onClose} show={show} modalName="Input Tugas Pokok">
        <form action="" onSubmit={handleSubmit(onSubmitData)}>
          <InputText
            {...register("nama_tugasTambahan")}
            placeholder="Tugas tambahan"
            type="text"
            Name="nama_tugasPokok"
            labelName="Nama tugas pokok:"
            error={errors.nama_tugasTambahan?.message}
          />
          <InputText
            Name="nominal"
            {...register("nominal")}
            labelName="Nominal:"
            type="Number"
            placeholder="Nominal"
            error={errors.nominal?.message}
          />
          <div className="pt-4">
            <button className="btn" type="submit">
              {dataToUpdate ? "Update" : "Submit"}
            </button>
          </div>
        </form>
      </Modal>
      <Message
        show={isMessage}
        status={stateData.status}
        message={stateData.message}
      />
    </div>
  );
}

export default FormListTugasTambahan;
