import { DocumentTextIcon, TrashIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useState } from "react";
import { AppThunkDispatch, useAppSelector } from "../../store";
import TableContainer from "../TableContainer";
import { useDispatch } from "react-redux";
import { deletePegawai, searchPegawai } from "../../store/actions/actionPTK";
import Confirm from "../Confirm";
import Message from "../Message";
import FormInputPTK from "../forms/FormInputPTK";
import { useNavigate } from "react-router-dom";
import { clearStatePtk } from "../../store/slices/ptkSlice";

function TablePegawai() {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isConfirm, setIsConfirm] = useState<boolean>(false);
  const [isModalOPen, setIsModalOpen] = useState(false);
  const [isMessage, setIsMessage] = useState(false);
  const [pegawaiUuidToDelete, SetPegawaiUuidToDelete] = React.useState("");
  const authState = useAppSelector((state) => state.auth);

  const navigate = useNavigate();
  const dispatch = useDispatch<AppThunkDispatch>();

  const myState = useAppSelector((state) => state.ptk);

  const Thead = [
    "NUPY",
    "Nama",
    "Alamat",
    "Satker",
    "Tugas Pokok",
    "Status Kepegawaian",
    "Detail",
    "Hapus",
  ];

  function handleSearch(text: string) {
    setSearchQuery(text);
  }
  function handleDelete(uuid: string) {
    setIsConfirm(true);
    SetPegawaiUuidToDelete(uuid);
  }
  function handleConfirmDelete() {
    dispatch(deletePegawai(pegawaiUuidToDelete));
    setIsConfirm(false);
    setIsMessage(true);
  }
  function handleDetailPegawai(uuid: string) {
    navigate(`/home/ptk/pegawai/${uuid}`);
  }
  useEffect(() => {
    if (isMessage) {
      const timer = setTimeout(() => {
        setIsMessage(false);
      }, 2000);

      return () => {
        clearTimeout(timer);
      };
    }

    if (searchQuery.length >= 1) {
      dispatch(searchPegawai(searchQuery));
    } else {
      dispatch(clearStatePtk.clearPtk());
    }
  }, [searchQuery, isMessage]);

  return (
    <div className="overflox-x">
      <FormInputPTK show={isModalOPen} onClose={() => setIsModalOpen(false)} />
      <div className="container-table">
        <TableContainer
          handleSearch={handleSearch}
          searchQuery={searchQuery}
          handleAdd={() => setIsModalOpen(true)}
          btnText={
            authState.role === "superAdmin" || authState.role === "personalia"
              ? "Tambah"
              : ""
          }
        >
          <table className="table table-auto overflow-scroll">
            <thead className="thead">
              <tr className="tr">
                {Thead.map((header) => (
                  <th className="th" key={header}>
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="tbody">
              {myState.pegawai.map((item: TPegawaiType) => (
                <tr className="tr-body" key={item.uuid}>
                  <td className="td">{item.nupy}</td>
                  <td className="td">{item.nama}</td>
                  <td className="td">{item.alamat}</td>
                  <td className="td">
                    {item.statusPtk !== null
                      ? item.statusPtk?.listSatker?.nama_satker
                      : "-"}
                  </td>
                  <td className="td">
                    {item.statusPtk !== null
                      ? item.statusPtk?.listTugasPokok?.nama_tugasPokok
                      : "-"}
                  </td>
                  <td className="td">
                    {item.statusPtk !== null
                      ? item.statusPtk?.status_kepegawaian
                      : "-"}
                  </td>

                  <td className="td">
                    <button onClick={() => handleDetailPegawai(item.uuid)}>
                      <DocumentTextIcon className="w-6 h-6" />
                    </button>
                  </td>
                  <td className="td">
                    <button onClick={() => handleDelete(item.uuid)}>
                      <TrashIcon className="w-6 h-6" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableContainer>
      </div>

      <Confirm
        onClose={() => setIsConfirm(false)}
        show={isConfirm}
        text="Do you want to delete this product?"
      >
        <div className="flex gap-1 items-center justify-center">
          <button className="btn w-full" onClick={handleConfirmDelete}>
            Ya
          </button>
        </div>
      </Confirm>
      <Message
        show={isMessage}
        message={myState.message}
        status={myState.status}
      />
    </div>
  );
}

export default TablePegawai;
