import { createAsyncThunk } from "@reduxjs/toolkit";
import apiTitpan from "../../api/apiTitipan";

export const getAllTitipan = createAsyncThunk(
    "titipan",
    async(_, {rejectWithValue}) =>{
        try{
            const res = await apiTitpan.all()
            if(res.status===200){
                return res.data
            }
        }catch(r){
            return rejectWithValue(r)
        }
    }
)
export const getTitipanByFilter = createAsyncThunk(
    "titipan/getByFilter",
    async(data:TGetTitipanByFilter, {rejectWithValue}) =>{
        try{
            const res = await apiTitpan.getByFilter(data)
            if(res.status===200){
                return res.data
            }
        }catch(r){
            return rejectWithValue(r)
        }
    }
)
export const postTitipan = createAsyncThunk(
    "titipan/post",
    async(data:any, {rejectWithValue}) =>{
        try{
            const res = await apiTitpan.post(data)
            if(res.status===200){
                return res.data
            }
        }catch(r){
            return rejectWithValue(r)
        }
    }
)
export const postTitipanFromExcel = createAsyncThunk(
    "titipan/post/excel",
    async(data:any, {rejectWithValue}) =>{
        try{
            const res = await apiTitpan.postFromExcel(data)
            if(res.status===200){
                return res.data
            }
        }catch(r){
            return rejectWithValue(r)
        }
    }
)

export const updateTitipan = createAsyncThunk(
    "titipan/update",
    async(data:any, {rejectWithValue}) =>{
        try{
            const res = await apiTitpan.update(data.uuid,data.update)
            if(res.status===200){
                return res.data
            }
        }catch(r){
            return rejectWithValue(r)
        }
    }
)