import React from "react";

type Button = {
  type: any;
  onClick?: () => void;
  title: string;
};

function Button({ type, onClick, title }: Button) {
  return (
    <button
      type={type}
      className="px-10 py-2 border border-[#155a7a] rounded-md text-[#104057] hover:bg-[#1e81b0] hover:text-white transition-colors ease-in-out duration-300"
      onClick={onClick}
    >
      {title}
    </button>
  );
}

export default Button;
