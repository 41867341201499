import api from "./api"

const apiPinjaman = {
    getAllPinjaman:()=>{
        return api.get("/cakepout/pinjaman")
    },
    getAllPinjamanByKey:(kode_satker:any)=>{
        return api.get(`/cakepout/pinjaman/${kode_satker}`)
    },
    postPinjaman:(data:any)=>{
        return api.post("/cakepout/pinjaman", data)
    },
    editPinjaman:(uuid:string, data:any)=>{
        return api.update(`/cakepout/pinjaman/${uuid}`, data)
    },
    deletePinjaman:(uuid:string)=>{
        return api.delete(`/cakepout/pinjaman/${uuid}`)
    }
}

export default apiPinjaman