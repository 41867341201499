import { createSlice } from "@reduxjs/toolkit"
import { ApiStatus } from "../../constants/apiStatus"
import { allListPenggajianApprove, getAllListPenggajian, getListGaji, getListGajiBykey, getListPenggajian, getTaskStatus, postAbsensi, postApprovalGaji, postNormalisasi } from "../actions/actionGaji"


type gajiState = {
    isLoading:boolean
    status: ApiStatus
    message:any
    keuangan:TKeuangan | null
    listPenggajian:TListPenggajian[]
    bgTaskStatus: TBgStatusTask | null
}

const initialState:gajiState = {
    isLoading:false,
    status:"IDLE",
    message:"",
    keuangan: null,
    listPenggajian:[],
    bgTaskStatus: null
}

const gajiSlice = createSlice({
    "name":"gajiPtk",
    initialState,
    reducers:{},
    extraReducers: builder =>{
        builder
        .addCase(getListGaji.pending,(state)=>{
            state.isLoading=true
            state.status = "PENDING"
        })
        .addCase(getListGaji.fulfilled,(state, action)=>{
            state.isLoading=false
            state.status="SUCCESS"
            state.keuangan=action.payload
        })
        .addCase(getListGaji.rejected,(state, action)=>{
            state.isLoading=false
            state.status="ERROR"
            state.message=action.error.message
        })
        .addCase(postNormalisasi.pending,(state)=>{
            state.isLoading=true
            state.status = "PENDING"
        })
        .addCase(postNormalisasi.fulfilled, (state, action)=>{
            state.isLoading=false
            state.message = action.payload
            state.status = "SUCCESS"
        })
        .addCase(postNormalisasi.rejected,(state, action)=>{
            state.isLoading=false
            state.message = action.error.message
            state.status = "ERROR"
        })
        .addCase(getAllListPenggajian.pending,(state)=>{
            state.isLoading=true
            state.status = "PENDING"
        })
        .addCase(getAllListPenggajian.fulfilled,(state,action)=>{
            state.isLoading= false
            state.status="SUCCESS"
            state.listPenggajian=action.payload
        })
        .addCase(getAllListPenggajian.rejected,(state,action)=>{
            state.isLoading=false
            state.status = "ERROR"
            state.message = action.error.message
        })
        .addCase(getListPenggajian.pending,(state)=>{
            state.isLoading=true
            state.status = "PENDING"
        })
        .addCase(getListPenggajian.fulfilled,(state,action)=>{
            state.isLoading= false
            state.status="SUCCESS"
            state.listPenggajian=action.payload
        })
        .addCase(getListPenggajian.rejected,(state,action)=>{
            state.isLoading=false
            state.status = "ERROR"
            state.message = action.error.message
        })
        .addCase(allListPenggajianApprove.pending,(state)=>{
            state.isLoading=true
            state.status = "PENDING"
        })
        .addCase(allListPenggajianApprove.fulfilled,(state,action)=>{
            state.isLoading= false
            state.status="SUCCESS"
            state.listPenggajian=action.payload
        })
        .addCase(allListPenggajianApprove.rejected,(state,action)=>{
            state.isLoading=false
            state.status = "ERROR"
            state.message = action.error.message
        })
        .addCase(postAbsensi.pending,(state)=>{
            state.isLoading=true
            state.status = "PENDING"
        })
        .addCase(postAbsensi.fulfilled,(state, action)=>{
            state.isLoading=false
            state.message=action.payload.msg
            state.status ="PENDING"
        })
        .addCase(postAbsensi.rejected,(state, action)=>{
            state.isLoading=false
            state.status="ERROR"
            state.message=action.error.message 
        })
        .addCase(getTaskStatus.pending,(state)=>{
            state.isLoading=true
            state.status = "PENDING"
        })
        .addCase(getTaskStatus.fulfilled,(state,action)=>{
            state.isLoading=false
            state.status = "SUCCESS"
            state.bgTaskStatus = action.payload
        })
        .addCase(getTaskStatus.rejected,(state, action)=>{
            state.isLoading=false
            state.status="ERROR"
            state.message=action.error.message 
        })
        .addCase(postApprovalGaji.pending,(state)=>{
            state.isLoading=true
            state.status = "PENDING"
        })
        .addCase(postApprovalGaji.fulfilled,(state,action)=>{
            state.isLoading=false
            state.status = "SUCCESS"
            state.bgTaskStatus = action.payload
        })
        .addCase(postApprovalGaji.rejected,(state, action)=>{
            state.isLoading=false
            state.status="ERROR"
            state.message=action.error.message 
        })
        .addCase(getListGajiBykey.pending,(state)=>{
            state.isLoading=true
            state.status="PENDING"
        })
        .addCase(getListGajiBykey.fulfilled,(state, action)=>{
            state.isLoading=false
            state.status="SUCCESS"
            state.keuangan = action.payload
        })
        .addCase(getListGajiBykey.rejected,(state, action)=>{
            state.isLoading=false
            state.status="ERROR"
            state.message=action.error.message
        })
    }
})

export const gajiAction = gajiSlice.actions


export default gajiSlice.reducer