import React from "react";
import TableListTugasTambahan from "../components/tables/TableListTugasTambahan";
import { useAppSelector } from "../store";
import Loading from "../components/Loading";
import HeaderUI from "../components/HeaderUI";

function ListTugasTambahan() {
  const loading = useAppSelector((state) => state.listTugasTambahan.isLoading);

  return (
    <div>
      <Loading show={loading} />
      <HeaderUI title="List Tugas Tambahan" />

      <TableListTugasTambahan />
    </div>
  );
}

export default ListTugasTambahan;
