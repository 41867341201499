import { createSlice } from "@reduxjs/toolkit";
import { getAllInformasi,addInformasi,updateInformasi,deleteInformasi } from "../actions/actionsInformasi";
import { ApiStatus } from "../../constants/apiStatus";

type Informasi = {
    allInformasi : string[]
    loadingInformasi : boolean
    msgInformasi:any,
    status : ApiStatus
}

const initialState:Informasi = {
    allInformasi : [],
    loadingInformasi : false,
    msgInformasi : "",
    status : "IDLE"
}

export const informasiStore = createSlice({
    name:"informasi",
    initialState,
    reducers:{
        clearInformasi : (state)=>{
            state.allInformasi = []
            state.msgInformasi = ""
            state.status = "IDLE"
        }
    },
    extraReducers:builder=>{
        builder
        .addCase(getAllInformasi.pending,(state)=>{
            state.loadingInformasi = true
            state.status = "PENDING"
        })
        .addCase(getAllInformasi.fulfilled,(state,action)=>{
            state.loadingInformasi = false
            state.allInformasi = action.payload
            state.status = "SUCCESS"
        })
        .addCase(getAllInformasi.rejected,(state,action)=>{
            state.loadingInformasi = false
            state.allInformasi = []
            state.msgInformasi = action.payload
            state.status = "ERROR"
        })
        .addCase(addInformasi.pending,(state)=>{
            state.loadingInformasi = true
            state.status = "PENDING"
        })
        .addCase(addInformasi.fulfilled,(state,action)=>{
            state.loadingInformasi = false
            state.msgInformasi = action.payload.msg
            state.status = "SUCCESS"
        })
        .addCase(addInformasi.rejected,(state,action)=>{
            state.loadingInformasi = false
            state.msgInformasi = action.payload
            state.status = "ERROR"
        })
        .addCase(updateInformasi.pending,(state)=>{
            state.loadingInformasi = true
            state.status = "PENDING"
        })
        .addCase(updateInformasi.fulfilled,(state,action)=>{
            state.loadingInformasi = false
            state.msgInformasi = action.payload.msg
            state.status = "SUCCESS"
        })
        .addCase(updateInformasi.rejected,(state,action)=>{
            state.loadingInformasi = false
            state.msgInformasi = action.payload
            state.status = "ERROR"
        })
        .addCase(deleteInformasi.pending,(state)=>{
            state.loadingInformasi = true
            state.status = "PENDING"
        })
        .addCase(deleteInformasi.fulfilled,(state,action)=>{
            state.loadingInformasi = false
            state.msgInformasi = action.payload.msg
            state.status = "SUCCESS"
        })
        .addCase(deleteInformasi.rejected,(state,action)=>{
            state.loadingInformasi = false
            state.msgInformasi = action.payload
            state.status = "ERROR"
        })
    }
})

export const informasiActions = informasiStore.actions
export default informasiStore.reducer