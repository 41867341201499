import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiUser } from "../../api/apiUser";

export const allUser = createAsyncThunk(
    'user/get/all',
    async (nama_sistem:string, { rejectWithValue }:any) => {
        try {
            const res : any = await ApiUser.get(nama_sistem)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err:any) {
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const addUser = createAsyncThunk(
    'user/add',
    async (data : any, { rejectWithValue }:any) => {
        try {
            const res : any = await ApiUser.post(data)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err:any) {
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const editSelf = createAsyncThunk(
    'user/self/edit',
    async (data:any, { rejectWithValue }:any) => {
        try {
            const res : any = await ApiUser.updateSelf(data)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err:any) {
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const editByAdmin = createAsyncThunk(
    'user/edit',
    async (data:any, { rejectWithValue }:any) => {
        try {
            const res:any = await ApiUser.update(data.uuid, data.update)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err:any) {
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const dropUser = createAsyncThunk(
    'user/delete',
    async (uuid:string, { rejectWithValue }:any) => {
        try {
            const res :any = await ApiUser.delete(uuid)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err:any) {
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const addSistem = createAsyncThunk(
    'sistem/add',
    async (data:any, { rejectWithValue }:any) => {
        try {
            const res:any = await ApiUser.postSistem(data.uuid, data.post)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err:any) {
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const dropSistem = createAsyncThunk(
    'sistem/delete',
    async (data:any, { rejectWithValue }:any) => {
        try {
            const res:any = await ApiUser.deleteSistem(data.uuid, data.uuid_sis)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err:any) {
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const editSistemOne = createAsyncThunk(
    'sistem/edit/one',
    async (data:any, { rejectWithValue }:any) => {
        try {
            const res = await ApiUser.updateSistem(data.uuid, data.uuid_sistem, data.update)
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err:any) {
            return rejectWithValue(err.response.data.msg)
        }

    }
)