import React from "react";

type InfoProps = {
  textInfo: string;
};

function Info() {
  return (
    <div className="inset shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full min-h-[55vh] z-20 flex justify-center items-center flex-col">
      <div className="w-24 h-24 flex justify-center items-center gap-2">
        <div
          className={`w-5 h-5 rounded-full bg-sky-400 opacity-0 animate-[fading_1s_ease-in-out_infinite]`}
        ></div>
        <div
          className={`w-5 h-5 rounded-full bg-sky-400 opacity-0 animate-[fading_1s_ease-in-out_0.30s_infinite]`}
        ></div>
        <div
          className={`w-5 h-5 rounded-full bg-sky-400 opacity-0 animate-[fading_1s_ease-in-out_0.60s_infinite]`}
        ></div>
      </div>
      <p className="font-semibold text-lg pl-5 pt-3 text-gray-700">
        Sedang memproses data pada background mohon tungu sebentar
      </p>
    </div>
  );
}

export default Info;
