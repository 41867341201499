import HeaderUI from "../components/HeaderUI";
import Loading from "../components/Loading";
import TablePegawai from "../components/tables/TablePegawai";
import { useAppSelector } from "../store";

function PagePTK() {
  const loading = useAppSelector((state) => state.ptk.isLoading);

  return (
    <div className="relative w-full">
      <Loading show={loading} />
      <HeaderUI title="PTK" />
      <div className="">
        <TablePegawai />
      </div>
    </div>
  );
}

export default PagePTK;
