import { createSlice } from "@reduxjs/toolkit";
import { allLembaga, allListLembaga, allRole, allSistem, allThnAjar } from "../actions/actionOptions";
import { ApiStatus } from "../../constants/apiStatus";

type Options ={
    tahunAjar: string[],
    lembagaAll: [],
    listLembagaAll: any,
    sistemAll: any,
    roleAll: any,
    loadingOptions: boolean,
    msgOptions: any
    status : ApiStatus
}

const initialState : Options = {
    tahunAjar : [],
    lembagaAll : [],
    listLembagaAll : [],
    sistemAll : [],
    roleAll : [],
    loadingOptions : false,
    msgOptions : "",
    status : "IDLE"
}

export const optionsStore = createSlice({
    name:"options",
    initialState,
    reducers:{
        clearThn : (state)=>{
            state.tahunAjar = []
            state.msgOptions = ""
        },
        clearLembaga : (state)=>{
            state.lembagaAll = []
            state.msgOptions = ""
        },
        clearSistem : (state)=>{
            state.sistemAll = []
            state.msgOptions = ""
        },
        clearRole : (state)=>{
            state.roleAll = []
            state.msgOptions = ""
        },
        clearListLembaga : (state)=>{
            state.listLembagaAll = []
            state.msgOptions = ""
        }
    },extraReducers: builder =>{
        builder
        .addCase(allThnAjar.pending,(state)=>{
            state.loadingOptions = true
            state.status = "PENDING"
        })
        .addCase(allThnAjar.fulfilled,(state,action)=>{
            state.loadingOptions = false
            state.tahunAjar = action.payload
            state.status = "SUCCESS"
        })
        .addCase(allThnAjar.rejected,(state,action)=>{
            state.loadingOptions = false
            state.tahunAjar = []
            state.msgOptions = action.payload
            state.status = "ERROR"
        })
        .addCase(allLembaga.pending,(state)=>{
            state.loadingOptions = true
            state.status = "PENDING"
        })
        .addCase(allLembaga.fulfilled,(state,action)=>{
            state.loadingOptions = false
            state.lembagaAll = action.payload
            state.status = "SUCCESS"
        })
        .addCase(allLembaga.rejected,(state,action)=>{
            state.loadingOptions = false
            state.lembagaAll = []
            state.msgOptions = action.payload
            state.status = "ERROR"
        })
        .addCase(allSistem.pending,(state)=>{
            state.loadingOptions = true
            state.status = "PENDING"
        })
        .addCase(allSistem.fulfilled,(state,action)=>{
            state.loadingOptions = false
            state.sistemAll = action.payload
            state.status = "SUCCESS"
        })
        .addCase(allSistem.rejected,(state,action)=>{
            state.loadingOptions = false
            state.sistemAll = []
            state.msgOptions = action.payload
            state.status = "ERROR"
        })
        .addCase(allRole.pending,(state)=>{
            state.loadingOptions = true
            state.status = "PENDING"
        })
        .addCase(allRole.fulfilled,(state,action)=>{
            state.loadingOptions = false
            state.roleAll  = action.payload
            state.status = "SUCCESS"
        })
        .addCase(allRole.rejected,(state,action)=>{
            state.loadingOptions = false
            state.roleAll  = []
            state.msgOptions = action.payload
            state.status = "ERROR"
        })
        .addCase(allListLembaga.pending,(state)=>{
            state.loadingOptions = true
            state.status = "PENDING"
        })
        .addCase(allListLembaga.fulfilled,(state,action)=>{
            state.loadingOptions = false
            state.listLembagaAll = action.payload
            state.status = "SUCCESS"
        })
        .addCase(allListLembaga.rejected,(state,action)=>{
            state.loadingOptions = false
            state.listLembagaAll = []
            state.msgOptions = action.payload
            state.status = "ERROR"
        })
    }
})
export const optionsActions = optionsStore.actions
export default optionsStore.reducer