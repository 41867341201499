import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Layout from "./components/Layout";
import Dashboard from "./UI/Dashboard";
import Login from "./UI/Login";
import DetailPegawai from "./UI/DetailPegawai";
import ListSatker from "./UI/ListSatker";
import ListTugasPokok from "./UI/ListTugasPokok";
import ListTugasTambahan from "./UI/ListTugasTambahan";
import TugasTambahan from "./UI/TugasTambahan";
import PagePTK from "./UI/PagePTK";
import Laporan from "./UI/Laporan";
import SimulaiGaji from "./UI/SimulaiGaji";
import UserPage from "./UI/UserPage";
import { useAppSelector } from "./store";
import ProfileUser from "./UI/ProfileUser";
import Pinjaman from "./UI/Pinjaman";
import TitipanPotongan from "./UI/TitipanPotongan";
import Approval from "./UI/Approval";
import ApprovalGaji from "./UI/ApprovalGaji";
import AbsensiEvent from "./UI/AbsensiEvent";
import KegiatanPage from "./UI/KegiatanPage";
import RekapAbsensiPtk from "./UI/RekapAbsensiPtk";
import JadwalAbsensi from "./UI/JadwalAbsensi";
import JadwalKhusus from "./UI/JadwalKhusus";
import DaftarHadir from "./UI/DaftarHadir";
import InformasiPtk from "./UI/InformasiPtk";

function App() {
  const authState = useAppSelector((state) => state.auth);
  return (
    <div className="App">
      <Routes>
        <Route
          path="/home"
          element={authState.token ? <Layout /> : <Navigate to="/" replace />}
        >
          <Route
            path="/home/dashboard"
            element={
              authState.role === "superAdmin" ? (
                <Dashboard />
              ) : (
                <Navigate to="/home/profile" replace />
              )
            }
          />
          <Route
            path="/home/approval"
            element={
              authState.role === "superAdmin" ||
              authState.role === "eksekutif-umum" ? (
                <Approval />
              ) : (
                <Navigate to="/home/profile" replace />
              )
            }
          />
          <Route
            path="/home/laporan"
            element={
              authState.role === "superAdmin" ? (
                <Laporan />
              ) : (
                <Navigate to="/home/profile" replace />
              )
            }
          />
          <Route
            path="/home/gaji"
            element={
              authState.role === "superAdmin" ? (
                <SimulaiGaji />
              ) : (
                <Navigate to="/home/profile" replace />
              )
            }
          />
          <Route
            path="/home/approval/gaji"
            element={
              authState.role === "superAdmin" ? (
                <ApprovalGaji />
              ) : (
                <Navigate to="/home/profile" replace />
              )
            }
          />
          <Route
            path="/home/ptk"
            element={
              authState.role === "superAdmin" ||
              authState.role === "eksekutif-lembaga" ||
              authState.role === "eksekutif-umum" ||
              authState.role === "personalia" ? (
                <PagePTK />
              ) : (
                <Navigate to="/home/profile" replace />
              )
            }
          />
          <Route
            path="/home/pinjaman"
            element={
              authState.role === "superAdmin" ? (
                <Pinjaman />
              ) : (
                <Navigate to="/home/pinjaman" replace />
              )
            }
          />
          <Route
            path="/home/titipan"
            element={
              authState.role === "superAdmin" ? (
                <TitipanPotongan />
              ) : (
                <Navigate to="/home/pinjaman" replace />
              )
            }
          />
          <Route
            path="/home/event/absensi"
            element={
              authState.role === "superAdmin" ? (
                <AbsensiEvent />
              ) : (
                <Navigate to="/home/profile" replace />
              )
            }
          />
          <Route
            path="/home/event/kegiatan"
            element={
              authState.role === "superAdmin" ? (
                <KegiatanPage />
              ) : (
                <Navigate to="/home/profile" replace />
              )
            }
          />
          <Route
            path="/home/absensi/ptk"
            element={
              authState.role === "superAdmin" ||
              authState.role === "admin-lembaga" ||
              authState.role === "eksekutif-lembaga" ? (
                <RekapAbsensiPtk />
              ) : (
                <Navigate to="/home/profile" replace />
              )
            }
          />
          <Route
            path="/home/absensi/jadwal"
            element={
              authState.role === "superAdmin" ||
              authState.role === "admin-lembaga" ||
              authState.role === "eksekutif-lembaga" ||
              authState.role === "personalia" ? (
                <JadwalAbsensi />
              ) : (
                <Navigate to="/home/profile" replace />
              )
            }
          />
          <Route
            path="/home/absensi/jadwal-khusus"
            element={
              authState.role === "superAdmin" ||
              authState.role === "admin-lembaga" ||
              authState.role === "eksekutif-lembaga" ||
              authState.role === "personalia"
               ? (
                <JadwalKhusus />
              ) : (
                <Navigate to="/home/profile" replace />
              )
            }
          />
          <Route
            path="/home/absensi/informasi"
            element={
              authState.role === "superAdmin" ? (
                <InformasiPtk />
              ) : (
                <Navigate to="/home/profile" replace />
              )
            }
          />

          <Route
            path="/home/absensi/daftar-hadir"
            element={
              authState.role === "superAdmin" ||
              authState.role === "admin-lembaga" ||
              authState.role === "eksekutif-lembaga" ||
              authState.role === "personalia" ? (
                <DaftarHadir />
              ) : (
                <Navigate to="/home/profile" replace />
              )
            }
          />
          <Route
            path="/home/list/satker"
            element={
              authState.role === "superAdmin" && authState.super_admin ? (
                <ListSatker />
              ) : authState.role === "superAdmin" && !authState.super_admin ? (
                <Navigate to="/home/dashboard" replace />
              ) : (
                <Navigate to="/home/profile" replace />
              )
            }
          />
          <Route
            path="/home/list/tugas/pokok"
            element={
              authState.role === "superAdmin" && authState.super_admin ? (
                <ListTugasPokok />
              ) : authState.role === "superAdmin" && !authState.super_admin ? (
                <Navigate to="/home/dashboard" replace />
              ) : (
                <Navigate to="/home/profile" replace />
              )
            }
          />
          <Route
            path="/home/list/tugas/tambahan"
            element={
              authState.role === "superAdmin" && authState.super_admin ? (
                <ListTugasTambahan />
              ) : authState.role === "superAdmin" && !authState.super_admin ? (
                <Navigate to="/home/dashboard" replace />
              ) : (
                <Navigate to="/home/profile" replace />
              )
            }
          />
          <Route
            path="/home/list/user"
            element={
              authState.role === "superAdmin" && authState.super_admin ? (
                <UserPage />
              ) : authState.role === "superAdmin" && !authState.super_admin ? (
                <Navigate to="/home/dashboard" replace />
              ) : (
                <Navigate to="/home/profile" replace />
              )
            }
          />
          <Route
            path="/home/list/tugas/tambahan/:uuid"
            element={
              authState.role === "superAdmin" ||
              authState.role === "personalia" ? (
                <TugasTambahan />
              ) : (
                <Navigate to="/home/profile" replace />
              )
            }
          />
          <Route
            path="/home/ptk/pegawai/:uuid"
            element={
              authState.role === "superAdmin" ||
              authState.role === "eksekutif-lembaga" ||
              authState.role === "eksekutif-umum" ||
              authState.role === "personalia" ? (
                <DetailPegawai />
              ) : (
                <Navigate to="/home/profile" replace />
              )
            }
          />
          <Route path="/home/profile" element={<ProfileUser />} />
        </Route>
        <Route
          path="/"
          element={
            authState.token ? (
              <Navigate to="/home/dashboard" replace />
            ) : (
              <Login />
            )
          }
        />
      </Routes>
    </div>
  );
}

export default App;
