import { createSlice } from "@reduxjs/toolkit"
import { ApiStatus } from "../../constants/apiStatus"
import { deletePinjaman, editPinjaman, getPinjaman, getPinjamanByKey, postPinjaman } from "../actions/actionPinjaman"

type pinjamanState = {
    isLoading:boolean
    status: ApiStatus
    message:any
    pegawai:TPinjamanPegwai[]
}

const initialState: pinjamanState = {
    isLoading:false,
    status: "IDLE",
    message:"any",
    pegawai:[],
}

export const pinjamanSlice = createSlice({
    name:"pinjaman",
    initialState,
    reducers:{},
    extraReducers:builder=>{
        builder
        .addCase(getPinjaman.pending,(state)=>{
            state.isLoading=true
            state.status="PENDING"
        })
        .addCase(getPinjaman.fulfilled,(state, action)=>{
            state.isLoading=false
            state.status="SUCCESS"
            state.pegawai = action.payload
        })
        .addCase(getPinjaman.rejected,(state, action)=>{
            state.isLoading=false
            state.status="ERROR"
            state.message = action.error.message
        })
        .addCase(getPinjamanByKey.pending,(state)=>{
            state.isLoading=true
            state.status="PENDING"
        })
        .addCase(getPinjamanByKey.fulfilled,(state, action)=>{
            state.isLoading=false
            state.status="SUCCESS"
            state.pegawai = action.payload
        })
        .addCase(getPinjamanByKey.rejected,(state, action)=>{
            state.isLoading=false
            state.status="ERROR"
            state.message = action.error.message
        })
        .addCase(postPinjaman.pending,(state)=>{
            state.isLoading=true
            state.status="PENDING"
        })
        .addCase(postPinjaman.fulfilled,(state, action)=>{
            state.isLoading=false
            state.status="SUCCESS"
            state.message=action.payload.msg
        })
        .addCase(postPinjaman.rejected,(state, action)=>{
            state.isLoading=false
            state.status="ERROR"
            state.message = action.error.message
        })
        .addCase(editPinjaman.pending,(state)=>{
            state.isLoading=true
            state.status="PENDING"
        })
        .addCase(editPinjaman.fulfilled,(state, action)=>{
            state.isLoading=false
            state.status="SUCCESS"
            state.message=action.payload.msg
        })
        .addCase(editPinjaman.rejected,(state, action)=>{
            state.isLoading=false
            state.status="ERROR"
            state.message = action.error.message
        })
        .addCase(deletePinjaman.pending,(state)=>{
            state.isLoading=true
            state.status="PENDING"
        })
        .addCase(deletePinjaman.fulfilled,(state, action)=>{
            state.isLoading=false
            state.status="SUCCESS"
            state.message=action.payload.msg
        })
        .addCase(deletePinjaman.rejected,(state, action)=>{
            state.isLoading=false
            state.status="ERROR"
            state.message = action.error.message
        })
        
    }
})


export default pinjamanSlice.reducer