import { createSlice } from "@reduxjs/toolkit"
import { ApiStatus } from "../../constants/apiStatus"
import { eventAdd, eventDelete, eventGetAbsensi, eventGetAll, eventSesiAdd, eventSesiAll, eventSesiUpdate, eventUpdate } from "../actions/actionEvent"
import finalPropsSelectorFactory from "react-redux/es/connect/selectorFactory"


type eventState = {
    isLoading:boolean
    status: ApiStatus
    message:any
    kegiatan:TKegiatan[]
    sesiKegiatan:TEventSesi[]
    absensiEvent:TEventAbsensi[]
}

const initialState:eventState = {
    isLoading:false,
    status:"IDLE",
    message:"",
    kegiatan:[],
    sesiKegiatan:[],
    absensiEvent:[]
}

export const eventSlice = createSlice({
    name: "event",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
        .addCase(eventGetAll.pending,(state)=>{
            state.isLoading = true
            state.status = "PENDING"
            
        })
        .addCase(eventGetAll.fulfilled,(state, action)=>{
            state.isLoading = false
            state.status = "SUCCESS"
            state.kegiatan = action.payload
        })
        .addCase(eventGetAll.rejected,(state, action)=>{
            state.isLoading = false
            state.status = "ERROR"
            state.message = action.error.message
        })
        .addCase(eventAdd.pending,(state)=>{
            state.isLoading = true
            state.status = "PENDING"
            
        })
        .addCase(eventAdd.fulfilled,(state, action)=>{
            state.isLoading = false
            state.status = "SUCCESS"
            state.message = action.payload.msg
        })
        .addCase(eventAdd.rejected,(state, action)=>{
            state.isLoading = false
            state.status = "ERROR"
            state.message = action.error.message
        })
        .addCase(eventUpdate.pending,(state)=>{
            state.isLoading = true
            state.status = "PENDING"
            
        })
        .addCase(eventUpdate.fulfilled,(state, action)=>{
            state.isLoading = false
            state.status = "SUCCESS"
            state.message = action.payload.msg
        })
        .addCase(eventUpdate.rejected,(state, action)=>{
            state.isLoading = false
            state.status = "ERROR"
            state.message = action.error.message
        })
        .addCase(eventDelete.pending,(state)=>{
            state.isLoading = true
            state.status = "PENDING"
            
        })
        .addCase(eventDelete.fulfilled,(state, action)=>{
            state.isLoading = false
            state.status = "SUCCESS"
            state.message = action.payload.msg
        })
        .addCase(eventDelete.rejected,(state, action)=>{
            state.isLoading = false
            state.status = "ERROR"
            state.message = action.error.message
        })
        .addCase(eventSesiAll.pending,(state)=>{
            state.isLoading = true
            state.status = "PENDING"
            
        })
        .addCase(eventSesiAll.fulfilled,(state, action)=>{
            state.isLoading = false
            state.status = "SUCCESS"
            state.sesiKegiatan = action.payload
        })
        .addCase(eventSesiAll.rejected,(state, action)=>{
            state.isLoading = false
            state.status = "ERROR"
            state.message = action.error.message
        })
        .addCase(eventSesiAdd.pending,(state)=>{
            state.isLoading = true
            state.status = "PENDING"
            
        })
        .addCase(eventSesiAdd.fulfilled,(state, action)=>{
            state.isLoading = false
            state.status = "SUCCESS"
            state.message = action.payload.msg
        })
        .addCase(eventSesiAdd.rejected,(state, action)=>{
            state.isLoading = false
            state.status = "ERROR"
            state.message = action.error.message
        })
        .addCase(eventSesiUpdate.pending,(state)=>{
            state.isLoading = true
            state.status = "PENDING"
            
        })
        .addCase(eventSesiUpdate.fulfilled,(state, action)=>{
            state.isLoading = false
            state.status = "SUCCESS"
            state.message = action.payload.msg
        })
        .addCase(eventSesiUpdate.rejected,(state, action)=>{
            state.isLoading = false
            state.status = "ERROR"
            state.message = action.error.message
        })
        .addCase(eventGetAbsensi.pending,(state)=>{
            state.isLoading = true
            state.status = "PENDING"
            
        })
        .addCase(eventGetAbsensi.fulfilled,(state, action)=>{
            state.isLoading = false
            state.status = "SUCCESS"
            state.absensiEvent = action.payload
        })
        .addCase(eventGetAbsensi.rejected,(state, action)=>{
            state.isLoading = false
            state.status = "ERROR"
            state.message = action.error.message
        })
    }
}) 

export default eventSlice.reducer