import {AnyAction, configureStore} from "@reduxjs/toolkit"
import { useSelector, TypedUseSelectorHook } from "react-redux";

import {persistStore} from "redux-persist";

import thunk, { ThunkDispatch } from "redux-thunk";
import authReducer from "./slices/authSlice"
import ptkReducer from "./slices/ptkSlice";
import satkerStore from "./slices/satkerSlice";
import addressReducer from "./slices/addressSlice"
import tugasPokokReducer from "./slices/tugasPokokSlice"
import listTugasTambahanReducer from "./slices/listTugasTambahanSlice";
import tugasTambahanReducer  from "./slices/tugasTambahanSlice";
import gajiReducer from "./slices/gajiSlice"
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage"
import absensiEventStore from "./slices/absensiSlice";
import pinjamanReducer from "./slices/pinjamanSlice"
import titipanReducer from "./slices/titipanSlice"
import optionsSlice from "./slices/optionsSlice";
import approvalReducer from "./slices/approvalSlice";
import userSlice from "./slices/userSlice";
import approvalGajiSlice from "./slices/approvalGajiSlice";
import eventReducer from "./slices/eventSlice";
import jadwalAbsensiStore from "./slices/jadwalSlice";
import daftarHarirReducer from "./slices/daftarHadirSlice";
import informasiStore from "./slices/informasiSlice";
import LocationStore from "./slices/locationSlice";



export const store = configureStore({
    reducer:{
        auth: persistReducer({
            key:"ptk",
            storage,
        },authReducer) ,
        ptk: ptkReducer,
        satker : satkerStore,
        address:addressReducer,
        tugasPokok:tugasPokokReducer,
        listTugasTambahan:listTugasTambahanReducer,
        tugasTambahan:tugasTambahanReducer,
        gaji:gajiReducer,
        absensi : absensiEventStore,
        pinjaman:pinjamanReducer,
        titipan: titipanReducer,
        options : optionsSlice,
        approval:approvalReducer,
        user : userSlice,
        approvalGaji : approvalGajiSlice,
        event : eventReducer,
        jadwalAbsensi : jadwalAbsensiStore,
        daftarHarir : daftarHarirReducer,
        informasiPtk : informasiStore,
        location : LocationStore
    },
    middleware:[thunk]
})

export type RootState = ReturnType<typeof store.getState>
export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const persistor = persistStore(store)