import api from "./api";

export const ApiUser = {
    get : (nama_sistem:string) =>{
        return api.get(`/user/${nama_sistem}`)
    },
    post : (data:any) =>{
        return api.post(`/user`,data)
    },
    updateSelf : (data:any) =>{
        return api.update(`/user`,data)
    },
    update : (uuid:string,data:any) =>{
        return api.update(`/user/${uuid}`,data)
    },
    delete : (uuid:string) =>{
        return api.delete(`/user/${uuid}`)
    },
    postSistem : (uuid:string,data:any)=>{
        return api.post(`/user/sistem/${uuid}`,data)
    },
    deleteSistem : (uuid:string,uuid_sis:string)=>{
        return api.delete(`/user/sistem/${uuid}/${uuid_sis}`)
    },
    updateSistem : (uuid:string,uuid_sistem:string,data:any) =>{
        return api.update(`/user/sistem/${uuid}/${uuid_sistem}`,data)
    },
}