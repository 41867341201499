import api from "./api"

const apiGaji = {
    getAll:(bulan:string)=>{
        return api.get(`/ptk/gaji/${bulan}`)
    },
    postNormalisasi:(data:any)=>{
        return api.post("/ptk/gaji", data)
    },
    allListPenggajian:()=>{
        return api.get("cakepout/list-penggajian")
    },
    getLisPenggajian:()=>{
        return api.get("cakepout/list-penggajian/active")
    },
    getLisPenggajianApprove:()=>{
        return api.get("cakepout/list-penggajian/approve/web")
    },
    postAbsensi:(data:any)=>{
        return api.post("/ptk/absensi", data)
    },
    getTask:()=>{
        return api.get("ptk/gaji/recap/task")
    },
    postTask:(data:any)=>{
        return api.post("ptk/gaji/recap/task", data)
    },
    getListPtkGajiByKey:(key:any)=>{
        return api.post("/ptk/gaji/recap", key)
    }
}

export default apiGaji