import { createAsyncThunk } from "@reduxjs/toolkit";
import apiPinjaman from "../../api/apiPinjaman";

export const getPinjaman = createAsyncThunk(
    "pinjaman",
    async(_, {rejectWithValue})=>{
        try{
            const res = await apiPinjaman.getAllPinjaman()
            if (res.status) {
                return res.data
            }
        }catch(r){
            return rejectWithValue(r)
        }
    }
)
export const getPinjamanByKey = createAsyncThunk(
    "pinjaman/getAllByKey",
    async(kode_satker:any, {rejectWithValue})=>{
        try{
            const res = await apiPinjaman.getAllPinjamanByKey(kode_satker)
            if (res.status) {
                return res.data
            }
        }catch(r){
            return rejectWithValue(r)
        }
    }
)
export const postPinjaman = createAsyncThunk(
    "pinjaman/postPinjaman",
    async(data:any, {rejectWithValue})=>{
        try{
            const res = await apiPinjaman.postPinjaman(data)
            if (res.status) {
                return res.data
            }
        }catch(r){
            return rejectWithValue(r)
        }
    }
)
export const editPinjaman = createAsyncThunk(
    "pinjaman/editPinjaman",
    async(data:any, {rejectWithValue})=>{
        try{
            const res = await apiPinjaman.editPinjaman(data.uuid, data.update)
            if (res.status) {
                return res.data
            }
        }catch(r){
            return rejectWithValue(r)
        }
    }
)

export const deletePinjaman = createAsyncThunk(
    "pinjaman/deletePinjaman",
    async(uuid:string, {rejectWithValue})=>{
        try{
            const res = await apiPinjaman.deletePinjaman(uuid)
            if (res.status === 200){
                return res.data
            }
        }catch(r:any){
            return rejectWithValue(r.response.data.msg)
        }
    }
)