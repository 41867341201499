import React from "react";
import { HiOutlineCircleStack, HiOutlineXMark, HiUser } from "react-icons/hi2";
import { Link, useLocation } from "react-router-dom";
import {
  menuAbsen,
  menuAbsenAdminLembaga,
  menuEvent,
  menuPanel,
  menuPtk,
  menuPtkEksekutif,
} from "./Menu";
import {
  HomeIcon,
  PresentationChartBarIcon,
  Square3Stack3DIcon,
  UserCircleIcon,
  WalletIcon,
} from "@heroicons/react/24/solid";
import { useAppSelector } from "../../store";

function SideBar(props: any) {
  const authState = useAppSelector((state) => state.auth);
  const location = useLocation();
  return (
    <div
      className={`md:static fixed md:top-0 top-0 bg-slate-50 md:h-[91vh] h-screen  w-[85vw] md:z-0 z-20 overflow-y-hidden hover:overflow-y-auto transition-all ease-in-out duration-300 border-r-2 ${
        props.sideMenu ? "left-0 md:w-[30vw]" : "-left-[85vw] md:w-0"
      }`}
    >
      <div className="w-full flex justify-end p-3 md:hidden">
        <HiOutlineXMark onClick={props.setSideMenu} className="w-7 h-7" />
      </div>
      <div className="p-4 md:my-5 flex">
        <HiUser className="w-12 h-12 bg-white rounded-full p-2 my-auto" />
        <div>
          <h1 className="mx-3 text-lg font-light">{authState.username}</h1>
        </div>
      </div>
      <div>
        <h1 className="px-6 text-xl text-gray-500 font-light my-2">Menu</h1>
        <Link
          onClick={props.setSideMenu}
          to={`/home/dashboard`}
          className={`${
            authState.role === "superAdmin" ? "block" : "hidden"
          } flex hover:text-sky-600  py-2 px-3 font-light transition-colors ease-in-out duration-300 ${
            location.pathname === "/dashboard"
              ? "border-l-4 border-sky-500 text-sky-700"
              : "border-l-4 border-transparent"
          }`}
        >
          <div className="my-auto">
            <HomeIcon className="icon mx-2" />
          </div>
          <h1 className="text-lg">Dashboard</h1>
        </Link>
        <Link
          onClick={props.setSideMenu}
          to={`/home/approval`}
          className={`${
            authState.role === "superAdmin" ||
            authState.role === "eksekutif-umum"
              ? "block"
              : "hidden"
          }
           flex hover:text-sky-600  py-2 px-3 font-light transition-colors ease-in-out duration-300 ${
             location.pathname === "/dashboard"
               ? "border-l-4 border-sky-500 text-sky-700"
               : "border-l-4 border-transparent"
           }`}
        >
          <div className="my-auto">
            <Square3Stack3DIcon className="icon mx-2" />
          </div>
          <h1 className="text-lg">Approval</h1>
        </Link>
        <Link
          onClick={props.setSideMenu}
          to={`/home/laporan`}
          className={`${
            authState.role === "superAdmin" ||
            authState.role === "admin-lembaga"
              ? "block"
              : "hidden"
          } flex hover:text-sky-600  py-2 px-3 font-light transition-colors ease-in-out duration-300 ${
            location.pathname === "/home/laporan"
              ? "border-l-4 border-sky-500 text-sky-700"
              : "border-l-4 border-transparent"
          }`}
        >
          <div className="my-auto">
            <PresentationChartBarIcon className="icon mx-2" />
          </div>
          <h1 className="text-lg">Laporan</h1>
        </Link>
        <Link
          onClick={props.setSideMenu}
          to={`/home/approval/gaji`}
          className={`${
            authState.role === "superAdmin" ? "block" : "hidden"
          } flex hover:text-sky-600  py-2 px-3 font-light transition-colors ease-in-out duration-300 ${
            location.pathname === "/home/approval/gaji"
              ? "border-l-4 border-sky-500 text-sky-700"
              : "border-l-4 border-transparent"
          }`}
        >
          <div className="my-auto">
            <WalletIcon className="icon mx-2" />
          </div>
          <h1 className="text-lg">Approval Gaji</h1>
        </Link>
        <Link
          onClick={props.setSideMenu}
          to={`/home/gaji`}
          className={`${
            authState.role === "superAdmin" ? "block" : "hidden"
          } flex hover:text-sky-600  py-2 px-3 font-light transition-colors ease-in-out duration-300 ${
            location.pathname === "/home/gaji"
              ? "border-l-4 border-sky-500 text-sky-700"
              : "border-l-4 border-transparent"
          }`}
        >
          <div className="my-auto">
            <WalletIcon className="icon mx-2" />
          </div>
          <h1 className="text-lg">Simulasi Gaji</h1>
        </Link>
        <Link
          onClick={props.setSideMenu}
          to={`/home/profile`}
          className={`flex hover:text-sky-600  py-2 px-3 font-light transition-colors ease-in-out duration-300 ${
            location.pathname === "/home/profile"
              ? "border-l-4 border-sky-500 text-sky-700"
              : "border-l-4 border-transparent"
          }`}
        >
          <div className="my-auto">
            <UserCircleIcon className="icon mx-2" />
          </div>
          <h1 className="text-lg">Profile</h1>
        </Link>
      </div>
      <div>
        {authState.role === "eksekutif-lembaga" ||
        authState.role === "eksekutif-umum" ||
        authState.role === "personalia" ? (
          <div>
            <h1 className={` block px-6 text-xl text-gray-500 font-light my-2`}>
              PTK
            </h1>
            {menuPtkEksekutif.map((e, id) => (
              <Link
                onClick={props.setSideMenu}
                to={`${e.link}`}
                key={id}
                className={` flex hover:text-sky-600  py-2 px-3 font-light transition-colors ease-in-out duration-300 ${
                  location.pathname === e.link
                    ? "border-l-4 border-sky-500 text-sky-700"
                    : "border-l-4 border-transparent"
                }`}
              >
                <div className="my-auto">{e.icon}</div>
                <h1 className="text-lg">{e.title}</h1>
              </Link>
            ))}
          </div>
        ) : (
          <div>
            <h1 className={` block px-6 text-xl text-gray-500 font-light my-2`}>
              PTK
            </h1>
            {menuPtk.map((e, id) => (
              <Link
                onClick={props.setSideMenu}
                to={`${e.link}`}
                key={id}
                className={`${
                  authState.role === "superAdmin" ? "block" : "hidden"
                } flex hover:text-sky-600  py-2 px-3 font-light transition-colors ease-in-out duration-300 ${
                  location.pathname === e.link
                    ? "border-l-4 border-sky-500 text-sky-700"
                    : "border-l-4 border-transparent"
                }`}
              >
                <div className="my-auto">{e.icon}</div>
                <h1 className="text-lg">{e.title}</h1>
              </Link>
            ))}
          </div>
        )}
      </div>
      <div>
        <h1
          className={`${
            authState.role === "superAdmin" ? "block" : "hidden"
          } px-6 text-xl text-gray-500 font-light my-2`}
        >
          Event
        </h1>
        {menuEvent.map((e, id) => (
          <Link
            onClick={props.setSideMenu}
            to={`${e.link}`}
            key={id}
            className={`${
              authState.role === "superAdmin" ? "block" : "hidden"
            } flex hover:text-sky-600  py-2 px-3 font-light transition-colors ease-in-out duration-300 ${
              location.pathname === e.link
                ? "border-l-4 border-sky-500 text-sky-700"
                : "border-l-4 border-transparent"
            }`}
          >
            <div className="my-auto">{e.icon}</div>
            <h1 className="text-lg">{e.title}</h1>
          </Link>
        ))}
      </div>
      <div>
        <div>
          {authState.role === "admin-lembaga" ||
          authState.role === "eksekutif-lembaga" ||
          authState.role === "personalia"
           ? (
            <div>
              <h1 className={` px-6 text-xl text-gray-500 font-light my-2`}>
                Absensi
              </h1>
              {menuAbsenAdminLembaga.map((e, id) => (
                <Link
                  onClick={props.setSideMenu}
                  to={`${e.link}`}
                  key={id}
                  className={` flex hover:text-sky-600  py-2 px-3 font-light transition-colors ease-in-out duration-300 ${
                    location.pathname === e.link
                      ? "border-l-4 border-sky-500 text-sky-700"
                      : "border-l-4 border-transparent"
                  }`}
                >
                  <div className="my-auto">{e.icon}</div>
                  <h1 className="text-lg">{e.title}</h1>
                </Link>
              ))}
            </div>
          ) : (
            <div>
              <h1
                className={`${
                  authState.role === "superAdmin" ? "block" : "hidden"
                } px-6 text-xl text-gray-500 font-light my-2`}
              >
                Absensi
              </h1>
              {menuAbsen.map((e, id) => (
                <Link
                  onClick={props.setSideMenu}
                  to={`${e.link}`}
                  key={id}
                  className={`${
                    authState.role === "superAdmin" ? "block" : "hidden"
                  } flex hover:text-sky-600  py-2 px-3 font-light transition-colors ease-in-out duration-300 ${
                    location.pathname === e.link
                      ? "border-l-4 border-sky-500 text-sky-700"
                      : "border-l-4 border-transparent"
                  }`}
                >
                  <div className="my-auto">{e.icon}</div>
                  <h1 className="text-lg">{e.title}</h1>
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>
      <div>
        <h1
          className={`px-6 text-xl text-gray-500 font-light my-2 ${
            authState.role === "superAdmin" && authState.super_admin
              ? "block"
              : "hidden"
          }`}
        >
          Lainnya
        </h1>
        {menuPanel.map((e: any, id: any) => (
          <div
            className={`${
              authState.role === "superAdmin" && authState.super_admin
                ? "block"
                : "hidden"
            }`}
            key={id}
            onClick={props.setDropUser}
          >
            {e.drop ? (
              <div key={id}>
                <div
                  onClick={props.setRefrensiDrop}
                  className={`flex hover:text-sky-600  py-2 px-3 font-light transition-colors ease-in-out duration-300 cursor-pointer border-l-4 border-transparent `}
                >
                  <div className="my-auto">{e.logo}</div>
                  <h1 className={`text-lg`}>{e.title}</h1>
                  <div className="flex justify-end w-full my-auto">
                    {e.dropIcon}
                  </div>
                </div>
                <div className={props.refrensiDrop ? "block" : "hidden"}>
                  {e.menuDrop.map((d: any, ind: any) => (
                    <Link
                      onClick={props.setSideMenu}
                      to={`${d.link}`}
                      key={ind}
                      className={`flex hover:text-sky-600  py-1 px-9 font-light transition-colors ease-in-out duration-300 ${
                        location.pathname === d.link
                          ? "border-l-4 border-sky-500 text-sky-700"
                          : "border-l-4 border-transparent"
                      }`}
                    >
                      <h1 className={`text-lg`}>{d.title}</h1>
                    </Link>
                  ))}
                </div>
              </div>
            ) : (
              <Link
                onClick={props.setSideMenu}
                to={`${e.link}`}
                key={id}
                className={`flex hover:text-sky-600  py-2 px-3 font-light transition-colors ease-in-out duration-300 ${
                  location.pathname === e.link && e.drop !== props.refrensiDrop
                    ? "border-l-4 border-sky-500 text-sky-700"
                    : "border-l-4 border-transparent"
                } `}
              >
                <div className="my-auto ">{e.icons}</div>
                <h1 className="text-lg">{e.title}</h1>
              </Link>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default SideBar;
