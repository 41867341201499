import {  createSlice } from "@reduxjs/toolkit"
import { getKabupaten, getKecamatan, getProvinsi } from "../actions/actionAdress"

type ApiStatus = 'IDLE' | 'PENDING' | 'SUCCESS' | 'ERROR'
type addressState={
        provinsi: IProvinsiType[]
        kabupaten: IKabupatenType[]
        kecamatan: IKecamatanType[]
        isLoading:boolean
        status: ApiStatus
        message:any
}

const initialState:addressState = {
    provinsi:[],
    kabupaten:[],
    kecamatan:[],
    isLoading:false,
    status: "IDLE",
    message:"any",
}

export const addressSlice = createSlice({
    name:"name",
    initialState,
    reducers:{

    },
    extraReducers: builder=>{
        builder
        .addCase(getProvinsi.pending,(state)=>{
            state.isLoading= true
            state.status="IDLE"
        })
        .addCase(getProvinsi.fulfilled,(state, action)=>{
            state.isLoading=false
            state.provinsi=action.payload
            state.status="SUCCESS"
        })
        .addCase(getProvinsi.rejected,(state, action)=>{
            state.isLoading=false
            state.message=action.error.message
            state.status="ERROR"
        })
        .addCase(getKabupaten.pending,(state)=>{
            state.isLoading= true
            state.status="IDLE"
        })
        .addCase(getKabupaten.fulfilled,(state, action)=>{
            state.isLoading=false
            state.kabupaten=action.payload
            state.status="SUCCESS"
        })
        .addCase(getKabupaten.rejected,(state,action)=>{
            state.isLoading=false
            state.message=action.error.message
            state.status="ERROR"
        })
        .addCase(getKecamatan.pending,(state)=>{
            state.isLoading= true
            state.status="IDLE"
        })
        .addCase(getKecamatan.fulfilled,(state, action)=>{
            state.isLoading=false
            state.kecamatan=action.payload
            state.status="SUCCESS"
        })
        .addCase(getKecamatan.rejected,(state,action)=>{
            state.isLoading=false
            state.message=action.error.message
            state.status="ERROR"
        })
    }
})

export default addressSlice.reducer