import React, { useEffect, useState } from "react";
import TableContainer from "../TableContainer";
import { DocumentTextIcon, TrashIcon } from "@heroicons/react/24/solid";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppThunkDispatch, useAppSelector } from "../../store";
import {
  deleteTugasTambahan,
  getTugasTambahanByUuidPtk,
} from "../../store/actions/actionTugasTambahan";
import FormTugasTambahan from "../forms/FormTugasTambahan";
import Confirm from "../Confirm";
import Message from "../Message";

function TableTugasTambahan() {
  const [dataToUpdate, setDataToUpdate] = useState<any>(null);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isMessage, setIsMessage] = useState(false);
  const [dataToDelete, setDataToDelete] = useState<string>("");
  const [showModal, setShowModal] = useState(false);

  const { uuid } = useParams<string>();
  const stateData = useAppSelector((state) => state.tugasTambahan);
  const dataTugas = stateData.tugasTambahan;

  function handleDelete(id: string) {
    setDataToDelete(id);
    setIsConfirm(true);
  }
  function confirmDelete() {
    dispatch(deleteTugasTambahan(dataToDelete));
    setIsConfirm(false);
    setIsMessage(true);
    setTimeout(() => {
      setIsMessage(false);
    }, 2000);
  }

  const dispatch = useDispatch<AppThunkDispatch>();

  function handleAdd() {
    setShowModal(true);
  }

  useEffect(() => {
    dispatch(getTugasTambahanByUuidPtk(uuid));
  }, [isMessage]);
  return (
    <div>
      <Message
        status={stateData.status}
        message={stateData.message}
        show={isMessage}
      />
      <Confirm
        onClose={() => setIsConfirm(false)}
        show={isConfirm}
        text="Ingin menghapus data ini?"
      >
        <div className="flex gap-1 items-center justify-center">
          <button className="btn w-full" onClick={confirmDelete}>
            Ya
          </button>
        </div>
      </Confirm>
      <FormTugasTambahan
        dataToUpdate={dataToUpdate}
        show={showModal}
        onClose={() => {
          setShowModal(false);
          setDataToUpdate(null);
        }}
      />
      <div className="md:px-3 py-3">
        <TableContainer btnText="Tambah" handleAdd={handleAdd}>
          <table className="table">
            <thead className="thead">
              <tr className="tr">
                <th className="th">No</th>
                <th className="th">Kode Tugas</th>
                <th className="th">Nama Tugas</th>
                <th className="th">Edit</th>
                <th className="th">Hapus</th>
              </tr>
            </thead>
            <tbody className="tbody">
              {dataTugas.map((d, i) => (
                <tr className="tr" key={i}>
                  <td className="td">{i + 1}</td>
                  <td className="td">{d.kode_tugasTambahan}</td>
                  <td className="td">
                    {d.listTugasTambahan.nama_tugasTambahan}
                  </td>
                  <td className="td">
                    <button
                      onClick={() => {
                        setShowModal(true);
                        setDataToUpdate(d);
                      }}
                    >
                      <DocumentTextIcon className="icon" />
                    </button>
                  </td>
                  <td className="td">
                    <button onClick={() => handleDelete(`${d.uuid}`)}>
                      <TrashIcon className="icon" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableContainer>
      </div>
    </div>
  );
}

export default TableTugasTambahan;
