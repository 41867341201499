import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router-dom";
import InputText from "../elements/InputText";
import Message from "../Message";
import { schemaPinjamanPtk } from "../../constants/schemaInput";
import { useDispatch } from "react-redux";
import { AppThunkDispatch, useAppSelector } from "../../store";
import { postPinjaman } from "../../store/actions/actionPinjaman";

function FormInputCredit({ show, onClose }: TModalProps) {
  const [isMessage, setIsMessage] = useState(false);
  const { uuid } = useParams<{ uuid: string }>();
  const {message,status} = useAppSelector(state=>state.pinjaman)

  const dispatch = useDispatch<AppThunkDispatch>();

  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaPinjamanPtk),
  });

  function submitData(data: any) {
    dispatch(postPinjaman(data));
    onClose();
    setIsMessage(true);
    setTimeout(() => {
      setIsMessage(false);
    }, 2000);
  }

  useEffect(() => {
    reset({
      uuid: uuid,
    });
  }, [show]);
  return (
    <div>
      <Modal modalName="Credit" show={show} onClose={onClose}>
        <form
          className="flex flex-col gap-2"
          action=""
          onSubmit={handleSubmit(submitData)}
        >
          <InputText
            Name="credit"
            labelName="Nominal:"
            placeholder="Nominal pinjaman"
            type="number"
            {...register("nominal")}
            error={errors.nominal?.message}
          />
          <InputText
            Name="potongan"
            labelName="Potongan:"
            placeholder="Jumlah potongan"
            type="number"
            {...register("potongan")}
            error={errors.potongan?.message}
          />
          <InputText
            Name="ket"
            labelName="Keterangan:"
            placeholder="Keterangan"
            type="text"
            {...register("ket")}
            error={errors.ket?.message}
          />

          <button type="submit" className="btn">
            Simpan
          </button>
        </form>
      </Modal>
      <Message message={message} status={status} show={isMessage} />
    </div>
  );
}

export default FormInputCredit;
