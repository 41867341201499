import api from "./api";

export const ApiInformasi = {
    getAll:()=>{
        return api.get(`/ptk/informasi-ptk`)
    },
    postInformasi:(data:any)=>{
        return api.post(`/ptk/informasi-ptk`,data)
    },
    putInformasi : (uuid:string,data:any)=>{
        return api.update(`/ptk/informasi-ptk/${uuid}`,data)
    },
    destroyInformasi : (uuid:string)=>{
        return api.delete(`/ptk/informasi-ptk/${uuid}`)
    }
}