import React, { useEffect, useState } from "react";
import TableContainer from "../TableContainer";
import { useDispatch } from "react-redux";
import { AppThunkDispatch, useAppSelector } from "../../store";
import { getApprovalTugasPokok } from "../../store/actions/actionApproval";
import FormApproval from "../forms/FormApproval";

function TableApprovalPokok() {
  const [isApproval, setIsApproval] = useState(false);
  const [dataToUpdate, setDataToUpdate] = useState<any>({});
  const dispatch = useDispatch<AppThunkDispatch>();
  const { approvalTugasPokok } = useAppSelector((state) => state.approval);

  useEffect(() => {
    dispatch(getApprovalTugasPokok());
  }, []);
  return (
    <div>
      <FormApproval
        show={isApproval}
        oneData={dataToUpdate}
        onClose={() => {
          setIsApproval(false);
          setDataToUpdate({});
        }}
      />

      <TableContainer tableTitle="Tugas pokok">
        <table className="table">
          <thead className="thead">
            <tr>
              <th className="th">Nama</th>
              <th className="th">status</th>
              <th className="th">Pegawai</th>
              <th className="th">Action</th>
            </tr>
          </thead>
          <tbody className="tbody">
            {approvalTugasPokok.map((d, i) => (
              <tr className="tr" key={i}>
                <td className="td">{d.listTugasPokok.nama_tugasPokok}</td>
                <td className="td">
                  {d.status === true ? "Disetujui" : "Belum disetujui"}
                </td>
                <td className="td">{d.ptk.nama}</td>
                <td className="td">
                  <button
                    className="btn"
                    onClick={() => {
                      setIsApproval(true);
                      setDataToUpdate(d);
                    }}
                  >
                    Tanggapi
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>
    </div>
  );
}

export default TableApprovalPokok;
