import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiSatker } from "../../api/apiSatker";


export const allSakter = createAsyncThunk<[]>(
    'satker/all',
    async(_,{rejectWIthValue}:any)=>{
        try{
            const res = await ApiSatker.getAll()
            if(res.status === 200){
                return res.data
            }
        }
        catch(r){
            return rejectWIthValue(r)
        }
    }
)
export const postSatker =  createAsyncThunk<any,any>(
    'satker/post',
    async(data,{rejectWithValue}:any)=>{
        try{
            const res = await ApiSatker.post(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(r){
            return rejectWithValue(r)
        }
    }
)
export const updateSatker = createAsyncThunk(
    'satker/update',
    async(data:any,{rejectWithValue}:any)=>{
        try{
            const res = await ApiSatker.put(data.id,data.data)
            if(res.status === 200){
                
                return res.data
            }
        }
        catch(r){
            return rejectWithValue(r)
        }
    }
)
export const deleteSatker = createAsyncThunk(
    'satker/delete',
    async(id:string,{rejectWithValue}:any)=>{
        try{
            const res = await ApiSatker.delete(id)
            if(res.status === 200){
                return res.data
            }
        }
        catch(r){
            return rejectWithValue(r)
        }
    }
)