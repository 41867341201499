import { HiUser, HiOutlineChevronDown } from "react-icons/hi2";
import { AiOutlineControl } from "react-icons/ai";
import { BsCalendar2Date, BsInfoCircle } from "react-icons/bs";
import { MdDateRange } from "react-icons/md";
import { FaHandHolding, FaMoneyBillWave } from "react-icons/fa";

let store: any;
export const initStore = (_store: any) => {
  store = _store;
};

export const menuPtk = [
  {
    link: "/home/ptk",
    title: "Ptk",
    icon: <HiUser className="w-6 h-6 mx-2 cursor-pointer" />,
  },
  {
    link: "/home/pinjaman",
    title: "Pinjaman",
    icon: <FaMoneyBillWave className="w-6 h-6 mx-2 cursor-pointer" />,
  },
  {
    link: "/home/titipan",
    title: "Titipan",
    icon: <FaHandHolding className="w-6 h-6 mx-2 cursor-pointer" />,
  },
];

export const menuPtkEksekutif = [
  {
    link: "/home/ptk",
    title: "Ptk",
    icon: <HiUser className="w-6 h-6 mx-2 cursor-pointer" />,
  },
];
export const menuPanel = [
  {
    title: "Panel",
    logo: <AiOutlineControl className="w-6 h-6 mx-2" />,
    dropIcon: <HiOutlineChevronDown className="w-5 h-5" />,
    drop: true,
    menuDrop: [
      { link: "/home/list/satker", title: "List Satker" },
      { link: "/home/list/user", title: "List user" },
      { link: "/home/list/tugas/pokok", title: "List Tugas Pokok" },
      { link: "/home/list/tugas/tambahan", title: "List Tugas Tambahan" },
    ],
  },
];

export const menuEvent = [
  {
    link: "/home/event/absensi",
    title: "Absensi",
    icon: <HiUser className="w-6 h-6 mx-2 cursor-pointer" />,
  },
  {
    link: "/home/event/kegiatan",
    title: "Kegiatan",
    icon: <MdDateRange className="w-6 h-6 mx-2 cursor-pointer" />,
  },
];

export const menuAbsen = [
  {
    link: "/home/absensi/ptk",
    title: "Absensi",
    icon: <HiUser className="w-6 h-6 mx-2 cursor-pointer" />,
  },
  {
    link: "/home/absensi/jadwal",
    title: "Jadwal",
    icon: <BsCalendar2Date className="w-6 h-6 mx-2 cursor-pointer" />,
  },
  {
    link: "/home/absensi/daftar-hadir",
    title: "Daftar Hadir",
    icon: <BsCalendar2Date className="w-6 h-6 mx-2 cursor-pointer" />,
  },
  {
    link: "/home/absensi/informasi",
    title: "Informasi",
    icon: <BsInfoCircle className="w-6 h-6 mx-2 cursor-pointer" />,
  },
];

export const menuAbsenAdminLembaga = [
  {
    link: "/home/absensi/jadwal",
    title: "Jadwal",
    icon: <BsCalendar2Date className="w-6 h-6 mx-2 cursor-pointer" />,
  },
  {
    link: "/home/absensi/daftar-hadir",
    title: "Daftar Hadir",
    icon: <BsCalendar2Date className="w-6 h-6 mx-2 cursor-pointer" />,
  },
];
