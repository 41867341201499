import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Modal from "../Modal";
import InputSelect from "../elements/InputSelect";
import { schemaTugasTambahan } from "../../constants/schemaInput";
import { useDispatch } from "react-redux";
import { AppThunkDispatch, useAppSelector } from "../../store";
import {
  getTugasTambahanByUuidPtk,
  postTugasTambahan,
  updateTugasTambahan,
} from "../../store/actions/actionTugasTambahan";
import { useEffect, useState } from "react";
import Message from "../Message";
import { getListTugasTambahan } from "../../store/actions/actionListTugasTambahan";
import { useParams } from "react-router-dom";

type FormTugasTambahanProps = {
  show: boolean;
  onClose: () => void;
  dataToUpdate: any;
};

function FormTugasTambahan({
  show,
  onClose,
  dataToUpdate,
}: FormTugasTambahanProps) {
  const { uuid } = useParams<string>();
  const [isMessage, setIsMessage] = useState(false);

  const dispatch = useDispatch<AppThunkDispatch>();
  const stateData = useAppSelector((state) => state.tugasTambahan);
  const listTugas = useAppSelector(
    (state) => state.listTugasTambahan.listTugasTambahan
  );

  const {
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaTugasTambahan),
  });
  const onSubmitData = (data: any) => {
    if (dataToUpdate) {
      dispatch(updateTugasTambahan(data));
    } else {
      dispatch(postTugasTambahan(data));
    }
    onClose();
    setIsMessage(true);
    setTimeout(() => {
      setIsMessage(false);
    }, 2000);
  };
  const optionsKodeTugasTambahan = listTugas.map((i) => {
    return { value: i.kode_tugasTambahan, label: i.nama_tugasTambahan };
  });
  useEffect(() => {
    dispatch(getTugasTambahanByUuidPtk(uuid));
    if (show) {
      dispatch(getListTugasTambahan());
      if (dataToUpdate) {
        reset(dataToUpdate);
      } else {
        reset({
          uuid_ptk: uuid,
        });
        setValue("kode_tugasTambahan", "");
      }
    }
  }, [show, isMessage]);

  return (
    <div>
      <Message
        status={stateData.status}
        show={isMessage}
        message={stateData.message}
      />
      <Modal onClose={onClose} show={show} modalName="Tugas Tambahan">
        <form action="" onSubmit={handleSubmit(onSubmitData)}>
          <InputSelect
            control={control}
            options={optionsKodeTugasTambahan}
            placeholder="Pilih tugas"
            Name="kode_tugasTambahan"
            labelName="Tugas tambahan:"
            error={errors.kode_tugasTambahan?.message}
          />
          <div className="pt-4 flex justify-end">
            <button className="btn" type="submit">
              Simpan
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default FormTugasTambahan;
