import React, { useEffect, useState } from "react";
import InputSelect from "../elements/InputSelect";
import Message from "../Message";
import Modal from "../Modal";
import { AppThunkDispatch, useAppSelector } from "../../store";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputArea from "../elements/inputArea";
import InputRadio from "../elements/InputRadio";
import InputText from "../elements/InputText";
import { addInformasi, getAllInformasi, updateInformasi } from "../../store/actions/actionsInformasi";

function FormInformasi({ show, onClose, oneData }: TModalProps) {
  const [isMessage, setIsMessage] = useState(false);
  const { msgInformasi, status, loadingInformasi } = useAppSelector(state => state.informasiPtk)
  const dispatch = useDispatch<AppThunkDispatch>();

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        title: yup.string().required("Informasi belum di isi"),
        informasi: yup.string().required("Informasi belum di isi"),
        statusInformasi: yup.string().oneOf(["true", "false"], "Pilih salah satu").required("status belum diisi"),
      })
    ),
  });
  function submitData(data: any) {
    if (Object.keys(oneData).length === 0) {
      dispatch(addInformasi(data))
    }
    else {
      dispatch(updateInformasi({ uuid: oneData?.uuid, update: data }))
    }
    onClose()
    setIsMessage(true);
    setTimeout(() => {
      setIsMessage(false);
      dispatch(getAllInformasi())
    }, 2000);
  }
  useEffect(() => {
    if (Object.keys(oneData).length !== 0) {
      reset({title:oneData?.title, informasi: oneData?.informasi, statusInformasi: oneData?.statusInformasi.toString() })
    }
    else {
      reset({title:"",informasi: "", statusInformasi: "false" })
    }
  }, [show, oneData]);
  return (
    <div>
      <Message status={status} message={msgInformasi} show={isMessage} />
      <Modal modalName="Informasi" show={show} onClose={onClose}>
        <form className="w-full " onSubmit={handleSubmit(submitData)}>
          {/* <InputArea
            col={20}
            row={5}
            Name="Informasi"
            labelName="Info:"
            {...register("informasi")}
            error={errors.informasi?.message}
          /> */}
          <InputText
            Name="Title"
            labelName="Title:"
            {...register("title")}
            error={errors.title?.message}
          />
          <InputText
            Name="informasi"
            labelName="Informasi:"
            {...register("informasi")}
            error={errors.informasi?.message}
          />
          <div className="flex flex-col gap-2 mb-4">
            <label htmlFor="radio" className="label">
              Status:
            </label>
            <div id="radio" className="flex flex-row">
              <InputRadio
                Name="statusInformasi"
                value="true"
                label="Tampilkan"
                control={control}
              />
              <InputRadio
                Name="statusInformasi"
                value="false"
                label="Tidak"
                control={control}
              />
            </div>
          </div>
          <div className=" flex justify-end">
            <button className="btn " type="submit">
              Simpan
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default FormInformasi;
