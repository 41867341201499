import axios, { AxiosInstance, AxiosRequestConfig } from "axios"
import { onLogout } from "../store/actions/actionsAuth";

const axiosInstance  = axios.create({
    // baseURL: "http://127.0.0.1:9999",
    // baseURL: "http://192.168.141.232:9999",
    baseURL:"https://sipahamv2.ponpesabuhurairah.id"
    // baseURL: "http://192.168.1.21:9999",
    // baseURL: "http://192.168.1.12:9999"
    // baseURL: "http://192.168.248.232:9999"
    //  baseURL: "https://3236-103-80-82-10.ngrok-free.app",
    // headers:{
    //     "ngrok-skip-browser-warning":true
    // }
})

const api = (axios:AxiosInstance) =>{
    return {
        get:(url:string, config:AxiosRequestConfig ={}) => axios.get(url, config),
        delete:(url:string, config:AxiosRequestConfig ={}) => axios.delete(url, config),
        post: (url: string, body:unknown, config:AxiosRequestConfig ={}) => axios.post(url, body, config),
        update: (url: string, body:unknown, config:AxiosRequestConfig ={}) => axios.put(url, body, config),
        patch:(url: string, body:unknown, config:AxiosRequestConfig ={}) => axios.patch(url, body, config),
    }
}

const apiInstance = api(axiosInstance);

let store:any;
export const injectStore = (_store: any) => {
  store = _store
}

axiosInstance.interceptors.request.use((req:any) => {
    if (!req.url.includes("/login")) {
      req.headers = {
        Authorization: `Bearer ${store.getState().auth.token}`,
      };
    }
  return req;
});

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err.response.status === 401 && store.getState().auth.token !== "") {
     store.dispatch(onLogout())
    }
    return Promise.reject(err);
  }
);

export default apiInstance