import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import InputText from "../elements/InputText";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Message from "../Message";
import { AppThunkDispatch, useAppSelector } from "../../store";
import { useDispatch } from "react-redux";
import {
  eventGetAll,
  eventSesiAdd,
  eventSesiAll,
  eventSesiUpdate,
} from "../../store/actions/actionEvent";

const schemaSesiKegiatan = yup.object().shape({
  uuid_event: yup.string().required("Uuid event Kegiatan belum diisi"),
  ket: yup.string().required("Keterangan Kegiatan belum diisi"),
  start_time: yup.string().required("Waktu mulai Kegiatan belum diisi"),
  end_time: yup.string().required("Waktu selesai Kegiatan belum diisi"),
});

function FormAddSesiKegiatan({
  show,
  onClose,
  idToEdit,
  oneData,
}: TModalProps) {
  const [isMessage, setIsMessage] = useState(false);

  const { message, isLoading, status } = useAppSelector((state) => state.event);

  const dispatch = useDispatch<AppThunkDispatch>();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaSesiKegiatan),
  });

  function submitData(data: any) {
    if (oneData) {
      dispatch(eventSesiUpdate({ uuid: oneData.uuid, data: data }));
    } else {
      dispatch(eventSesiAdd(data));
    }

    setIsMessage(true);
    setTimeout(() => {
      setIsMessage(false);
      dispatch(eventSesiAll(idToEdit!))
    }, 2000);
    onClose();
  }

  useEffect(() => {
    if (oneData) {
      reset({
        uuid_event: oneData.uuid_event,
        ket: oneData.ket,
        start_time: oneData.start_time,
        end_time: oneData.end_time,
      });
    } else {
      reset({
        uuid_event: idToEdit,
        ket: "",
        start_time: "",
        end_time: ""
      });
    }
  }, [show]);

  useEffect(() => {
    dispatch(eventGetAll());
    if (oneData) {
      dispatch(eventSesiAll(oneData?.uuid_event))
    }
  }, [isMessage, oneData]);
  return (
    <div>
      <Message message={message} status={status} show={isMessage} />
      <Modal modalName="Sesi kegiatan" show={show} onClose={onClose}>
        <form
          action=""
          className="flex flex-col gap-3"
          onSubmit={handleSubmit(submitData)}
        >
          <div className="flex flex-col gap-3">
            <InputText
              type="text"
              Name="ket"
              labelName="Judul:"
              placeholder="Judul kegiatan"
              {...register(`ket`)}
              error={errors.ket?.message}
            />
            <div className="flex flex-row gap-2">
              <InputText
                type="time"
                Name="start_time"
                labelName="mulai:"
                {...register(`start_time`)}
                error={errors.start_time?.message}
              />
              <InputText
                type="time"
                Name="end_time"
                labelName="Selesai:"
                {...register(`end_time`)}
                error={errors.end_time?.message}
              />
            </div>
          </div>

          <div className="flex justify-between mt-4">
            <button className="btn" type="submit">
              simpan
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default FormAddSesiKegiatan;
