import React from "react";
import { Control, Controller } from "react-hook-form";
import Select from "react-select";
type Option = {
  value: any;
  label: string;
};
type Props = {
  options: Option[];
  control: Control<any>;
  rules?: Object;
  Name: string;
  labelName?: string;
  placeholder?: string;
  error?: string;
  disable?: boolean;
  //   onChange: any;
};

function InputSelect({
  options,
  rules,
  Name,
  control,
  labelName,
  placeholder,
  disable,
  error,
}: Props) {
  return (
    <div className="flex flex-col bg-transparent">
      <Controller
        control={control}
        rules={rules}
        name={Name}
        render={({ field: { value, onChange } }) => (
          <div className="flex flex-col bg-transparent relative mb-2">
            <label htmlFor="select">{labelName}</label>
            <Select
              //   className="input-text"
              placeholder={placeholder}
              id="select"
              options={options}
              onChange={(e: any) => {
                onChange(e.value);
              }}
              value={options.find((e: any) => e.value === value) || {}}
              isDisabled={disable}
            />
            {/* <option value="">{placeholder}</option> */}
            {/* {options.map((o) => (
              <option value={o.value} key={o.value}>
                {o.label}
              </option>
            ))} */}
            {error && <p className="error ">{error}</p>}
          </div>
        )}
      />
    </div>
  );
}

export default InputSelect;
