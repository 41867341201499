import React, { useEffect, useState } from "react";
import HeaderUI from "../components/HeaderUI";
import Button from "../components/Button";
import TableContainer from "../components/TableContainer";
import { useDispatch } from "react-redux";
import { AppThunkDispatch, useAppSelector } from "../store";
import {
  deleteInformasi,
  getAllInformasi,
} from "../store/actions/actionsInformasi";
import FormInformasi from "../components/forms/FormInformasi";
import { HiOutlinePencilSquare, HiTrash } from "react-icons/hi2";
import Loading from "../components/Loading";
import Confirm from "../components/Confirm";
import Message from "../components/Message";

function InformasiPtk() {
  const [showFormAddInformasi, setShowFormAddInformasi] = useState(false);
  const [confirmDeleteInformasi, setConfirmDeleteInformasi] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [saveData, setSaveData] = useState<any>({});
  const dispatch = useDispatch<AppThunkDispatch>();
  const { allInformasi, loadingInformasi, msgInformasi, status } =
    useAppSelector((state) => state.informasiPtk);
  const hapusInformasi = () => {
    dispatch(deleteInformasi(saveData?.uuid));
    setConfirmDeleteInformasi(false);
    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
    }, 2000);
  };
  useEffect(() => {
    dispatch(getAllInformasi());
  }, [showMessage]);
  return (
    <div>
      <Message status={status} message={msgInformasi} show={showMessage} />
      <Loading show={loadingInformasi} />
      <Confirm
        onClose={() => {
          setSaveData({});
          setConfirmDeleteInformasi(false);
        }}
        show={confirmDeleteInformasi}
        text="Ingin menghapus data ini?"
      >
        <div className="flex gap-1 items-center justify-center">
          <button className="btn w-full" onClick={hapusInformasi}>
            Ya
          </button>
        </div>
      </Confirm>
      <FormInformasi
        show={showFormAddInformasi}
        onClose={() => setShowFormAddInformasi(false)}
        oneData={saveData}
      />
      <HeaderUI title="Informasi Ptk" />
      <div className="bg-white mx-2 my-5 p-4 shadow-lg flex justify-end rounded-md">
        <Button
          title="Tambah"
          onClick={() => {
            setSaveData({});
            setShowFormAddInformasi(true);
          }}
          type="button"
        />
      </div>
      <div className="">
        {allInformasi?.length !== 0 ? (
          <TableContainer>
            <table className="table">
              <thead className="thead">
                <tr>
                  <th className="th">No</th>
                  <th className="th">Title</th>
                  <th className="th">Informasi</th>
                  <th className="th">Status</th>
                  <th className="th">Edit</th>
                  <th className="th">Delete</th>
                </tr>
              </thead>
              <tbody className="tbody">
                {allInformasi?.map((d: any, i: number) => (
                  <tr className="tr" key={i}>
                    <td className="th">{i + 1}</td>
                    <td className="th">{d?.title}</td>
                    <td className="th">{d?.informasi}</td>
                    <td className="th">
                      {d?.statusInformasi ? "Ditampilkan" : "Tidak ditampilkan"}
                    </td>
                    <td className="th">
                      <HiOutlinePencilSquare
                        onClick={() => {
                          setShowFormAddInformasi(true);
                          setSaveData(d);
                        }}
                        className="w-6 h-6 cursor-pointer"
                      />
                    </td>
                    <td className="th">
                      <HiTrash
                        onClick={() => {
                          setConfirmDeleteInformasi(true);
                          setSaveData(d);
                        }}
                        className="w-6 h-6 cursor-pointer"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TableContainer>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default InformasiPtk;
