import { createAsyncThunk } from "@reduxjs/toolkit";
import apiAuth from "../../api/apiAuth";
import { error } from "console";

export const onLogin = createAsyncThunk(
    "auth",
    async(data:any, {rejectWithValue}:any)=>{
        try {
            const res = await apiAuth.login(data)
            if (res.status === 200) {
                return res.data
            }
        } catch (r:any){
            return rejectWithValue(r?.message)}

        
    }
)

export const onLogout = createAsyncThunk(
    "auth/logout",
    async(_,{rejectWithValue}:any)=>{
        try {
            const res = await apiAuth.logout()
            if (res.status === 200) {
                return res.data
            }
        } catch (r){return rejectWithValue(r)}
    }
)