import { createAsyncThunk } from "@reduxjs/toolkit";
import apiEvent from "../../api/apiEvent";

export const eventGetAll = createAsyncThunk(
    "event/all",
    async (_, {rejectWithValue})=>{
        try {
            const res = await apiEvent.getAll()
            if (res.status === 200){
                return res.data
            }
        }catch (e) {
            return rejectWithValue(e)
        }
    }
)

export const eventAdd = createAsyncThunk(
    "event/Add",
    async (data:any, {rejectWithValue})=>{
        try {
            const res = await apiEvent.add(data)
            if (res.status === 200){
                return res.data
            }
        }catch (e) {
            return rejectWithValue(e)
        }
    }
)
export const eventUpdate = createAsyncThunk(
    "event/update",
    async (data:any, {rejectWithValue})=>{
        try {
            const res = await apiEvent.update(data.uuid, data.data)
            if (res.status === 200){
                return res.data
            }
        }catch (e) {
            return rejectWithValue(e)
        }
    }
)

export const eventDelete = createAsyncThunk(
    "event/delete",
    async (uuid:string, {rejectWithValue})=>{
        try{
            const res = await apiEvent.delete(uuid)
            if (res.status === 200){
                return res.data
            }
        }catch(e){
            return rejectWithValue(e)
        }
    }
)

export const eventSesiAll = createAsyncThunk(
    "event/sesiAll",
    async (uuid:string, {rejectWithValue})=>{
        try {
            const res = await apiEvent.getSesiAll(uuid)
            if (res.status === 200){
                return res.data
            }
        }catch (e) {
            return rejectWithValue(e)
        }
    }
)

export const eventSesiAdd = createAsyncThunk(
    "event/sesiAdd",
    async (data:any, {rejectWithValue})=>{
        try{
            const res = await apiEvent.addSesi(data)
            if (res.status === 200){
                return res.data
            }
        }catch(e){
            return rejectWithValue(e)
        }
    }
)
export const eventSesiUpdate = createAsyncThunk(
    "event/sesiUpdate",
    async (data:any, {rejectWithValue})=>{
        try{
            const res = await apiEvent.updateSesi(data.uuid,data.data)
            if (res.status === 200){
                return res.data
            }
        }catch(e){
            return rejectWithValue(e)
        }
    }
)
export const eventGetAbsensi = createAsyncThunk(
    "event/sesiAbsensi",
    async (uuid:string, {rejectWithValue})=>{
        try{
            const res = await apiEvent.getAbsensiAll(uuid)
            if (res.status === 200){
                return res.data
            }
        }catch(e){
            return rejectWithValue(e)
        }
    }
)