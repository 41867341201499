import React, { useEffect } from "react";
import { AppThunkDispatch, useAppSelector } from "../../store";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputSelect from "../elements/InputSelect";
import InputText from "../elements/InputText";
import {
  optionsGander,
  optionsGolDarah,
  optionsPendidikan,
  optionsStatusPernikahan,
} from "../../constants/options";
import Modal from "../Modal";
import {
  getKabupaten,
  getKecamatan,
  getProvinsi,
} from "../../store/actions/actionAdress";
import { useDispatch } from "react-redux";
import { schemaPTK } from "../../constants/schemaInput";

type FormUpdatePTKProps = {
  show: boolean;
  onClose: () => void;
  submitData: (data: TUpdatePTK) => void;
};

function FormUpdatePTK({ show, onClose, submitData }: FormUpdatePTKProps) {
  const dispatch = useDispatch<AppThunkDispatch>();
  const statePegawai = useAppSelector((state) => state.ptk);
  const stateAddress = useAppSelector((state) => state.address);
  const {
    handleSubmit,
    register,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaPTK),
  });

  const optionProvinsi = stateAddress.provinsi.map((i) => {
    return { value: i.id, label: i.nama };
  });
  const optionKabupaten = stateAddress.kabupaten.map((i) => {
    return { value: i.id, label: i.nama };
  });
  const optionKecamatan = stateAddress.kecamatan.map((i) => {
    return { value: i.id, label: i.nama };
  });

  const provWatch = watch("provinsi");
  const kabWatch = watch("kabupaten");
  useEffect(() => {
    if (provWatch !== "" && provWatch !== undefined) {
      dispatch(getKabupaten(provWatch));
    }
    if (kabWatch !== "" && kabWatch !== undefined) {
      dispatch(getKecamatan(kabWatch));
    }
  }, [provWatch, kabWatch]);
  useEffect(() => {
    if (show) {
      dispatch(getProvinsi());
      reset({
        nama: statePegawai.selectedPegawai?.nama,
        tempat_lahir: statePegawai.selectedPegawai?.tempat_lahir,
        tanggal_lahir: statePegawai.selectedPegawai?.tanggal_lahir,
        alamat: statePegawai.selectedPegawai?.alamat,
        provinsi: statePegawai.selectedPegawai?.provinsi,
        kabupaten: statePegawai.selectedPegawai?.kabupaten,
        kecamatan: statePegawai.selectedPegawai?.kecamatan,
        gender: statePegawai.selectedPegawai?.gender,
        no_hp: statePegawai.selectedPegawai?.no_hp,
        status_pernikahan: statePegawai.selectedPegawai?.status_pernikahan,
        pendidikan_terakhir: statePegawai.selectedPegawai?.pendidikan_terakhir,
        gelar: statePegawai.selectedPegawai?.gelar,
        gol_darah: statePegawai.selectedPegawai?.gol_darah,
      });
    }
  }, [show]);

  return (
    <div>
      <Modal modalName="Edit PTK" onClose={onClose} show={show}>
        <form onSubmit={handleSubmit(submitData)} action="">
          <div className="grid grid-cols-2 gap-3">
            <InputText
              placeholder="Masukkan nama"
              type="text"
              Name="nama"
              labelName="Nama:"
              {...register("nama")}
              error={errors.nama?.message}
            />
            <InputText
              placeholder="Masukkan tempat lahir"
              type="text"
              Name="tempat_lahir"
              labelName="Tempat Lahir:"
              {...register("tempat_lahir")}
              error={errors.tempat_lahir?.message}
            />
            <InputText
              placeholder="Masukkan tanggal lahir"
              type="date"
              Name="tanggal_lahir"
              labelName="Tanggal Lahir:"
              {...register("tanggal_lahir")}
              error={errors.tanggal_lahir?.message}
            />
            <InputText
              placeholder="Masukkan alamat"
              type="text"
              Name="alamat"
              labelName="Alamat:"
              {...register("alamat")}
              error={errors.alamat?.message}
            />
            <InputSelect
              placeholder="Pilih provinsi"
              control={control}
              options={optionProvinsi}
              Name="provinsi"
              labelName="Provinsi:"
              error={errors.provinsi?.message}
            />
            <InputSelect
              placeholder="Pilih kabupaten"
              control={control}
              options={optionKabupaten}
              Name="kabupaten"
              labelName="Kabupaten:"
              error={errors.kabupaten?.message}
            />
            <InputSelect
              placeholder="Pilih kecamatan"
              control={control}
              options={optionKecamatan}
              Name="kecamatan"
              labelName="Kecamatan:"
              error={errors.kecamatan?.message}
            />
            <InputSelect
              placeholder="Pilih jenis kelamin"
              Name="gender"
              options={optionsGander}
              control={control}
              labelName="Jenis Kelamin:"
              error={errors.gender?.message}
            />
            <InputText
              placeholder="Masukkan nomer hp"
              type="text"
              Name="no_hp"
              labelName="No Handphone:"
              {...register("no_hp")}
              error={errors.no_hp?.message}
            />
            <InputSelect
              placeholder="Pilih status pernikahan"
              Name="status_pernikahan"
              control={control}
              options={optionsStatusPernikahan}
              labelName="Status Pernikahan:"
              error={errors.status_pernikahan?.message}
            />
            <InputSelect
              placeholder="Pilih pendidikan terakhir"
              control={control}
              options={optionsPendidikan}
              Name="pendidikan_terakhir"
              labelName="Pendidikan Terakhir:"
              error={errors.pendidikan_terakhir?.message}
            />
            <InputText
              placeholder="Masukkan gelar pendidikan"
              type="text"
              Name="gelar"
              labelName="Gelar:"
              {...register("gelar")}
              error={errors.gelar?.message}
            />

            <InputSelect
              placeholder="Pilih golongan darah"
              control={control}
              options={optionsGolDarah}
              Name="gol_darah"
              labelName="Golongan Darah:"
              error={errors.gol_darah?.message}
            />
          </div>
          <div className="flex justify-end mt-2">
            <button type="submit" className="btn px-8">
              Simpan
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default FormUpdatePTK;
