import * as yup from "yup"

export const schemaLogin = yup.object().shape({
    username: yup.string().required("Username is missing"),
    password: yup.string().required("Password is missing"),
    sistem : yup.string()
  });
  
export const schemaPTK = yup.object().shape({
    nama: yup.string().required("Nama harus diisi"),
    tempat_lahir: yup.string().required("Tempat Lahir harus diisi"),
    tanggal_lahir: yup.string().required("Tanggal Lahir harus diisi"),
    alamat: yup.string().required("Alamat harus diisi"),
    kecamatan: yup.string().required("Kecamatan harus diisi"),
    kabupaten: yup.string().required("Kabupaten harus diisi"),
    provinsi: yup.string().required("provinsi harus diisi"),
    gender: yup.string().required("Jenis Kelamin harus diisi"),
    no_hp: yup.string().required("Nomer hp harus diisi"),
    status_pernikahan: yup.string().required("Status pernikahan harus diisi"),
    pendidikan_terakhir: yup.string().required("Pendidikan harus diisi"),
    gelar: yup.string().required("Gelar harus diisi"),
    gol_darah: yup.string().required("Golongan darah harus diisi"),
})

export const schemaStatusPTK = yup.object().shape({
    status_kepegawaian: yup.number().required("Status Kepegawaian belum terisi!"),
    kode_satker: yup.number().required("Kode satker belum terisi!"),
    kode_tugasPokok: yup.number().required("Kode tugas pokok belum terisi!"),
    kode_lembaga:yup.string().required("Tidak boleh kosong"),
    golongan: yup.string().required("Golongan belum terisi!"),
    uuid_ptk: yup.string().required("uuid tidak ada!"),
    active: yup.boolean().required("Pilih status aktif atau tidak!"),
    id_location:yup.string().required("Tidak boleh kosong")
  });

export  const schemaTugasPokok = yup.object().shape({
    nama_tugasPokok: yup.string().required("Nama tugas Pokok harus diisi"),
    nominal: yup.number().required("Nominal belum diisi")
  });

export const schemaTugasTambahan = yup.object().shape({
    kode_tugasTambahan: yup.string().required("Nama tugas tambahan harus diisi!"),
    uuid_ptk: yup.string().required("uuid ptk kosong!"),
  });

export const schemaListTugasTambahan = yup.object().shape({
    nama_tugasTambahan: yup.string().required("Nama tugas tambahan harus diisi!"),
    nominal: yup.number().required("Nominal belum diisi")
  });

export const schemaNormalisaiGaji = yup.object().shape({
  nominal: yup.number().required("Nominal belum diisi"),
  increment: yup.boolean().required("Tipe normalisasi belum dipilih"),
  uuid: yup.string().required("uuid_ptk tidak ada")
})

export const schemaAbsensiPtk = yup.object().shape({
  uuid: yup.string().required("uuid_ptk tidak ada"),
  nominal: yup.number().required("Nominal belum diisi"),
  bulan: yup.string().required("Tipe normalisasi belum dipilih"),
})

export const schemaPinjamanPtk = yup.object().shape({
  uuid: yup.string().required("uuid_ptk tidak ada"),
  nominal: yup.number().required("Nominal belum diisi"),
  potongan:yup.number().required("Potongan belum diisi"),
  ket:yup.string().required("Keterangan belum diisi"),
})

export const schemaEditPinjaman = yup.object().shape({
  uuid: yup.string().required("uuid_ptk tidak ada"),
  optional_potongan: yup.number().required("Optional potongan belum diisi"),
  proses: yup.boolean().required("Proses belum diisi"),
})

export const schemaPostTitipan = yup.object().shape({
  uuid: yup.string().required("uuid_ptk tidak ada"),
  nominal: yup.number().required("Nominal belum diisi"),
  bulan:yup.string().required("bulan belum diisi"),
  ket:yup.string().required("Keterangan belum diisi"),
})

export const schemaUpdateTitipan = yup.object().shape({
  nominal: yup.number().required("Nominal belum diisi"),
  bulan:yup.string().required("Bulan belum diisi"),
  ket:yup.string().required("Keterangan belum diisi"),
})

export const schemaAddUser = yup.object().shape({
  nama: yup.string().required("Nama belum diisi"),
  username: yup.string().required("Nama belum diisi"),
  password: yup.string().required("Nama belum diisi"),
  general_user:yup.boolean().required("General user belum diisi"),
  sistem: yup.object().shape({
    uuid_sistem: yup.string().required("uuid_sistem tidak ada"),
    uuid_role: yup.string().required("uuid_role tidak ada"),
    super_admin:yup.boolean().required("General user belum diisi"),
    no_lembaga: yup.number()
  })
})

export const schemaEditUser = yup.object().shape({
  nama: yup.string().required("Nama belum diisi"),
  username: yup.string().required("Nama belum diisi"),
  password: yup.string().required("Nama belum diisi"),
  general_user:yup.boolean().required("General user belum diisi"),
})

export const schemaApprovalPost = yup.object().shape({
  uuid: yup.string().required("uuid tidak ada"),
  status: yup.boolean().required("Belum ada status")
})

export const schemaKegiatan = yup.object().shape({
  kegiatan: yup.string().required("Judul Kegiatan belum diisi"),
  start_date: yup.string().required("Judul Kegiatan belum diisi"),
  end_date: yup.string().required("Judul Kegiatan belum diisi"),
});


export const schemaJadwal = yup.object().shape({
  jadwal : yup.array().of(yup.object().shape({
    kode_lembaga : yup.number().required("Tidak boleh kosong"),
    day : yup.number().required("Tidak boleh kosong"),
    start:yup.string().required("Tidak boleh kosong"),
    end:yup.string().required("Tidak boleh kosong"),
    active:yup.string().oneOf(["true","false"],"Pilih salah satu").required("Tidak boleh kosong"),
  }))
})


export const schemaJadwalKhusus = yup.object().shape({
  bulan: yup.number().required("Bulan tidak boleh kosong"),
  jadwal : yup.array().required("Jadwal tidak boleh kosong"),
})
