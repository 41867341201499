import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import InputText from "../elements/InputText";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemaAbsensiPtk } from "../../constants/schemaInput";
import InputSelect from "../elements/InputSelect";
import { AppThunkDispatch, useAppSelector } from "../../store";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getListPenggajian, postAbsensi } from "../../store/actions/actionGaji";
import Message from "../Message";

function FormAbsensiPtk({ show, onClose }: TModalProps) {
  const { uuid } = useParams<{ uuid: string }>();

  const [isMessage, setIsMessage] = useState(false);

  const stateGaji = useAppSelector((state) => state.gaji);
  const listBulan = stateGaji.listPenggajian;

  const dispatch = useDispatch<AppThunkDispatch>();

  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaAbsensiPtk),
  });

  const optionsBulan = listBulan?.map((i) => {
    return { value: i.bulan, label: i.bulan };
  });

  function submitData(data: any) {
    dispatch(postAbsensi(data));
    onClose();
    setIsMessage(true);
    setTimeout(() => {
      setIsMessage(false);
    }, 2000);
  }

  useEffect(() => {
    if (show) {
      reset({
        uuid: uuid,
      });
      dispatch(getListPenggajian());
    } else {
      reset();
    }
  }, [show]);

  return (
    <div>
      <Modal modalName="Absensi" show={show} onClose={onClose}>
        <form
          className="flex flex-col gap-2"
          onSubmit={handleSubmit(submitData)}
        >
          <InputText
            Name="nominal"
            type="number"
            defaultValue={0}
            placeholder="Input nominal"
            {...register("nominal")}
            error={errors.nominal?.message}
          />

          <InputSelect
            placeholder="Pilih bulan"
            Name="bulan"
            options={optionsBulan}
            control={control}
            error={errors.bulan?.message}
          />

          <button type="submit" className="btn">
            Simpan
          </button>
        </form>
      </Modal>

      <Message
        show={isMessage}
        status={stateGaji.status}
        message={stateGaji.message}
      />
    </div>
  );
}

export default FormAbsensiPtk;
