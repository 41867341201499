import React, { useEffect, useState } from "react";
import TableContainer from "../components/TableContainer";
import { useDispatch } from "react-redux";
import { AppThunkDispatch, useAppSelector } from "../store";
import {
  deletePinjaman,
  getPinjaman,
  getPinjamanByKey,
} from "../store/actions/actionPinjaman";
import { DocumentTextIcon, TrashIcon } from "@heroicons/react/24/solid";
import HeaderUI from "../components/HeaderUI";
import Select from "react-select";
import { allSakter } from "../store/actions/actionSatker";
import Loading from "../components/Loading";
import { formatCurrency } from "../helpers/formatCurrency";
import FormEditCredit from "../components/forms/FormEditCredit";
import Message from "../components/Message";
import Confirm from "../components/Confirm";

function Pinjaman() {
  const [isMessage, setIsMessage] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [idToDelete, setIdToDelete] = useState<string>("");
  const [isFormEditCredit, setIsFormEditCredit] = useState(false);
  const [idToEdit, setIdToEdit] = useState<string>("");

  const statePinjaman = useAppSelector((state) => state.pinjaman);
  const dispatch = useDispatch<AppThunkDispatch>();
  const pegawai = statePinjaman.pegawai;
  const [satker, setSatker] = useState<any>({ value: "", label: "SEMUA" });
  const { satkerAll } = useAppSelector((state) => state.satker);
  const satkerOpt = satkerAll.map((d: any) => {
    return { value: d.kode_satker, label: d.nama_satker };
  });
  satkerOpt.splice(0, 0, { value: "", label: "SEMUA" });

  useEffect(() => {
    if (Object.keys(satker).length !== 0) {
      if (satker.value === "") {
        dispatch(getPinjaman());
      } else {
        dispatch(getPinjamanByKey(satker.value));
      }
    }
  }, [satker]);

  function handleDelete() {
    dispatch(deletePinjaman(idToDelete));
    setIsMessage(true);
    setIsConfirm(false);
    setTimeout(() => {
      setIsMessage(false);
    }, 2000);
  }
  useEffect(() => {
    dispatch(allSakter());
  }, [isMessage]);
  return (
    <div>
      <Loading show={statePinjaman.isLoading} />
      <Confirm
        text="Hapus data ini?"
        onClose={() => setIsConfirm(false)}
        show={isConfirm}
      >
        <div className="flex gap-1 items-center justify-center">
          <button className="btn w-full" onClick={handleDelete}>
            Ya
          </button>
        </div>
      </Confirm>
      <Message
        status={statePinjaman.status}
        message={statePinjaman.message}
        show={isMessage}
      />
      <HeaderUI title="Pinjaman PTK" />
      <div className="bg-white  ">
        <div className="p-4 m-2 mb-4 shadow-lg">
          <div className=" z-50  w-full max-w-[300px]">
            <label htmlFor="bulan">Pilih Satker:</label>
            <Select
              id="satker"
              placeholder="Pilih Satker"
              options={satkerOpt}
              value={satker}
              onChange={(i: any) => setSatker(i)}
            />
          </div>
        </div>
        <TableContainer>
          <table className="table">
            <thead className="thead">
              <tr>
                <th className="th">No</th>
                <th className="th">Nupy</th>
                <th className="th">Nama</th>
                <th className="th">Satker</th>
                <th className="th">Nominal</th>
                <th className="th">Potongan</th>
                <th className="th">Optional potongan</th>
                <th className="th">Keterangan</th>
                <th className="th">Edit</th>
                <th className="th">hapus</th>
              </tr>
            </thead>
            <tbody className="tbody">
              {pegawai?.map((d, i) => (
                <tr className="tr" key={i}>
                  <td className="td">{i + 1}</td>
                  <td className="td">{d.ptk?.nupy}</td>
                  <td className="td">{d.ptk?.nama}</td>
                  <td className="td">
                    {d?.ptk?.statusPtk
                      ? d.ptk?.statusPtk?.listSatker?.nama_satker
                      : "-"}
                  </td>
                  <td className="td">
                    {d?.nominal ? formatCurrency(d.nominal) : "-"}
                  </td>
                  <td className="td">
                    {d?.potongan ? formatCurrency(d.potongan) : "-"}
                  </td>
                  <td>
                    {d?.optional_potongan
                      ? formatCurrency(d.optional_potongan)
                      : "-"}
                  </td>
                  <td className="td">{d?.ket}</td>
                  <td className="td">
                    <button
                      onClick={() => {
                        setIsFormEditCredit(true);
                        setIdToEdit(d.uuid);
                      }}
                    >
                      <DocumentTextIcon className="icon" />
                    </button>
                  </td>
                  <td className="td">
                    <button
                      onClick={() => {
                        setIsConfirm(true);
                        setIdToDelete(d.uuid);
                      }}
                    >
                      <TrashIcon className="icon" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableContainer>
      </div>
      <FormEditCredit
        show={isFormEditCredit}
        onClose={() => setIsFormEditCredit(false)}
        idToEdit={idToEdit}
      />
    </div>
  );
}

export default Pinjaman;
