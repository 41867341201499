import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiAddress } from "../../api/apiAddress";

export const getProvinsi = createAsyncThunk(
    "address",
    async(_,{rejectWithValue}:any)=>{
        try{
            const res = await apiAddress.getProvinsi()
            if (res.status === 200){
                return res.data
            }
        }catch(r){
            return rejectWithValue(r)
        }
    }
)
export const getKabupaten = createAsyncThunk(
    "address/kabupaten",
    async(id:string, {rejectWithValue})=>{
        try{
            const res =await apiAddress.getKabupaten(id)
            if (res.status === 200) {
                return res.data
            }
        }catch(r){
            return rejectWithValue(r)
        }
    }
)
export const getKecamatan = createAsyncThunk(
    "address/kecamatan",
    async(id:string, {rejectWithValue})=>{
        try{
            const res =await apiAddress.getKecamatan(id)
            if (res.status === 200) {
                return res.data
            }
        }catch(r){
            return rejectWithValue(r)
        }
    }
)