import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemaPostTitipan } from "../../constants/schemaInput";
import InputText from "../elements/InputText";
import InputSelect from "../elements/InputSelect";
import { useParams } from "react-router";
import Message from "../Message";
import { useDispatch } from "react-redux";
import { AppThunkDispatch, useAppSelector } from "../../store";
import { postTitipan } from "../../store/actions/actionTitipan";
import { getListPenggajian } from "../../store/actions/actionGaji";

function FormPostTitipan({ show, onClose }: TModalProps) {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { isLoading, status, message, pegawai } = useAppSelector(
    (state) => state.titipan
  );
  const { listPenggajian } = useAppSelector((state) => state.gaji);

  const { uuid } = useParams<{ uuid: string }>();
  const [isMessage, setIsMessage] = useState(false);
  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaPostTitipan),
  });

  function submitData(data: any) {
    dispatch(postTitipan(data));
    onClose();
    setIsMessage(true);
    setTimeout(() => {
      setIsMessage(false);
    }, 2000);
  }
  const optionsBulan = listPenggajian.map((d: any) => {
    return { value: d.bulan, label: d.bulan };
  });

  useEffect(() => {
    dispatch(getListPenggajian());
    reset({
      uuid: uuid,
      bulan: "",
      ket: "",
      nominal: 0,
    });
  }, [show]);
  return (
    <div>
      <Modal show={show} onClose={onClose} modalName="Titipan">
        <form
          onSubmit={handleSubmit(submitData)}
          className="flex flex-col gap-2"
        >
          <InputSelect
            Name="bulan"
            labelName="Bulan:"
            placeholder="pilih bulan"
            control={control}
            error={errors.bulan?.message}
            options={optionsBulan}
          />
          <InputText
            Name="nominal"
            type="number"
            placeholder="nominal"
            labelName="Nominal"
            {...register("nominal")}
            error={errors.nominal?.message}
          />
          <InputText
            Name="ket"
            labelName="Keterangan:"
            placeholder="Keterangan"
            type="text"
            {...register("ket")}
            error={errors.ket?.message}
          />
          <button type="submit" className="btn">
            Simpan
          </button>
        </form>
      </Modal>
      <Message show={isMessage} message={message} status={status} />
    </div>
  );
}

export default FormPostTitipan;
