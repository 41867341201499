import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiOptions } from "../../api/apiOptions";

export const allThnAjar = createAsyncThunk(
    'thn/ajar/get',
    async(_,{rejectWithValue})=>{
        try{
            const res : any =  await ApiOptions.getThnAjar()
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue(err.response.data.msg)
        }
    }
) 
export const allLembaga = createAsyncThunk(
    'lembaga/get',
    async(_,{rejectWithValue})=>{
        try{
            const res : any = await ApiOptions.getLembaga()
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const allSistem = createAsyncThunk(
    'sistem/get',
    async(_,{rejectWithValue})=>{
        try{
            const res : any = await ApiOptions.sistem()
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const allRole = createAsyncThunk(
    'role/get',
    async(uuid:string,{rejectWithValue})=>{
        try{
            const res : any = await ApiOptions.role(uuid)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue( err.response.data.msg)
        }
    }
)
export const allListLembaga = createAsyncThunk(
    'lembaga/list/all',
    async (_, { rejectWithValue }) => {
        try {
            const res = await ApiOptions.LembagaList()
            if (res.status === 200) {
                return res.data
            }
        }
        catch (err:any) {
            return rejectWithValue( err.response.data.msg)
        }
    }
)