import api from "./api";

export const ApiSatker  = {
    getAll : () =>{
        return api.get(`/ptk/list-satker`)
    },
    post : (data:TListSatker)=>{
        return api.post(`/ptk/list-satker`,data)
    },
    put : (id:string,data:TListSatker)=>{
        return api.update(`/ptk/list-satker/${id}`,data)
    },
    delete : (id:string)=>{
        return api.delete(`/ptk/list-satker/${id}`)
    }
}