import React, { useEffect, useState } from "react";
import {
  HiOutlineArrowRightOnRectangle,
  HiOutlineBars2,
  HiOutlineCircleStack,
  HiOutlineListBullet,
  HiOutlinePencilSquare,
  HiOutlineXMark,
  HiUser,
} from "react-icons/hi2";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useLocation } from "react-router-dom";
import { menuPanel, menuPtk } from "./nav/Menu";
import Header from "./nav/Header";
import SideBar from "./nav/SideBar";
import { AppThunkDispatch } from "../store";
import { authActions } from "../store/slices/authSlice";
import { onLogout } from "../store/actions/actionsAuth";

function Sidebar() {
  const dispatch = useDispatch<AppThunkDispatch>();
  // const { loadingAuth, msgAuth, username, super_admin, generalUser } = useSelector((state: any) => state.auth)
  // const { loadingUser, msgUser } = useSelector((state: any) => state.user)
  const [showFormUser, setShowFormUser] = useState<boolean>(false);
  const [sideMenu, setSideMenu] = useState<boolean>(false);
  const [dropUser, setDropUser] = useState<boolean>(false);
  const [refrensiDrop, setRefrensiDrop] = useState<boolean>(false);
  const [alertUser, setAlertUser] = useState<boolean>(false);
  const date = new Date();
  const tahun = date.getFullYear();
  const editUser = (e: any) => {
    // dispatch(editSelf(e))
    setShowFormUser(false);
    setAlertUser(true);
  };
  const out = () => {
    dispatch(onLogout());
  };
  useEffect(() => {
    if (alertUser) {
      const interval = setInterval(() => {
        setAlertUser(false);
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [alertUser]);
  return (
    <div className="max-h-screen w-screen overflow-hidden">
      {/* <Loading show={loadingUser} />
            <Alert show={alertUser} msg={msgUser} />
            <EditUserSelf showForm={showFormUser} closeForm={setShowFormUser} submit={editUser} /> */}
      <Header
        title="Redagsi"
        sideMenu={() => setSideMenu(!sideMenu)}
        userDrop={() => setDropUser(!dropUser)}
        dropUser={dropUser}
        logout={out}
        userMenu={() => {
          setDropUser(!dropUser);
        }}
      />
      <div className="flex">
        <SideBar
          sideMenu={sideMenu}
          setSideMenu={() => setSideMenu(!sideMenu)}
          setDropUser={() => setDropUser(false)}
          setRefrensiDrop={() => setRefrensiDrop(!refrensiDrop)}
          refrensiDrop={refrensiDrop}
        />
        <div
          className="md:w-[100vw] w-full relative h-[90vh] overflow-y-auto"
          onClick={() => setDropUser(false)}
        >
          <Outlet />
          <footer className="p-3 flex space-x-2">
            <h1 className="font-light md:text-xl">COPYRIGHT &#9400; {tahun}</h1>
            <h1 className="font-extralight md:text-xl text-gray-900">
              Ponpes Abu Hurairah.
            </h1>
          </footer>
        </div>
        <div
          onClick={() => setSideMenu(!sideMenu)}
          className={`bg-black bg-opacity-80 fixed top-0 left-0 w-screen h-screen md:hidden ${
            sideMenu ? "block" : "hidden"
          }`}
        ></div>
      </div>
    </div>
  );
}

export default Sidebar;
