import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiLocation from "../../api/apiLocation";

export const getAllLocation = createAsyncThunk(
    'location/all',
    async(_,{rejectWithValue})=>{
        try{
            const res = await ApiLocation.getAll()
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue(err.response?.data?.msg)
        }
    }
)