import React, { useEffect, useState } from "react";
import TableContainer from "../TableContainer";
import { DocumentTextIcon, TrashIcon } from "@heroicons/react/24/solid";
import { AppThunkDispatch, useAppSelector } from "../../store";
import { useDispatch } from "react-redux";
import {
  deleteTugasPokok,
  getTugasPokok,
} from "../../store/actions/actionTugasPokok";
import Confirm from "../Confirm";
import Message from "../Message";
import FormListTugasPokok from "../forms/FormListTugasPokok";

function TableTugasPokok() {
  const [dataToUpdate, setDataToUpdate] = useState<any>(null);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isMessage, setIsMessage] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [dataToDelete, setDataToDelete] = useState<string>("");

  const tugasState = useAppSelector((state) => state.tugasPokok);
  const data = tugasState.tugasPokokAll;

  const dispatch = useDispatch<AppThunkDispatch>();

  function handleDelete(id: string) {
    setDataToDelete(id);
    setIsConfirm(true);
  }
  function confirmDelete() {
    dispatch(deleteTugasPokok(dataToDelete));
    setIsMessage(true);
    setIsConfirm(false);
    setTimeout(() => {
      setIsMessage(false);
    }, 2000);
  }
  function handleAdd() {
    setModalShow(true);
  }

  useEffect(() => {
    dispatch(getTugasPokok());
  }, [isMessage]);
  return (
    <div>
      <FormListTugasPokok
        onClose={() => {
          setModalShow(false);
          setDataToUpdate(null);
        }}
        show={modalShow}
        dataToUpdate={dataToUpdate}
      />
      <div className=" overflow-x-auto md:px-3 py-3">
        <TableContainer btnText="Tambah" handleAdd={handleAdd}>
          <table className="table">
            <thead className="thead">
              <tr className="tr">
                <th scope="col" className="th">
                  No
                </th>
                <th scope="col" className="th">
                  Kode
                </th>
                <th scope="col" className="th">
                  Nama
                </th>
                <th scope="col" className="th">
                  Nominal
                </th>
                <th scope="col" className="th">
                  Edit
                </th>
                <th scope="col" className="th">
                  Hapus
                </th>
              </tr>
            </thead>
            <tbody className="bg-grey-light">
              {data?.map((d, i) => (
                <tr className="tr" key={i}>
                  <td className="td">{i + 1}</td>
                  <td className="td">{d.kode_tugasPokok}</td>
                  <td className="td">{d.nama_tugasPokok}</td>
                  <td className="td">{d.nominal}</td>
                  <td className="td">
                    <button
                      onClick={() => {
                        setModalShow(true);
                        setDataToUpdate(d);
                      }}
                    >
                      <DocumentTextIcon className="icon" />
                    </button>
                  </td>
                  <td className="td">
                    <button
                      onClick={() => handleDelete(`${d.kode_tugasPokok}`)}
                    >
                      <TrashIcon className="icon" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableContainer>
      </div>
      <Confirm
        onClose={() => setIsConfirm(false)}
        show={isConfirm}
        text={`Yakin data ini dihapus?`}
      >
        <div className="flex gap-1 items-center justify-center">
          <button className="btn w-full" onClick={confirmDelete}>
            Ya
          </button>
        </div>
      </Confirm>
      <Message
        message={tugasState.message}
        show={isMessage}
        status={tugasState.status}
      />
    </div>
  );
}

export default TableTugasPokok;
