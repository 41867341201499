import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "../Modal";
import InputSelect from "../elements/InputSelect";
import { schemaStatusPTK } from "../../constants/schemaInput";
import { optionsStatusKepegawian } from "../../constants/options";
import { AppThunkDispatch, useAppSelector } from "../../store";
import { useDispatch } from "react-redux";
import { allSakter } from "../../store/actions/actionSatker";
import { getTugasPokok } from "../../store/actions/actionTugasPokok";
import { useParams } from "react-router-dom";
import {
  detailPegawai,
  getGolongan,
  updateStatusPTK,
} from "../../store/actions/actionPTK";
import InputRadio from "../elements/InputRadio";
import Message from "../Message";

type FormStatusPTKProps = {
  show: boolean;
  onClose: () => void;
};
function FormStatusPTK({ show, onClose }: FormStatusPTKProps) {
  const [isMessage, setIsMessage] = useState(false);
  const { uuid } = useParams<{ uuid: string }>();
  const stateData = useAppSelector((state) => state.ptk);
  const { allLocation } = useAppSelector((state) => state.location)
  const ptk = stateData.selectedPegawai;
  const satker = useAppSelector((state) => state.satker);
  const tugasPokok = useAppSelector((state) => state.tugasPokok);
  const dispatch = useDispatch<AppThunkDispatch>();
  const { lembagaAll } = useAppSelector((state) => state.options)

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaStatusPTK), defaultValues: { uuid_ptk: uuid }
  });

  const kepWatch = watch("status_kepegawaian");

  const onSubmitData = (data: any) => {
    dispatch(updateStatusPTK(data));
    onClose();
    setIsMessage(true);
    setTimeout(() => {
      setIsMessage(false);
    }, 2000);
  };

  const lembagaOpt = lembagaAll.map((d: any) => {
    return { value: d.kode_lembaga, label: d.nama }
  })

  const optionsKodeSatker = satker.satkerAll?.map((i) => {
    return { value: i.kode_satker, label: i.nama_satker };
  });
  const optionsKodeTugasPokok = tugasPokok.tugasPokokAll?.map((i) => {
    return { value: i.kode_tugasPokok, label: i.nama_tugasPokok };
  });

  const optionsGolongan = stateData?.golongan?.map((i) => {
    return { value: i.gol, label: i.gol };
  });
  const optionsLocation = allLocation.map((d: any) => {
    return { value: d.id, label: d.keterangan }
  })
  useEffect(() => {
    if (kepWatch !== null) {
      dispatch(allSakter());
      dispatch(getTugasPokok());
      dispatch(getGolongan());
    }
    reset({
      golongan: ptk?.statusPtk?.golongan.toLowerCase(),
      uuid_ptk: uuid,
      kode_lembaga: ptk?.statusPtk?.kode_lembaga,
      id_location: ptk?.statusPtk?.id_location,
      status_kepegawaian: ptk?.statusPtk?.status_kepegawaian,
      kode_satker: ptk?.statusPtk?.kode_satker,
      kode_tugasPokok: ptk?.statusPtk?.kode_tugasPokok,
      active: ptk?.statusPtk?.active?.toString(),
    });
  }, [show]);
  useEffect(() => {
    dispatch(detailPegawai(uuid));
    // if (isMessage) {
    // }
  }, [isMessage]);
  return (
    <div>
      <Message
        status={stateData.status}
        show={isMessage}
        message={stateData.message}
      />
      <Modal show={show} onClose={onClose} modalName="Status PTK">
        <form action="" onSubmit={handleSubmit(onSubmitData)}>
          <InputSelect
            control={control}
            options={optionsGolongan}
            placeholder="Pilih golongan"
            Name="golongan"
            labelName="Golongan:"
            error={errors.golongan?.message}
          />
          <InputSelect
            control={control}
            options={optionsStatusKepegawian}
            placeholder="Pilih status kepegawaian"
            Name="status_kepegawaian"
            labelName="Status sepegawaian:"
            error={errors.status_kepegawaian?.message}
          />
          <InputSelect
            Name="kode_satker"
            placeholder="Pilih satker"
            control={control}
            options={optionsKodeSatker}
            labelName="Satker:"
            error={errors.kode_satker?.message}
          />
          <InputSelect
            Name="kode_lembaga"
            placeholder="Pilih Lembaga"
            control={control}
            options={lembagaOpt}
            labelName="Lembaga:"
            error={errors.kode_lembaga?.message}
          />
          <InputSelect
            Name="kode_tugasPokok"
            control={control}
            options={optionsKodeTugasPokok}
            placeholder="Pilih tugas pokok"
            labelName="Tugas pokok:"
            error={errors.kode_tugasPokok?.message}
          />
          <InputSelect
            Name="id_location"
            control={control}
            options={optionsLocation}
            placeholder="Pilih Lokais kerja"
            labelName="Lokasi kerja:"
            error={errors.id_location?.message}
          />
          <div className="">
            <div className="flex flex-col">
              <label className="label">Status:</label>
              <div className="flex flex-row">
                <InputRadio
                  Name="active"
                  control={control}
                  value="true"
                  label="Active"
                />
                <InputRadio
                  Name="active"
                  control={control}
                  value="false"
                  label="Tidak aktif"
                />
              </div>
            </div>
          </div>
          <div className="pt-4 flex justify-end">
            <button type="submit" className="btn px-8">
              simpan
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default FormStatusPTK;
