import api from "./api"

const apiTugasTambahan = {
    getByUuidPtk:(uuid_ptk:string)=>{
        return api.get(`/ptk/tugas-tambahan/ptk/${uuid_ptk}`)
    },
    post:(data:any)=>{
        return api.post(`/ptk/tugas-tambahan/`,data)
    },
    delete:(id:string)=>{
        return api.delete(`/ptk/tugas-tambahan/${id}`)
    },
    put:(data:any)=>{
        return api.update(`/ptk/tugas-tambahan/`, data)
    }
}
export default apiTugasTambahan