import React, { useEffect, useState } from "react";
type MessageProps = {
  status: string;
  message: string;
  show: boolean;
};

function Message({ status, message, show }: MessageProps) {
  const [isVisible, setIsVisible] = useState(show);

  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [show]);
  return (
    <div className="">
      <div
        className={`${
          show ? "translate-x-0" : "translate-x-[25rem]"
        } transition-all duration-500 fixed shadow-md bg-gray-50  right-0 top-20 overflow-hidden rounded-tl-lg rounded-bl-lg`}
      >
        <div className="relative overflow-hidden min-w-[200px] min-h-[2.5rem] max-w-[400px] flex items-center ">
          <span
            className={`${
              status === "ERROR"
                ? "bg-red-500"
                : status === "SUCCESS"
                ? "bg-green-500"
                : ""
            } absolute w-6 h-full`}
          ></span>
          <p className="ml-6 px-3 text-sm">{message}</p>
        </div>
      </div>
    </div>
  );
}

export default Message;
