import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemaUpdateTitipan } from "../../constants/schemaInput";
import InputText from "../elements/InputText";
import InputSelect from "../elements/InputSelect";
import { optionsBulan } from "../../constants/options";
import { useParams } from "react-router";
import Message from "../Message";
import { useDispatch } from "react-redux";
import { AppThunkDispatch, useAppSelector } from "../../store";
import { postTitipan, updateTitipan } from "../../store/actions/actionTitipan";

function FormEditTitipan({ show, onClose,oneData }: TModalProps) {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { isLoading, status, message, pegawai } = useAppSelector(
    (state) => state.titipan
  );

  const { uuid } = useParams<{ uuid: string }>();
  const [isMessage, setIsMessage] = useState(false);
  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaUpdateTitipan),
  });

  function submitData(e: any) {
    dispatch(updateTitipan({uuid:oneData.uuid,update:e}));
    onClose();
    setIsMessage(true);
    setTimeout(() => {
      setIsMessage(false);
    }, 2000);
  }

  useEffect(() => {
    if(Object.keys(oneData).length !== 0){
        reset({
            bulan: oneData.bulan,
            ket: oneData.ket,
            nominal: oneData.nominal,
          });      
    }
  }, [show,oneData]);
  return (
    <div>
      <Modal show={show} onClose={onClose} modalName="Titipan">
        <form
          onSubmit={handleSubmit(submitData)}
          className="flex flex-col gap-2"
        >
          <InputSelect
            Name="bulan"
            labelName="Bulan:"
            placeholder="pilih bulan"
            control={control}
            error={errors.bulan?.message}
            options={optionsBulan}
          />
          <InputText
            Name="nominal"
            type="number"
            placeholder="nominal"
            labelName="Nominal"
            {...register("nominal")}
            error={errors.nominal?.message}
          />
          <InputText
            Name="ket"
            labelName="Keterangan:"
            placeholder="Keterangan"
            type="text"
            {...register("ket")}
            error={errors.ket?.message}
          />
          <button type="submit" className="btn">
            Simpan
          </button>
        </form>
      </Modal>
      <Message show={isMessage} message={message} status={status} />
    </div>
  );
}

export default FormEditTitipan;
