import { Controller, Control } from "react-hook-form";
import React from "react";
type InputRadioContinerType = {
  control: Control;
  Name: string;
  labelName?: string;
  error?: string;
  props: React.ProfilerProps;
  //   children: React.ReactNode;
};

function InputRadio(props: any) {
  return (
    <div>
      <div className="flex w-full md:px-2">
        <Controller
          control={props.control}
          name={props.Name}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => {
            return (
              <input
                onChange={(e) => {
                  onChange(e.target.value);
                  if (props.onChange) {
                    props.onChange(e.target.value);
                  }
                }}
                type="radio"
                className={`w-4 h-4 mt-1`}
                id={props.id}
                value={props.value}
                checked={props.value === value}
                ref={ref}
              />
            );
          }}
        />
        <label className="ml-2 text-md font-light" htmlFor={props.id}>
          {props.label}
        </label>
      </div>
    </div>
  );
}

export default InputRadio;
