import { formatCurrency } from "./formatCurrency";
import XLSX from "sheetjs-style";

export const toExcel = (data: any) => {
  return new Promise((res, _) => {
    let no = 0;
    let rekapSementara: any = [];
    let jsonSementara: any = {
      no: 0,
      nupy: "",
      nama: "",
      satker: "",
      tugas_pokok: "",
      gol: "",
      nominal_tugas_pokok: 0,
      tugas_tambahan: "",
      total_tugas_tambahan: 0,
      gapok: 0,
      normalisasi: 0,
      kehadiran: 0,
      thp: 0,
    };
    data.map((d: any) => {
      no += 1;
      jsonSementara.no = no;
      jsonSementara.nupy = d.nupy;
      jsonSementara.nama = d.nama;
      jsonSementara.tugas_pokok = d.tugas_pokok;
      jsonSementara.gol = d.golongan;
      jsonSementara.satker = d.satker;
      jsonSementara.nominal_tugas_pokok = d.tugas_pokok_nominal;
      if (d.tugas_tambahan !== null) {
        jsonSementara.total_tugas_tambahan = d.tugas_tambahan.total;
        let tugasTambahanSementara = "";
        d.tugas_tambahan.tugasTambahan.map((i: any, index: number) => {
          if (index === d.tugas_tambahan.tugasTambahan.length - 1) {
            tugasTambahanSementara += `${i.nama}-${i.nominal}`;
          } else {
            tugasTambahanSementara += `${i.nama}-${i.nominal},`;
          }
        });
        jsonSementara.tugas_tambahan = tugasTambahanSementara;
      }
      jsonSementara.gapok = d.gapok;
      jsonSementara.normalisasi = d.normalisasi;
      jsonSementara.kehadiran = d.kehadiran;
      jsonSementara.thp = d.thp;
      rekapSementara.push(jsonSementara);
      jsonSementara = {
        no: 0,
        nupy: "",
        nama: "",
        satker: "",
        tugas_pokok: "",
        gol: "",
        nominal_tugas_pokok: 0,
        tugas_tambahan: "",
        total_tugas_tambahan: 0,
        gapok: 0,
        normalisasi: 0,
        kehadiran: 0,
        thp: 0,
      };
    });
    res(rekapSementara);
  });
};

export const thpExcel = (data: any) => {
  return new Promise(async (res, _) => {
    let no = 0;
    let rekapSementara: any = [];
    let jsonSementara: any = {
      no: 0,
      nupy: "",
      nama: "",
      satker:"",
      thp: 0,
    };
    data.map((d: any) => {
      no += 1;
      jsonSementara.no = no;
      jsonSementara.nupy = d.ptk?.nupy;
      jsonSementara.nama = d.ptk?.nama;
      jsonSementara.satker = d.ptk?.statusPtk?.listSatker?.nama_satker;
      jsonSementara.thp = formatCurrency(d.finalTHP);
      rekapSementara.push(jsonSementara);
      jsonSementara = {
        no: 0,
        nupy: "",
        nama: "",
        satker:"",
        thp: 0,
      };
    });
    res(rekapSementara);
  });
};
export const parafExcel = (data: any) => {
  return new Promise(async (res, _) => {
    let no = 0;
    let rekapSementara: any = [];
    let jsonSementara: any = {
      no: 0,
      nupy: "",
      nama: "",
      satker: "",
      paraf: "",
    };
    data.map((d: any) => {
      no += 1;
      jsonSementara.no = no;
      jsonSementara.nupy = d.ptk?.nupy;
      jsonSementara.nama = d.ptk?.nama;
      jsonSementara.satker = d.ptk?.statusPtk?.listSatker?.nama_satker;
      rekapSementara.push(jsonSementara);
      jsonSementara = {
        no: 0,
        nupy: "",
        nama: "",
        satker: "",
        paraf: "",
      };
    });
    res(rekapSementara);
  });
};

export const absensiKhususExcel = (data: any) => {
  console.log(data)
  return new Promise((res, _) => {
    let no = 2;
    let rekapSementara = [];
    let jsonSementara: any = { no: "", nupy: "", nama: "" };
    let headerSementara: any = { no: "", nupy: "", nama: "" };
    for (let i = 0; i < data?.date_list?.length; i++) {
      for (let j = 0; j < 2; j++) {
        if (j === 0) {
          headerSementara[`${data.date_list[i]}-d`] = "";
        } else {
          headerSementara[`${data.date_list[i]}-p`] = "";
        }
      }
    }
    headerSementara.no = 1;
    headerSementara.nupy = data.ptk[0]?.nupy;
    headerSementara.nama = data.ptk[0]?.nama;
    rekapSementara.push(headerSementara);
    for (let k = 1; k < data.ptk.length; k++) {
      jsonSementara.no = no;
      jsonSementara.nupy = data.ptk[k].nupy;
      jsonSementara.nama = data.ptk[k].nama;
      no += 1;
      rekapSementara.push(jsonSementara);
      jsonSementara = { no: "", nupy: "", nama: "" };
    }
    res(rekapSementara);
  });
};

export const readDataExcel = (data: any): Promise<any> => {
  const reader = new FileReader();
  let final: any = [];
  reader.onload = (i) => {
    const data = i.target?.result;
    const wb = XLSX.read(data, { type: "array" });
    const sheetName = wb.SheetNames[0];
    const ws = wb.Sheets[sheetName];
    const json = XLSX.utils.sheet_to_json(ws);
    final = json;
  };
  reader.readAsArrayBuffer(data.target.files[0]);
  return final;
};
