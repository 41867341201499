import React, { useEffect, useState } from "react";
import { AppThunkDispatch, useAppSelector } from "../store";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { detailPegawai, updatePegwawai } from "../store/actions/actionPTK";
import FormUpdatePTK from "../components/forms/FormUpdatePTK";
import Message from "../components/Message";
import FormStatusPTK from "../components/forms/FormStatusPTK";
import HeaderUI from "../components/HeaderUI";
import FormNormalizeGaji from "../components/forms/FormNormalizeGaji";
import FormAbsensiPtk from "../components/forms/FormAbsensiPtk";
import FormInputCredit from "../components/forms/FormInputCredit";
import FormPostTitipan from "../components/forms/FormPostTitipan";
import { allLembaga } from "../store/actions/actionOptions";
import { getAllLocation } from "../store/actions/actionLocation";

function DetailPegawai() {
  const { uuid } = useParams<{ uuid: string }>();
  const [isMessage, setIsMessage] = useState(false);
  const [isModalUpdatePtk, setIsModalOpenUpdatePtk] = useState(false);
  const [isModalStatus, setIsModalStatus] = useState(false);
  const [isNormalisasi, setIsNormalisasi] = useState(false);
  const [isAbsensi, setIsAbsensi] = useState(false);
  const [isFormCredit, setIsFormCredit] = useState(false);
  const [isFormTitipan, setIsFormTitipan] = useState(false);

  const authState = useAppSelector((state) => state.auth);
  const myState = useAppSelector((state) => state.ptk);
  const dispatch = useDispatch<AppThunkDispatch>();
  useEffect(() => {
    if (uuid) {
      dispatch(detailPegawai(uuid));
    }
  }, [dispatch, uuid, isMessage]);

  const submitData = (data: TUpdatePTK) => {
    dispatch(updatePegwawai({ uuid: uuid, data }));
    setIsModalOpenUpdatePtk(false);
    setIsMessage(true);
    setTimeout(() => {
      setIsMessage(false);
    }, 2000);
  };

  useEffect(() => {
    dispatch(allLembaga());
    dispatch(getAllLocation())
  }, []);
  return (
    <>
      <HeaderUI title="Panel PTK" />
      <div className="container-table">
        <Message
          status={myState.status}
          show={isMessage}
          message={myState.message}
        />
        <div className="px-10 py-4 border shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-sm">
          <div className="py-3 border-b-2 border-b-gray-400">
            <h1 className="text-lg font-semibold">Data Pegawai</h1>
          </div>
          <div className="my-4">
            <div className="min-w-full flex flex-col md:flex-row justify-start md:justify-between my-2   px-2">
              <p className="text-base font-semibold">NUPY:</p>
              <p className="text-base font-light">
                {myState.selectedPegawai?.nupy}
              </p>
            </div>

            <div className="min-w-full flex flex-col md:flex-row justify-start md:justify-between my-2   px-2">
              <p className=" text-base font-semibold">Nama pegawai:</p>
              <p className="text-base font-light">
                {myState.selectedPegawai?.nama}
              </p>
            </div>
            <div className="min-w-full flex flex-col md:flex-row justify-start md:justify-between my-2   px-2">
              <p className="text-base font-semibold">Status Keaktifan:</p>
              <p className="text-base font-light">
                {myState.selectedPegawai?.statusPtk !== null
                  ? myState.selectedPegawai?.statusPtk?.active !== false
                    ? "Aktif"
                    : "Tidak Aktif"
                  : "-"}
              </p>
            </div>
            <div className="min-w-full flex flex-col md:flex-row justify-start md:justify-between my-2   px-2">
              <p className="text-base font-semibold">Status kepegawaian:</p>
              <p className="text-base font-light">
                {myState.selectedPegawai?.statusPtk !== null
                  ? myState.selectedPegawai?.statusPtk?.status_kepegawaian
                  : "-"}
              </p>
            </div>
            <div className="min-w-full flex flex-col md:flex-row justify-start md:justify-between my-2   px-2">
              <p className="text-base font-semibold">Nama satker:</p>
              <p className="text-base font-light">
                {myState.selectedPegawai?.statusPtk !== null
                  ? myState.selectedPegawai?.statusPtk?.listSatker?.nama_satker
                  : "-"}
              </p>
            </div>
            <div className="min-w-full flex flex-col md:flex-row justify-start md:justify-between my-2   px-2">
              <p className="text-base font-semibold">Nama tugas pokok:</p>
              <p className="text-base font-light">
                {myState.selectedPegawai?.statusPtk !== null
                  ? myState.selectedPegawai?.statusPtk?.listTugasPokok
                      ?.nama_tugasPokok
                  : "-"}
              </p>
            </div>
            <div className="min-w-full flex flex-col md:flex-row justify-start md:justify-between my-2   px-2">
              <p className="text-base font-semibold">No. Hp:</p>
              <p className="text-base font-light">
                {myState.selectedPegawai?.no_hp}
              </p>
            </div>
            <div className="min-w-full flex flex-col md:flex-row justify-start md:justify-between my-2   px-2">
              <p className="text-base font-semibold">Alamat:</p>
              <p className="text-base font-light">
                {myState.selectedPegawai?.alamat}
              </p>
            </div>
          </div>

          <div className="border-t-2 border-gray-400 py-4 ">
            <div className="flex flex-col text-center md:flex-row gap-2">
              {authState.role === "superAdmin" ||
              authState.role === "personalia" ? (
                <div className="flex flex-col text-center md:flex-row gap-2">
                  <button
                    onClick={() => {
                      setIsModalOpenUpdatePtk(true);
                    }}
                    className="btn py-2"
                  >
                    Edit ptk
                  </button>
                  <button
                    onClick={() => setIsModalStatus(true)}
                    className="btn py-2"
                  >
                    Status ptk
                  </button>
                  <a
                    href={`/home/list/tugas/tambahan/${uuid}`}
                    className="btn py-2"
                  >
                    Tugas tambahan
                  </a>
                </div>
              ) : (
                ""
              )}
              {authState.role === "superAdmin" && (
                <div className="flex flex-col text-center md:flex-row gap-2">
                  <button
                    onClick={() => setIsNormalisasi(true)}
                    className="btn py-2"
                  >
                    Normalisai
                  </button>
                  <button
                    onClick={() => setIsAbsensi(true)}
                    className="btn py-2"
                  >
                    Absensi
                  </button>
                  <button
                    onClick={() => setIsFormCredit(true)}
                    className="btn py-2"
                  >
                    Pinjaman
                  </button>
                  <button
                    onClick={() => setIsFormTitipan(true)}
                    className="btn py-2"
                  >
                    Titipan
                  </button>
                </div>
              )}
            </div>
          </div>
          <FormUpdatePTK
            submitData={submitData}
            onClose={() => setIsModalOpenUpdatePtk(false)}
            show={isModalUpdatePtk}
          />
          <FormStatusPTK
            onClose={() => setIsModalStatus(false)}
            show={isModalStatus}
          />
          <FormNormalizeGaji
            onClose={() => setIsNormalisasi(false)}
            show={isNormalisasi}
          />
          <FormAbsensiPtk
            show={isAbsensi}
            onClose={() => setIsAbsensi(false)}
          />
          <FormInputCredit
            show={isFormCredit}
            onClose={() => setIsFormCredit(false)}
          />
          <FormPostTitipan
            show={isFormTitipan}
            onClose={() => setIsFormTitipan(false)}
          />
        </div>
      </div>
    </>
  );
}

export default DetailPegawai;
