import api from "./api"

const apiEvent = {
    getAll:()=>{
        return api.get("/ptk/event/all/web")
    },
    add:(data:any)=>{
        return api.post("/ptk/event/",data)
    },
    update:(uuid:string, data:any)=>{
        return api.update(`/ptk/event/${uuid}`,data)
    },
    delete:(uuid:string)=>{
        return api.delete(`/ptk/event/${uuid}`)
    },
    getSesiAll:(uuid:string)=>{
        return api.get(`/ptk/sesi/all/${uuid}`)
    },
    addSesi:(data:any)=>{
        return api.post("/ptk/sesi/",data)
    },
    updateSesi:(uuid:string,data:any)=>{
        return api.update(`/ptk/sesi/${uuid}`,data)
    },
    getAbsensiAll:(uuid_sesi:string)=>{
        return api.get(`/ptk/sesi/absensi/${uuid_sesi}`)
    }
}

export default apiEvent