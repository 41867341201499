import React from "react";
import TableSatker from "../components/tables/TableSatker";
import Loading from "../components/Loading";
import { useAppSelector } from "../store";
import HeaderUI from "../components/HeaderUI";

function ListSatker() {
  const loading = useAppSelector((state) => state.satker.loadingSatker);
  return (
    <div>
      <HeaderUI title="Satker" />

      <Loading show={loading} />
      <TableSatker />
    </div>
  );
}

export default ListSatker;
