import { createAsyncThunk } from "@reduxjs/toolkit";
import apiGaji from "../../api/apiGaji";

export const getListGaji = createAsyncThunk(
    "gajiPtk",
    async(bulan:string,{rejectWithValue}:any)=>{
        try{
            const res = await apiGaji.getAll(bulan)
            if (res.status === 200) {
                return res.data
            }
        }catch (r){
            return rejectWithValue(r)
        }
    }
)

export const postNormalisasi = createAsyncThunk(
    "gajiPtk/normalisasi",
    async(data:any, {rejectWithValue}:any)=>{
        try{
            const res = await apiGaji.postNormalisasi(data)
            if(res.status === 200){
                return res.data.msg
            }
        }catch(r) {
            return rejectWithValue(r)
        }
    }
)

export const getAllListPenggajian = createAsyncThunk(
    "gajiPtk/all/listPenggajian",
    async(_,{rejectWithValue}:any)=>{
        try{
            const res = await apiGaji.allListPenggajian()
            if (res.status === 200){
                return res.data
            }
        }catch(r){
            return rejectWithValue(r)
        }
    }
)


export const getListPenggajian = createAsyncThunk(
    "gajiPtk/listPenggajian",
    async(_,{rejectWithValue}:any)=>{
        try{
            const res = await apiGaji.getLisPenggajian()
            if (res.status === 200){
                return res.data
            }
        }catch(r){
            return rejectWithValue(r)
        }
    }
)
export const allListPenggajianApprove = createAsyncThunk(
    "gajiPtk/listPenggajian/approve",
    async(_,{rejectWithValue}:any)=>{
        try{
            const res = await apiGaji.getLisPenggajianApprove()
            if (res.status === 200){
                return res.data
            }
        }catch(r){
            return rejectWithValue(r)
        }
    }
)


export const postAbsensi = createAsyncThunk(
    "gajiPtk/postAbsensi",
    async(data:any,{rejectWithValue}:any)=>{
        try{
            const res = await apiGaji.postAbsensi(data)
            if(res.status === 200){
                return res.data
            }
        }catch(r){
            return rejectWithValue(r)
        }
    }
)

export const getTaskStatus = createAsyncThunk(
    "gajiPtk/getTask",
    async(_,{rejectWithValue}:any)=>{
        try {
            const res = await apiGaji.getTask()
            if(res.status === 200){
                return res.data
            }
        }catch(r){
            return rejectWithValue(r)
        }
    }
)

export const postApprovalGaji = createAsyncThunk(
    "gajiPtk/postApprovalGaji",
    async(data:any,{rejectWithValue}:any)=>{
        try {
            const res = await apiGaji.postTask(data)
            if(res.status === 200){
                return res.data
            }
        }catch(r){
            return rejectWithValue(r)
        }
    }
)

export const getListGajiBykey = createAsyncThunk(
    "gajiPtk/listBykey",
    async(key:TpostGaji,{rejectWithValue}:any)=>{
        try{
            const res = await apiGaji.getListPtkGajiByKey(key)
            if (res.status === 200){
                return res.data
            }
        }catch(r){
            return rejectWithValue(r)
        }
    }
)