import api from "./api";


export const apiPTK = {
    getPegawai : ()=>{
        return api.get("ptk/pegawai")
    },
    PostPegawai: (data:TPTK)=>{
        return api.post("ptk/pegawai", data)
    },
    updatePegwawai: (uuid:string, data:TStatusPTK )=>{
        return api.update(`ptk/pegawai/${uuid}`, data)
    },
    deletePegawai: (uuid:string) => {
        return api.delete(`ptk/pegawai/${uuid}`)
    },
    getPegawaiByKey: (key:string)=>{
        return api.get(`ptk/pegawai/search/${key}`)
    },
    getPegawaiByUuid: (uuid:string)=>{
        return api.get(`ptk/pegawai/one/${uuid}`)
    },
    postStatusPegawai:(data:TStatusPTK)=>{
        return api.post("ptk/pegawai/status", data)
    },
    getGolongan:()=>{
        return api.get("/ptk/list-golongan")
    }
}
