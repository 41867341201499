import React, { useEffect, useState } from "react";
import HeaderUI from "../components/HeaderUI";
import TableContainer from "../components/TableContainer";
import { AppThunkDispatch, useAppSelector } from "../store";
import Select from "react-select";
import { useDispatch } from "react-redux";
import {
  eventGetAbsensi,
  eventGetAll,
  eventSesiAll,
} from "../store/actions/actionEvent";
import moment from "moment";
import { formatTimeOnly } from "../helpers/formatTIme";

function AbsensiPage() {
  const [idEvent, setIdEvent] = useState<any>({});
  const [idSesi, setIdSesi] = useState<any>({});

  const { sesiKegiatan, kegiatan, absensiEvent } = useAppSelector(
    (state) => state.event
  );

  const dispatch = useDispatch<AppThunkDispatch>();

  const eventOpts = kegiatan.map((i) => {
    return {
      value: i.uuid,
      label: i.kegiatan,
    };
  });
  const sesiOpts = sesiKegiatan.map((i) => {
    return {
      value: i.uuid,
      label: i.ket,
    };
  });

  useEffect(() => {
    dispatch(eventSesiAll(idEvent.value));
    dispatch(eventGetAbsensi(idSesi.value));
  }, [idEvent, idSesi]);

  useEffect(() => {
    dispatch(eventGetAll());
  }, []);
  return (
    <div>
      <HeaderUI title="Absensi kegiatan" />
      <div className="container-table">
        <div className="flex gap-3 shadow-[0_3px_10px_rgb(0,0,0,0.2)] mx-2 mb-5 rounded-md px-3 py-2">
          <div className="w-full">
            <label htmlFor="kegiatan">Pilih kegiatan:</label>
            <Select
              id="kegiatan"
              placeholder="Pilih kegiatan"
              options={eventOpts}
              value={idEvent}
              onChange={(i) => setIdEvent(i)}
            />
          </div>
          <div className="w-full">
            <label htmlFor="sesi">Pilih Sesi:</label>
            <Select
              id="sesi"
              placeholder="Pilih sesi"
              options={sesiOpts}
              value={idSesi}
              onChange={(i) => setIdSesi(i)}
            />
          </div>
        </div>
        <TableContainer>
          <table className="table">
            <thead className="thead">
              <tr>
                <th className="th">No</th>
                <th className="th">Nupy</th>
                <th className="th">Nama</th>
                <th className="th">Satker</th>
                <th className="th">gender</th>
                <th className="th">Kedatangan</th>
              </tr>
            </thead>
            <tbody className="tbody">
              {absensiEvent.map((i, index) => (
                <tr key={index}>
                  <td className="td">{index + 1}</td>
                  <td className="td">{i.nupy}</td>
                  <td className="td">{i.nama}</td>
                  <td className="td">{i.statusPtk?.listSatker?.nama_satker}</td>
                  <td className="td">{i.gender}</td>
                  <td className="td">
                    {i.absensi_sesi?.createdAt ? formatTimeOnly(i.absensi_sesi?.createdAt):"-"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableContainer>
      </div>
    </div>
  );
}

export default AbsensiPage;
