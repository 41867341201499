import React, { useEffect, useState } from 'react'
import HeaderUI from '../components/HeaderUI'
import Select from "react-select";
import { AppThunkDispatch, useAppSelector } from '../store';
import { useDispatch } from 'react-redux';
import { allLembaga } from '../store/actions/actionOptions';
import Input from '../components/fields/Input';
import { getRekapAbsensi_ } from '../store/actions/actionAbsensi';
import TableContainer from '../components/TableContainer';
import Loading from '../components/Loading';
import { allListPenggajianApprove, getAllListPenggajian, getListPenggajian } from '../store/actions/actionGaji';

function RekapAbsensiPtk() {
    const authState = useAppSelector((state) => state.auth);
    const { allRekapAbsensi, loadingAbsensi } = useAppSelector((state) => state.absensi)
    const { listPenggajian } = useAppSelector((state) => state.gaji)
    const dispatch = useDispatch<AppThunkDispatch>();
    const [saveLembaga, setSaveLembaga] = useState<any>({});
    const [saveBulan,setSaveBulan] = useState<any>({})
    const [lembagaOpt, setLembagaOpt] = useState<any>([]);
    const [listPenggajianOptions,setListPenggajianOptions] = useState<any>([])
    const [name, setName] = useState(null)
    const { lembagaAll } = useAppSelector((state) => state.options);
    useEffect(() => {
        if (lembagaAll.length !== 0) {
            let lembaga: any = [];
            lembagaAll.map((d: any) => {
                lembaga.push({ value: d.kode_lembaga, label: d.nama });
            });
            setLembagaOpt(lembaga);
            lembaga = [];
        }
        if(listPenggajian.length !== 0){
            let list_ :any=[]
            listPenggajian.map((e:any)=>{
                list_.push({value:e.index_bulan,label:e.bulan})
            })
            setListPenggajianOptions(list_)
            list_ = []
        }
    }, [lembagaAll,listPenggajian]);
    useEffect(() => {
        dispatch(getAllListPenggajian())
        dispatch(allLembaga());
    }, [])
    useEffect(() => {
        if (Object.keys(saveLembaga)?.length !== 0) {
            dispatch(getRekapAbsensi_({index_bulan:saveBulan?.value, kode_lembaga: saveLembaga?.value, key: name || "-" }))
        }
    }, [saveLembaga, name,saveBulan])
    return (
        <div>
            <Loading show={loadingAbsensi} />
            <HeaderUI title='Absensi' />
            <div className="bg-white mx-2 my-5 p-4 shadow-lg flex flex-col md:flex-row justify-between rounded-md">
                <div className="flex w-full flex-col md:flex-row justify-start gap-5">
                    <div className="w-72 mt-1">
                        <label htmlFor="lembaga">Pilih bulan:</label>
                        <Select
                            id="bulan"
                            placeholder="Pilih Bulan"
                            options={listPenggajianOptions}
                            value={saveBulan}
                            onChange={(i: any) => setSaveBulan(i)}
                        />
                    </div>
                    {authState.role === "superAdmin" || authState.role === "personalia" ?
                        <div className='flex gap-5'>
                            <div className="w-72 mt-1">
                                <label htmlFor="lembaga">Pilih lembaga:</label>
                                <Select
                                    id="lembaga"
                                    placeholder="Pilih lembaga"
                                    options={lembagaOpt}
                                    value={saveLembaga}
                                    onChange={(i: any) => setSaveLembaga(i)}
                                />
                            </div>
                        </div>
                        :
                        <></>
                    }
                    <div>
                        <Input label="Masukkan Nama" value={name} onChange={(e: any) => setName(e.target?.value)} />
                    </div>
                </div>
            </div>
            <TableContainer>
                <table className="table">
                    <thead className="thead">
                        <tr>
                            <th className="th">No</th>
                            <th className="th">Nupy</th>
                            <th className="th">Nama</th>
                            <th className="th">Lembaga</th>
                            <th className="th">Total Absensi</th>
                            {/* <th className="th">Aktif</th> */}
                        </tr>
                    </thead>
                    <tbody className="tbody">
                        {allRekapAbsensi?.map((d:any, i) => (
                            <tr className="tr" key={i}>
                                <td className="th">{i + 1}</td>
                                <td className="th">{d.ptk?.nupy}</td>
                                <td className="th">{d.ptk?.nama}</td>
                                <td className="th">{d.ptk?.statusPtk?.kode_lembaga ? lembagaOpt?.find((e:any)=>e.value === d.ptk?.statusPtk?.kode_lembaga)?.label : "-"}</td>
                                <td className="th">{d.total_absensi}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </TableContainer>
        </div>
    )
}

export default RekapAbsensiPtk