import { createSlice } from "@reduxjs/toolkit"
import { ApiStatus } from "../../constants/apiStatus"
import { deleteListTugasTambahan, getListTugasTambahan, postListTugasTambahan, updateListTugasTambahan } from "../actions/actionListTugasTambahan"
import apiListTugasTambahan from "../../api/apiListTugasTambahan"

type ListTugasTambahanState = {
    isLoading:boolean
    message:any
    status:ApiStatus
    listTugasTambahan: TListTugasTambahan[]
}

const initialState:ListTugasTambahanState = {
    isLoading:false,
    message:"",
    status:"IDLE",
    listTugasTambahan:[]
}

export const listTugasTambahanSlice = createSlice({
    name:"listTugasTambahan",
    initialState:initialState,
    reducers:{},
    extraReducers:builder=>{
        builder
        .addCase(getListTugasTambahan.pending,(state)=>{
            state.isLoading=true
            state.status="PENDING"
        })
        .addCase(getListTugasTambahan.fulfilled,(state, action)=>{
            state.isLoading=false
            state.listTugasTambahan=action.payload
            state.status="SUCCESS"
        })
        .addCase(getListTugasTambahan.rejected,(state,action)=>{
            state.isLoading=false
            state.message=action.error.message
            state.status="ERROR"
        })
        .addCase(postListTugasTambahan.pending,(state)=>{
            state.isLoading=true
            state.status="PENDING"
        })
        .addCase(postListTugasTambahan.fulfilled,(state,action)=>{
            state.isLoading=false
            state.message=action.payload.msg
            state.status="SUCCESS"
        })
        .addCase(postListTugasTambahan.rejected,(state, action)=>{
            state.isLoading=false
            state.message=action.error.message
            state.status="ERROR"
        })
        .addCase(updateListTugasTambahan.pending,(state)=>{
            state.isLoading=true
            state.status="PENDING"
        })
        .addCase(updateListTugasTambahan.fulfilled,(state, action)=>{
            state.isLoading=false
            state.message=action.payload.msg
            state.status="SUCCESS"
        })
        .addCase(updateListTugasTambahan.rejected,(state, action)=>{
            state.isLoading=false
            state.message=action.error.message
            state.status="ERROR"
        })
        .addCase(deleteListTugasTambahan.pending,(state)=>{
            state.isLoading=true
            state.status="PENDING"
        })
        .addCase(deleteListTugasTambahan.fulfilled,(state, action)=>{
            state.isLoading=false
            state.message=action.payload.msg
            state.status="SUCCESS"
        })
        .addCase(deleteListTugasTambahan.rejected,(state, action)=>{
            state.isLoading=false
            state.message=action.error.message
            state.status="ERROR"
        })
    }
})

export const listTugasTambahanActions = listTugasTambahanSlice.actions

export default listTugasTambahanSlice.reducer