import api from "./api"

const apiListTugasTambahan = {
    get:()=>{
        return api.get("/ptk/list-tugas-tambahan")
    },
    delete:(id:string)=>{
        return api.delete(`/ptk/list-tugas-tambahan/${id}`)
    },
    post:(data:TListTugasTambahan)=>{
        return api.post("/ptk/list-tugas-tambahan", data)
    },
    put:(id:string, data:TListTugasTambahan)=>{
        return api.update(`/ptk/list-tugas-tambahan/${id}`, data)
    }
}

export default apiListTugasTambahan