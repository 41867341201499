import { createAsyncThunk } from "@reduxjs/toolkit";
import  apiTugasPokok  from "../../api/apiTugasPokok";

export const getTugasPokok = createAsyncThunk(
    "tugasPokok",
    async(_,{rejectWithValue}:any)=>{
        try{
            const res = await apiTugasPokok.get()
            if (res.status === 200){
                return res.data
            }
        }catch(r){
            return rejectWithValue(r)
        }
    }
)
export const deleteTugasPokok = createAsyncThunk(
    "tugasPokok/delete",
    async(id:string, {rejectWithValue}:any)=>{
        try{
            const res = await apiTugasPokok.delete(id)
            if(res.status === 200){
                return res.data
            }
        }catch(r){
            return rejectWithValue(r)
        }
    }
)

export const addTugasPokok = createAsyncThunk(
    "tugasPokok/add",
    async(data:any, {rejectWithValue}:any)=>{
        try{
            const res = await apiTugasPokok.post(data)
            if(res.status === 200){
                return res.data
            }
        }catch(r){
            return rejectWithValue(r)
        }
    }
)

export const updateTugasPokok = createAsyncThunk(
    "tugasPokok/update",
    async(data:any,{rejectWithValue}:any)=>{
        try{
            const res = await apiTugasPokok.put(data.id, data.data)
            if (res.status === 200){
                return res.data
            }
        }catch(r){
            return rejectWithValue(r)
        }
    }
)