import React, { forwardRef } from "react";

type InputType = {
  labelName?: string;
  Name: string;
  placeholder?: string;
  defaultValue?: any;
  step?: string;
  type?: string;
  error?: string;
};

const InputText = forwardRef<HTMLInputElement, InputType>(
  (
    { labelName, Name, placeholder, defaultValue, step, type, error, ...props },
    ref
  ) => {
    return (
      <div className="flex flex-col bg-transparent relative w-full bg-amber-300">
        <label className="label" htmlFor={Name}>
          {labelName}
        </label>
        <input
          ref={ref}
          className="input-text"
          id={Name}
          step={step}
          defaultValue={defaultValue}
          type={type}
          placeholder={placeholder}
          {...props}
        />
        {error && <p className="error">{error}</p>}
      </div>
    );
  }
);

export default InputText;
