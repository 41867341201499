import React from "react";
import HeaderUI from "../components/HeaderUI";
import TableApprovalPokok from "../components/tables/TableApprovalPokok";
import TableApprovalTambahan from "../components/tables/TableApprovalTambahan";

function Approval() {
  return (
    <div className="min-w-full">
      <HeaderUI title="Approval" />

      <div className="flex flex-col lg:flex-row min-w-full ">
        <div className="flex-auto">
          <TableApprovalPokok />
        </div>
        <div className="flex-auto">
          <TableApprovalTambahan />
        </div>
      </div>
    </div>
  );
}

export default Approval;
