import React, { useEffect, useState } from "react";

import InputText from "../elements/InputText";
import Modal from "../Modal";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import InputSelect from "../elements/InputSelect";
import { useDispatch } from "react-redux";
import { AppThunkDispatch, useAppSelector } from "../../store";
import { postPegawai } from "../../store/actions/actionPTK";
import Message from "../Message";
import {
  optionsGander,
  optionsGolDarah,
  optionsPendidikan,
  optionsStatusPernikahan,
} from "../../constants/options";
import {
  getKabupaten,
  getKecamatan,
  getProvinsi,
} from "../../store/actions/actionAdress";
import { schemaPTK } from "../../constants/schemaInput";

function FormInputPTK({ show, onClose }: TModalProps) {
  const [showMessage, setShowMessage] = useState(false);
  const dispatch = useDispatch<AppThunkDispatch>();
  const myState = useAppSelector((state) => state.ptk);
  const stateAddress = useAppSelector((state) => state.address);

  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<TPTK>({
    resolver: yupResolver(schemaPTK),
  });

  const provWatch = watch("provinsi");
  const kabWatch = watch("kabupaten");

  const optionProvinsi = stateAddress.provinsi.map((i) => {
    return { value: i.id, label: i.nama };
  });
  const optionKabupaten = stateAddress.kabupaten.map((i) => {
    return { value: i.id, label: i.nama };
  });
  const optionKecamatan = stateAddress.kecamatan.map((i) => {
    return { value: i.id, label: i.nama };
  });

  const submitData = (data: TPTK) => {
    dispatch(postPegawai(data));
    setShowMessage(true);
    onClose();
    setTimeout(() => {
      setShowMessage(false);
    }, 2000);
  };
  useEffect(() => {
    if (provWatch !== "" && provWatch !== undefined) {
      dispatch(getKabupaten(provWatch));
    }
    if (kabWatch !== "" && kabWatch !== undefined) {
      dispatch(getKecamatan(kabWatch));
    }
  }, [provWatch, kabWatch, showMessage]);

  useEffect(() => {
    dispatch(getProvinsi());
    reset();
  }, [show]);

  return (
    <div>
      <Message
        show={showMessage}
        status={myState.status}
        message={myState.message}
      />
      <Modal modalName="Input PTK" show={show} onClose={onClose}>
        <form onSubmit={handleSubmit(submitData)} action="">
          <div className="grid grid-cols-2 gap-3">
            <InputText
              placeholder="Masukkan nama"
              type="text"
              Name="nama"
              labelName="Nama:"
              {...register("nama")}
              error={errors.nama?.message}
            />
            <InputText
              placeholder="Masukkan tempat lahir"
              type="text"
              Name="tempatLahir"
              labelName="Tempat Lahir:"
              {...register("tempat_lahir")}
              error={errors.tempat_lahir?.message}
            />
            <InputText
              placeholder="Masukkan tanggal lahir"
              type="date"
              Name="tanggalLahir"
              labelName="Tanggal Lahir:"
              {...register("tanggal_lahir")}
              error={errors.tanggal_lahir?.message}
            />
            <InputText
              placeholder="Masukkan alamat"
              type="text"
              Name="alamat"
              labelName="Alamat:"
              {...register("alamat")}
              error={errors.alamat?.message}
            />
            <InputSelect
              placeholder="Pilih provinsi"
              control={control}
              options={optionProvinsi}
              Name="provinsi"
              labelName="Provinsi:"
              error={errors.provinsi?.message}
            />
            <InputSelect
              placeholder="Pilih kabupaten"
              control={control}
              options={optionKabupaten}
              Name="kabupaten"
              labelName="Kabupaten:"
              error={errors.kabupaten?.message}
            />
            <InputSelect
              placeholder="Pilih kecamatan"
              control={control}
              options={optionKecamatan}
              Name="kecamatan"
              labelName="Kecamatan:"
              error={errors.kecamatan?.message}
            />
            <InputSelect
              placeholder="Pilih jenis kelamin"
              Name="gender"
              options={optionsGander}
              control={control}
              labelName="Jenis Kelamin:"
              error={errors.gender?.message}
            />
            <InputText
              placeholder="Masukkan nomer hp"
              type="text"
              Name="no_hp"
              labelName="No Handphone:"
              {...register("no_hp")}
              error={errors.no_hp?.message}
            />
            <InputSelect
              placeholder="Pilih status pernikahan"
              Name="status_pernikahan"
              control={control}
              options={optionsStatusPernikahan}
              labelName="Status Pernikahan:"
              error={errors.status_pernikahan?.message}
            />
            <InputSelect
              placeholder="Pilih pendidikan terakhir"
              control={control}
              options={optionsPendidikan}
              Name="pendidikan_terakhir"
              labelName="Pendidikan Terakhir:"
              error={errors.pendidikan_terakhir?.message}
            />
            <InputText
              placeholder="Masukkan gelar pendidikan"
              type="text"
              Name="gelar"
              labelName="Gelar:"
              {...register("gelar")}
              error={errors.gelar?.message}
            />

            <InputSelect
              placeholder="Pilih golongan darah"
              control={control}
              options={optionsGolDarah}
              Name="gol_darah"
              labelName="Golongan Darah:"
              error={errors.gol_darah?.message}
            />
          </div>
          <div className="flex justify-end mt-2">
            <button type="submit" className="btn px-8">
              Submit
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default FormInputPTK;
