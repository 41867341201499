import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import InputText from "../elements/InputText";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemaEditPinjaman } from "../../constants/schemaInput";
import InputRadio from "../elements/InputRadio";
import Message from "../Message";
import { useDispatch } from "react-redux";
import { AppThunkDispatch, useAppSelector } from "../../store";
import { editPinjaman } from "../../store/actions/actionPinjaman";

function FormEditCredit({ onClose, show, idToEdit }: TModalProps) {
  const { message, pegawai, status } = useAppSelector(
    (state) => state.pinjaman
  );
  const dispatch = useDispatch<AppThunkDispatch>();
  const [isMessage, setIsMessage] = useState(false);

  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaEditPinjaman),
  });

  function submitData(data: any) {
    dispatch(editPinjaman({ uuid: idToEdit, update: data }));
    onClose();
    setIsMessage(true);
    setTimeout(() => {
      setIsMessage(false);
    }, 2000);
  }

  useEffect(() => {
    reset({
      uuid: idToEdit,
    });
  }, [show]);
  return (
    <div>
      <Modal modalName="Edit Pinjaman" onClose={onClose} show={show}>
        <form
          action=""
          className="flex flex-col gap-2"
          onSubmit={handleSubmit(submitData)}
        >
          <InputText
            Name="optional_potongan"
            labelName="Optional potongan:"
            type="number"
            {...register("optional_potongan")}
            error={errors.optional_potongan?.message}
          />
          <div>
            <div className="flex flex-col">
              <label className="label">Proses:</label>
              <div className="flex flex-row">
                <InputRadio
                  Name="proses"
                  control={control}
                  value="true"
                  label="Diproses"
                />
                <InputRadio
                  Name="proses"
                  control={control}
                  value="false"
                  label="Tidak diproses"
                />
              </div>
            </div>
          </div>

          <button type="submit" className="btn">
            Simpan
          </button>
        </form>
      </Modal>
      <Message show={isMessage} status={status} message={message} />
    </div>
  );
}

export default FormEditCredit;
