import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import InputText from "../elements/InputText";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Message from "../Message";
import { AppThunkDispatch, useAppSelector } from "../../store";
import { useDispatch } from "react-redux";
import {
  eventAdd,
  eventGetAll,
  eventUpdate,
} from "../../store/actions/actionEvent";
import { schemaKegiatan } from "../../constants/schemaInput";


function FormAddKegiatan({ show, onClose, oneData }: TModalProps) {
  const { isLoading, message, status } = useAppSelector((state) => state.event);

  const dispatch = useDispatch<AppThunkDispatch>();

  const [isMessage, setIsMessage] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaKegiatan),
  });

  function submitData(data: any) {
    if (oneData) {
      dispatch(eventUpdate({ uuid: oneData.uuid, data: data }));
    } else {
      dispatch(eventAdd(data));
    }
    setIsMessage(true);
    setTimeout(() => {
      setIsMessage(false);
      dispatch(eventGetAll())
    }, 2000);
    onClose();
  }
  useEffect(() => {
    if (oneData) {
      reset({
        kegiatan: oneData.kegiatan,
        start_date: oneData.start_date,
        end_date: oneData.end_date,
      });
    }
  }, [show, isMessage]);

  useEffect(() => {
    dispatch(eventGetAll());
  }, [isMessage]);
  return (
    <div>
      <Message message={message} show={isMessage} status={status} />
      <Modal modalName="Kegiatan" show={show} onClose={onClose}>
        <form
          action=""
          className="flex flex-col gap-2"
          onSubmit={handleSubmit(submitData)}
        >
          <InputText
            type="text"
            Name="kegiatan"
            labelName="Kegiatan:"
            placeholder="Kegiatan"
            {...register("kegiatan")}
            error={errors.kegiatan?.message}
          />
          <InputText
            type="date"
            Name="start_date"
            labelName="Mulai:"
            {...register("start_date")}
            error={errors.start_date?.message}
          />
          <InputText
            type="date"
            Name="end_date"
            labelName="Selesai:"
            {...register("end_date")}
            error={errors.end_date?.message}
          />

          <button className="btn" type="submit">
            simpan
          </button>
        </form>
      </Modal>
    </div>
  );
}

export default FormAddKegiatan;
