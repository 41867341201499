import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { allLembaga, allListLembaga, allRole, allSistem } from '../store/actions/actionOptions'
import RadioForm from './fields/InputRadio'
import SelectorForm from './fields/SelectorForms'
// import { getLembaga, getLembagaList, getRole, getSistem } from '../store/actions/optional'

function Sistem(props: any) {
    const dispatch = useDispatch<any>()
    const { roleAll, sistemAll,lembagaAll } = useSelector((state: any) => state.options)
    const sistemWatch = props.method.watch(`sistem[${props.id}].uuid_sistem`)
    useEffect(() => {
        if (props.show) {
            dispatch(allSistem())
            dispatch(allLembaga())
        }
    }, [props.show])
    const watchRole = props.method.watch(`sistem[${props.id}].uuid_role`)
    const find = roleAll.find((e: any) => e.uuid === watchRole)?.nama_role
    const lemOpt = lembagaAll.map((d: any) => {
        return { value: d.kode_lembaga, label: d.nama }
    })
    const sistemOpt = sistemAll.map((d: any) => {
        return { value: d.uuid, label: d.nama_sistem }
    })
    const roleOpt = roleAll.map((d: any) => {
        return { value: d.uuid, label: d.nama_role }
    })
    useEffect(() => {
        if (sistemWatch !== undefined && sistemWatch !== null && sistemWatch !== "") {
            dispatch(allRole(sistemWatch))
        }
    }, [sistemWatch])
    return (
        <div className='w-full'>
            <SelectorForm label="Sistem" method={props.method} methodName={`sistem[${props.id}].uuid_sistem`} options={sistemOpt} />
            <SelectorForm label="Role" method={props.method} methodName={`sistem[${props.id}].uuid_role`} options={roleOpt} />
            <div className='mb-1'>
                <h1 className='font-light text-lg mb-2'>Super Admin</h1>
                <div className='flex my-auto md:space-x-0 space-x-2'>
                    <RadioForm method={props.method} methodName={`sistem[${props.id}].super_admin`} value="true" label="Iya" />
                    <RadioForm method={props.method} methodName={`sistem[${props.id}].super_admin`} value="false" label="Tidak" />
                </div>
                {props.method.formState.errors[`sistem[${props.id}].super_admin`] && (
                    <p className="text-red-600 mt-1 mx-1">
                        {props.method.formState.errors[`sistem[${props.id}].super_admin`].message}
                    </p>
                )}
            </div>
            <div className={find === "admin-lembaga" ? "block" : "hidden"}>
                <SelectorForm label="Lembaga" method={props.method} methodName={`sistem[${props.id}].no_lembaga`} options={lemOpt} />
            </div>
        </div>
    )
}

export default Sistem