import React, { useEffect, useRef, useState } from "react";
import HeaderUI from "../components/HeaderUI";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import Button from "../components/Button";
import { absensiKhususExcel } from "../helpers/exportToExcel";
import Select from "react-select";
import { optionsBulanExcel } from "../constants/options";
import { useDispatch } from "react-redux";
import { AppThunkDispatch, useAppSelector } from "../store";
import {
  addJadwalKhusus,
  getAllHeaderExcel,
  getAllJadwalKhusus,
} from "../store/actions/actionJadwalAbsensi";
import Loading from "../components/Loading";
import { allLembaga } from "../store/actions/actionOptions";
import { CloudArrowUpIcon } from "@heroicons/react/24/solid";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemaJadwalKhusus } from "../constants/schemaInput";
import TableContainer from "../components/TableContainer";
import Message from "../components/Message";
import Input from "../components/fields/Input";
import { formatWithDay } from "../helpers/formatTIme";

function JadwalKhusus() {
  const [thead, setThead] = useState<any>([]);
  const [isMessage, setIsMessage] = useState(false);
  const dispatch = useDispatch<AppThunkDispatch>();
  const { headerExcel, isLoading, message, status } = useAppSelector(
    (state) => state.jadwalAbsensi
  );
  const { lembagaAll } = useAppSelector((state) => state.options);
  const { allJadwal } = useAppSelector((state) => state.jadwalAbsensi)
  const [saveBulan, setSaveBulan] = useState<any>({});
  const [saveLembaga, setSaveLembaga] = useState<any>({ value: "_", label: "" });
  const [saveLembagaRincian, setSaveLembagaRincian] = useState<any>({});
  const [lembagaOpt, setLembagaOpt] = useState<any>([]);
  const refFileInput = useRef<any>();
  const [loadingUploadExcel, setLoadingUploadExcel] = useState(false);
  const authState = useAppSelector((state) => state.auth);
  const [jsonFromExcel, setJsonFormExcel] = useState<any>([]);
  const [startJadwal, setStartJadwal] = useState("")
  const [endJadwal, setEndJadwal] = useState("")
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  let exceptLembaga: any = [
    "DAPUR",
    "KEAMANAN",
    "UKS",
    "ASRAMA PUTRA",
    "ASRAMA PUTRI",
  ];

  const onUpload = useForm({
    resolver: yupResolver(schemaJadwalKhusus),
  });

  function handleSubmit(d: any) {
    dispatch(addJadwalKhusus(d));
  }
  useEffect(() => {
    if (status === "SUCCESS" || status === "ERROR") {
      setIsMessage(true);
      setTimeout(() => {
        setIsMessage(false);
      }, 3000);
    }
  }, [status])
  useEffect(() => {
    let lembaga: any = [];
    lembagaAll.map((d: any) => {
      if (exceptLembaga.includes(d.nama)) {
        lembaga.push({ value: d.kode_lembaga, label: d.nama });
      }
    });
    setLembagaOpt(lembaga);
    lembaga = [];
  }, [lembagaAll]);
  const downloadExcel = async () => {
    if (headerExcel) {
      const formatExcel: any = await absensiKhususExcel(headerExcel);
      const ws = XLSX.utils.json_to_sheet(formatExcel);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(
        data,
        `Rekap Absensi ${saveLembaga.label}-${saveBulan.label}` + fileExtension
      );
    }
  };

  const readExcel = (e: any) => {
    if (e.target.files) {
      setLoadingUploadExcel(true);
      const reader = new FileReader();
      reader.onload = (i) => {
        const data = i.target?.result;
        const wb = XLSX.read(data, { type: "array" });
        const sheetName = wb.SheetNames[0];
        const ws = wb.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(ws);
        setJsonFormExcel(json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
      setLoadingUploadExcel(false);
    }
  };
  const findJadwalKhusus = () => {
    dispatch(getAllJadwalKhusus({ start: startJadwal, end: endJadwal, kode_lembaga: saveLembagaRincian.value }))
  }
  useEffect(() => {
    dispatch(allLembaga());
    if (jsonFromExcel.length !== 0) {
      onUpload.setValue("jadwal", jsonFromExcel);
      setThead(Object.keys(jsonFromExcel[0]));
    }
    if (saveBulan) {
      onUpload.setValue("bulan", saveBulan.value);
    }
  }, [jsonFromExcel, saveBulan]);
  useEffect(() => {
    if (
      Object.keys(saveBulan).length !== 0 &&
      Object.keys(saveLembaga).length !== 0
    ) {
      dispatch(
        getAllHeaderExcel({
          bulan: saveBulan.value,
          lembaga: saveLembaga.value,
        })
      );
    }
  }, [saveBulan, saveLembaga]);
  return (
    <div>
      <Loading show={isLoading} />
      <Message show={isMessage} message={message} status={status} />
      <HeaderUI title="Jadwal Khusus" />
      <div className="bg-white mx-2 my-5 p-4 shadow-lg flex flex-col md:flex-row justify-between rounded-md">
        <div className="flex w-full flex-col md:flex-row justify-start gap-5">
          <div className="w-full ">
            <label htmlFor="bulan">Pilih bulan:</label>
            <Select
              id="bulan"
              placeholder="Pilih bulan"
              options={optionsBulanExcel}
              value={saveBulan}
              onChange={(i: any) => setSaveBulan(i)}
            />
            {onUpload.formState.errors && (
              <p className="error">
                {onUpload.formState.errors.bulan?.message}
              </p>
            )}
          </div>
          {authState.role === "superAdmin" || authState.role === "personalia" ?
            <div className="w-full">
              <label htmlFor="lembaga">Pilih lembaga:</label>
              <Select
                id="lembaga"
                placeholder="Pilih lembaga"
                options={lembagaOpt}
                value={saveLembaga}
                onChange={(i: any) => setSaveLembaga(i)}
              />
            </div>
            :
            <></>
          }
        </div>
        <div className="mt-4 flex w-full justify-end items-end">
          <Button
            title="Download Excel"
            type="button"
            onClick={downloadExcel}
          />
        </div>
      </div>
      <div className="bg-white">
        {jsonFromExcel.length !== 0 ? (
          <TableContainer
            btnText="Simpan"
            btnText2="Hapus excel"
            handleAdd={onUpload.handleSubmit(handleSubmit)}
            button2={() => setJsonFormExcel([])}
          >
            <table className="table">
              <thead className="thead">
                <tr className="tr">
                  {thead?.map((d: any, i: any) => (
                    <th className="th" key={i}>
                      {d}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="tbody">
                {jsonFromExcel?.map((d: any, i: any) => (
                  <tr className="tr" key={i}>
                    {thead?.map((t: any, index: any) => (
                      <td className="td" key={index}>
                        {d[thead[index]]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </TableContainer>
        ) : (
          <div
            className="p-4 mb-3 mx-2 flex flex-col items-center gap-2 bg-violet-50 text-slate-800 rounded-lg hover:bg-violet-100 cursor-pointer"
            onClick={() => refFileInput.current?.click()}
          >
            <CloudArrowUpIcon className="w-6 h-6" />
            <span>Upload Excel Absensi</span>
            <input
              type="file"
              ref={refFileInput}
              className="hidden"
              onChange={readExcel}
            />
          </div>
        )}
      </div>
      <div className="bg-white mx-2 my-5 p-4 shadow-lg flex justify-between rounded-md">
        <div className="flex gap-5">
          <div className="w-72">
            <Input className="w-72" label="Dari" type="date" value={startJadwal} onChange={(e: any) => setStartJadwal(e.target.value)} />
          </div>
          <div className="w-72">
            <Input className="w-72" label="Sampai" type="date" value={endJadwal} onChange={(e: any) => setEndJadwal(e.target.value)} />
          </div>
          {authState.role === "superAdmin" || authState.role === "personalia" ?
            <div className="w-72">
              <div className="mt-[6px] z-10">
                <label htmlFor="lembaga">Pilih lembaga:</label>
                <Select
                  id="lembaga"
                  placeholder="Pilih lembaga"
                  options={lembagaOpt}
                  value={saveLembagaRincian}
                  onChange={(i: any) => setSaveLembagaRincian(i)}
                />
              </div>
            </div>
            :
            <></>
          }
        </div>
        <div className="mt-4 flex w-full justify-end items-end">
          <Button
            title="Filter"
            type="button"
            onClick={findJadwalKhusus}
          />
        </div>
      </div>
      <TableContainer>
        <table className="table">
          <thead className="thead">
            <tr className="tr">
              <td className="td">No</td>
              <td className="td">Masuk</td>
              <td className="td">Pulang</td>
              <td className="td">Nupy</td>
              <td className="td">Nama</td>
            </tr>
          </thead>
          <tbody className="tbody">
            {allJadwal?.map((d: any, i: any) => (
              d.jadwal_ptks?.map((e: any, i: number) => (
                <tr className="tr" key={i}>
                  <td className="td">{i + 1}</td>
                  <td className="td">{e.start ? formatWithDay(e.start) : "-"}</td>
                  <td className="td">{e.end ? formatWithDay(e.end) : "-"}</td>
                  <td className="td">{d.nupy}</td>
                  <td className="td">{d.nama}</td>
                </tr>
              ))
            ))}
          </tbody>
        </table>
      </TableContainer>
    </div>
  );
}

export default JadwalKhusus;
