import React, { useEffect, useState } from "react";
import TableContainer from "../TableContainer";
import { DocumentTextIcon, TrashIcon } from "@heroicons/react/24/solid";
import { AppThunkDispatch, useAppSelector } from "../../store";
import { useDispatch } from "react-redux";
import {
  deleteListTugasTambahan,
  getListTugasTambahan,
} from "../../store/actions/actionListTugasTambahan";
import FormListTugasTambahan from "../forms/FormListTugasTambahan";
import Confirm from "../Confirm";
import Message from "../Message";

function TableListTugasTambahan() {
  const [isConfirm, setIsConfirm] = useState(false);
  const [isMessage, setIsMessage] = useState(false);
  const [dataToUpdate, setDataToUpdate] = useState<any>();
  const [showModal, setShowModal] = useState(false);
  const [dataToDelete, setDataToDelete] = useState<string>("");

  const stateData = useAppSelector((state) => state.listTugasTambahan);
  const dispatch = useDispatch<AppThunkDispatch>();
  const data = stateData.listTugasTambahan;

  function handleModal() {
    setShowModal(true);
  }

  function handleDelete(id: string) {
    setIsConfirm(true);
    setDataToDelete(id);
  }

  function confirmDelete() {
    dispatch(deleteListTugasTambahan(dataToDelete));
    setIsConfirm(false);
    setIsMessage(true);
    setTimeout(() => {
      setIsMessage(false);
    }, 2000);
  }

  useEffect(() => {
    dispatch(getListTugasTambahan());
  }, [isMessage]);

  return (
    <div>
      <FormListTugasTambahan
        onClose={() => setShowModal(false)}
        show={showModal}
        dataToUpdate={dataToUpdate}
      />
      <div className="md:px-3 py-3">
        <TableContainer handleAdd={handleModal} btnText="Tambah">
          <table className="table">
            <thead className="thead">
              <tr className="tr">
                <th scope="col" className="th">
                  No
                </th>
                <th scope="col" className="th">
                  Kode
                </th>
                <th scope="col" className="th">
                  Nama
                </th>
                <th scope="col" className="th">
                  Nominal
                </th>
                <th scope="col" className="th">
                  Edit
                </th>
                <th scope="col" className="th">
                  Hapus
                </th>
              </tr>
            </thead>
            <tbody className="tbody">
              {data.map((d, i) => (
                <tr className="tr" key={i}>
                  <td className="td">{i + 1}</td>
                  <td className="td">{d.kode_tugasTambahan}</td>
                  <td className="td">{d.nama_tugasTambahan}</td>
                  <td className="td">{d.nominal}</td>
                  <td className="td">
                    <button
                      onClick={() => {
                        setShowModal(true);
                        setDataToUpdate(d);
                      }}
                    >
                      <DocumentTextIcon className="icon" />
                    </button>
                  </td>
                  <td className="td">
                    <button
                      onClick={() => handleDelete(`${d.kode_tugasTambahan}`)}
                    >
                      <TrashIcon className="icon" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableContainer>
      </div>
      <Confirm
        onClose={() => setIsConfirm(false)}
        show={isConfirm}
        text="Yakin data ini dihapus?"
      >
        <div className="flex gap-1 items-center justify-center">
          <button className="btn w-full" onClick={confirmDelete}>
            Ya
          </button>
        </div>
      </Confirm>
      <Message
        status={stateData.status}
        show={isMessage}
        message={stateData.message}
      />
    </div>
  );
}

export default TableListTugasTambahan;
