import api from "./api"
const apiAuth = {
    login: (data: any) => {
        return api.post("/login", data)
    },
    logout:()=>{
        return api.get("/logout")
    }
    
}
export default apiAuth