import { createAsyncThunk } from "@reduxjs/toolkit";
import apiDaftarHadir from "../../api/apiDaftarHadir";

export const getDaftarHadirByFilter = createAsyncThunk(
    "daftarHadir/getByFilter",
    async (data:any, {rejectWithValue})=>{
        try {
            const res = await apiDaftarHadir.getByFilter(data)
            if (res.status === 200){
                return res.data
            }
        }catch(r:any){
            return rejectWithValue(r)
        }
    }
)