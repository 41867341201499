import { createSlice } from "@reduxjs/toolkit"
import { ApiStatus } from "../../constants/apiStatus"
import { deleteTugasTambahan, getTugasTambahanByUuidPtk, postTugasTambahan, updateTugasTambahan } from "../actions/actionTugasTambahan"

type TugasTambahanState = {
    isLoading:boolean
    message:any
    status:ApiStatus
    tugasTambahan: TDataTugasTambahan[]
}

const initialState:TugasTambahanState = {
    isLoading:false,
    message:"",
    status:"IDLE",
    tugasTambahan: [],
}

export const tugasTambahanSlice = createSlice({
    name:"tugasTambahan",
    initialState:initialState,
    reducers:{},
    extraReducers:builder=>{
        builder
        .addCase(getTugasTambahanByUuidPtk.pending,(state)=>{
            state.isLoading=true
            state.status="PENDING"
        })
        .addCase(getTugasTambahanByUuidPtk.fulfilled,(state, action)=>{
            state.isLoading=false
            state.tugasTambahan=action.payload
            state.status="SUCCESS"
        })
        .addCase(getTugasTambahanByUuidPtk.rejected,(state, action)=>{
            state.isLoading=false
            state.message=action.error.message
            state.status="ERROR"
        })
        .addCase(postTugasTambahan.pending,(state)=>{
            state.isLoading=true
            state.status="PENDING"
        })
        .addCase(postTugasTambahan.fulfilled,(state, action)=>{
            state.isLoading=false
            state.message=action.payload.msg
            state.status="SUCCESS"
        })
        .addCase(postTugasTambahan.rejected,(state, action)=>{
            state.isLoading=false
            state.message=action.error.message
            state.status="ERROR"
        })
        .addCase(updateTugasTambahan.pending,(state)=>{
            state.isLoading=true
            state.status="PENDING"
        })
        .addCase(updateTugasTambahan.fulfilled,(state, action)=>{
            state.isLoading=false
            state.message=action.payload.msg
            state.status="SUCCESS"
        })
        .addCase(updateTugasTambahan.rejected,(state, action)=>{
            state.isLoading=false
            state.message=action.error.message
            state.status="ERROR"
        })
        .addCase(deleteTugasTambahan.pending,(state)=>{
            state.isLoading=true
            state.status="PENDING"
        })
        .addCase(deleteTugasTambahan.fulfilled,(state, action)=>{
            state.isLoading=false
            state.message=action.payload.msg
            state.status="SUCCESS"
        })
        .addCase(deleteTugasTambahan.rejected,(state, action)=>{
            state.isLoading=false
            state.message=action.error.message
            state.status="ERROR"
        })
        
    }
})

export const tugasTambahanAction = tugasTambahanSlice.actions
export default tugasTambahanSlice.reducer