import { createSlice } from "@reduxjs/toolkit"
import { addTugasPokok, deleteTugasPokok, getTugasPokok, updateTugasPokok } from "../actions/actionTugasPokok"
import { ApiStatus } from "../../constants/apiStatus"

type tugasPokokState = {
    tugasPokokAll : TListTugasPokok[],
    tugasPokokSelected : {} | null,
    message : any,
    isLoading : boolean
    status:ApiStatus
}

const initialState:tugasPokokState ={
    tugasPokokAll:[],
    tugasPokokSelected:null,
    message:"",
    isLoading:false,
    status:"IDLE"
}

export const tugasPokokSlice = createSlice({
    name:"tugasPokok",
    initialState,
    reducers:{
        
    },
    extraReducers:builder=>{
        builder
        .addCase(getTugasPokok.pending,(state)=>{
            state.isLoading=true
            state.status="PENDING"
        })
        .addCase(getTugasPokok.fulfilled,(state,action)=>{
            state.isLoading=false
            state.tugasPokokAll=action.payload
            state.status="SUCCESS"
        })
        .addCase(getTugasPokok.rejected,(state,action)=>{
            state.isLoading = false
            state.message = action.error.message
            state.status = "ERROR"
        })
        .addCase(addTugasPokok.pending,(state,action)=>{
            state.isLoading = true
            state.status="PENDING"
        })
        .addCase(addTugasPokok.fulfilled,(state,action)=>{
            state.isLoading=false
            state.message=action.payload?.msg
            state.status="SUCCESS"
        })
        .addCase(addTugasPokok.rejected,(state, action)=>{
            state.isLoading=false
            state.message=action.error.message
            state.status="ERROR"
        })
        .addCase(deleteTugasPokok.pending,(state)=>{
            state.isLoading = true
            state.status="PENDING"
        })
        .addCase(deleteTugasPokok.fulfilled,(state, action)=>{
            state.isLoading=false
            state.message=action.payload?.msg
            state.status="SUCCESS"
        })
        .addCase(deleteTugasPokok.rejected,(state,action)=>{
            state.isLoading=false
            state.message=action.error.message
            state.status="ERROR"
        })
        .addCase(updateTugasPokok.pending,(state)=>{
            state.isLoading = true
            state.status="PENDING"
        })
        .addCase(updateTugasPokok.fulfilled,(state,action)=>{
            state.isLoading=false
            state.message=action.payload?.msg
            state.status="SUCCESS"
        })
        .addCase(updateTugasPokok.rejected,(state,action)=>{
            state.isLoading=false
            state.message=action.error.message
            state.status="ERROR"
        })
    }
})

export default tugasPokokSlice.reducer 