import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiPTK } from "../../api/apiPTK";

export const getPegawai = createAsyncThunk<TPegawaiType[]>(
    "ptk",
    async(_, {rejectWithValue}:any)=>{
        try{
            const res = await apiPTK.getPegawai()
            if (res.status === 200) {
                return res.data
            }
        }catch(r) {
            return rejectWithValue(r)
        }
    }
)

export const postPegawai = createAsyncThunk<TPTK, any>(
    "ptk/addPegawai",
    async (data, {rejectWithValu}:any) =>{
        try{
            const res = await apiPTK.PostPegawai(data)
            if(res.status === 200){
                return res.data?.msg
            }
        } catch(r) {
            return rejectWithValu(r)
        }
    }
)

export const deletePegawai = createAsyncThunk(
    "ptk/deletePegawai",
    async(uuid:any, {rejectWithValue}:any)=>{
        try{
            const res = await apiPTK.deletePegawai(uuid)
            if (res.status === 200) {
                return res.data?.msg
            }
        } catch (r) {
            return rejectWithValue(r)
        }
    }
)

export const searchPegawai = createAsyncThunk(
    "ptk/searchPegawai",
    async(key:string, {rejectWithValue}:any) =>{
        try{
            const res = await apiPTK.getPegawaiByKey(key)
            if (res.status === 200) {
                return res.data
            }
        }catch(r){
            return rejectWithValue(r)
        }
    }
)

export const detailPegawai = createAsyncThunk (
    "ptk/detailPegawai",
    async(uuid:any, {rejectWithValue}:any)=>{
        try{
            const res = await apiPTK.getPegawaiByUuid(uuid)
            if (res.status === 200) {
                return res.data
            }
        }catch (r){
            return rejectWithValue(r)
        }
    }
)

export const updatePegwawai = createAsyncThunk<TUpdatePTK, any>(
    "ptk/updatePegawai",
    async(data, {rejectWithValue}:any)=>{
        try{
            const res = await apiPTK.updatePegwawai(data.uuid, data.data)
            if(res.status === 200){
                return res.data.msg
            }
        }catch(r){
            return rejectWithValue(r)
        }
    }
)

export const updateStatusPTK = createAsyncThunk(
    "ptk/updateStatus",
    async(data:TStatusPTK, {rejectWithValue}:any)=>{
        try{
            const res = await apiPTK.postStatusPegawai(data)
            if (res.status === 200){
                return res.data
            }
        }catch(r){
            return rejectWithValue(r)
        }
    }
)

export const getGolongan = createAsyncThunk(
    "ptk/golongan",
    async(_,{rejectWithvalue}:any)=>{
        try{
            const res = await apiPTK.getGolongan()
            if (res.status === 200){
                return res.data
            }
        }catch(r){
            return rejectWithvalue(r)
        }
    }
)
