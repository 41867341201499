import {  createSlice } from "@reduxjs/toolkit"
import { getAllAbsensi, getRekapAbsensi_, rekapAbsensi, rekapAbsensiByKey, updateAbsensiByExcel } from "../actions/actionAbsensi"
import { ApiStatus } from "../../constants/apiStatus"

type absensiEventState ={
    status: ApiStatus
    allRekapAbsensi : string[]
    messageAbsensi :any,
    loadingAbsensi :boolean
    allAbsensi : string[]
}
const initialState  : absensiEventState= {
    status: "IDLE",
    allRekapAbsensi : [],
    allAbsensi : [],
    loadingAbsensi : false,
    messageAbsensi : ""
}

export const absensiEventStore = createSlice({
    name : "absensiEvent",
    initialState,
    reducers :{
        clearRekapAbsensi :(state)=>{
            state.allRekapAbsensi = []
            state.messageAbsensi = ""
            state.status = "IDLE"
        }
    },
    extraReducers:builder=>{
        builder
        .addCase(rekapAbsensi.pending,(state)=>{
            state.loadingAbsensi = true
            state.status = "PENDING"
        })
        .addCase(rekapAbsensi.fulfilled,(state,action)=>{
            state.loadingAbsensi = false
            state.status="SUCCESS"
            state.allRekapAbsensi = action.payload
        })
        .addCase(rekapAbsensi.rejected,(state,action)=>{
            state.loadingAbsensi = false
            state.status="ERROR"
            state.allRekapAbsensi = []
            state.messageAbsensi = action.payload
        })
        .addCase(rekapAbsensiByKey.pending,(state)=>{
            state.loadingAbsensi = true
            state.status = "PENDING"
        })
        .addCase(rekapAbsensiByKey.fulfilled,(state,action)=>{
            state.loadingAbsensi = false
            state.status="SUCCESS"
            state.allRekapAbsensi = action.payload
        })
        .addCase(rekapAbsensiByKey.rejected,(state,action)=>{
            state.loadingAbsensi = false
            state.status="ERROR"
            state.allRekapAbsensi = []
            state.messageAbsensi = action.payload
        })
        .addCase(updateAbsensiByExcel.pending,(state)=>{
            state.loadingAbsensi = true
            state.status = "PENDING"
        })
        .addCase(updateAbsensiByExcel.fulfilled,(state,action)=>{
            state.loadingAbsensi = false
            state.status="SUCCESS"
            state.messageAbsensi = action.payload.msg
        })
        .addCase(updateAbsensiByExcel.rejected,(state,action)=>{
            state.loadingAbsensi = false
            state.status="ERROR"
            state.allRekapAbsensi = []
            state.messageAbsensi = action.payload
        })
        .addCase(getAllAbsensi.pending,(state)=>{
            state.loadingAbsensi = true
            state.status = "PENDING"
        })
        .addCase(getAllAbsensi.fulfilled,(state,action)=>{
            state.loadingAbsensi = false
            state.status = "SUCCESS"
            state.allAbsensi = action.payload
        })
        .addCase(getAllAbsensi.rejected,(state,action)=>{
            state.loadingAbsensi = false
            state.status = "ERROR"
            state.allAbsensi = []
            state.messageAbsensi = action.payload
        })
        .addCase(getRekapAbsensi_.pending,(state)=>{
            state.loadingAbsensi = true
            state.status = "PENDING"
        })
        .addCase(getRekapAbsensi_.fulfilled,(state,action)=>{
            state.loadingAbsensi = false
            state.status = "SUCCESS"
            state.allRekapAbsensi = action.payload
        })
        .addCase(getRekapAbsensi_.rejected,(state,action)=>{
            state.loadingAbsensi = false
            state.status = "ERROR"
            state.allRekapAbsensi = []
            state.messageAbsensi = action.payload
        })

    }
})

export const absensiEventActions =absensiEventStore.actions
export default absensiEventStore.reducer