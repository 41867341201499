import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { HiEye, HiEyeSlash } from 'react-icons/hi2'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from "yup"
import Dialog from '../Dialog'
import InputForm from '../fields/InputForm'
import RadioForm from '../fields/InputRadio'
import Button from '../Button'
import Sistem from '../Sistem'


function AddUser(props: any) {
    const [eye, setEye] = useState<boolean>(false)
    const methodAddUser = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                nama: yup.string().required("Tidak Boleh Kosong"),
                username: yup.string().required("Tidak Boleh Kosong"),
                password: yup.string().min(8, "Minimal 8 Karakater").required("Tidak Boleh Kosong"),
                general_user: yup.string().oneOf(["true", "false"], "Pilih Salah Satu").required("Tidak Boleh Kosong"),
                sistem: yup.array().of(yup.object().shape({
                    uuid_sistem: yup.string().required("Tidak Boleh Kosong"),
                    uuid_role: yup.string().required("Tidak Boleh Kosong"),
                    super_admin: yup.string().oneOf(["true", "false"], "Pilih Salah Satu").required("Tidak Boleh Kosong"),
                    no_lembaga: yup.string()
                }))
            })
        ),
        defaultValues: { sistem: [{ uuid_sistem: "", uuid_role: "" }] }
    })
    const { fields, append, remove } = useFieldArray<any>({
        control: methodAddUser.control, name: "sistem"
    })
    useEffect(() => {
        methodAddUser.reset()
    }, [props.showForm])
    return (
        <Dialog show={props.showForm} toggle={props.closeForm}>
            <div className='bg-white md:w-[50vw] w-[90vw]'>
                <div className='py-4 px-6 border-b border-slate-200'>
                    <h1 className='text-xl'>Formulir Tambah User</h1>
                </div>
                <form onSubmit={methodAddUser.handleSubmit(props.submit)} className='px-6 py-7 space-y-1'>
                    <div className='flex justify-between space-x-4'>
                        <div className='w-[100%]'>
                            <InputForm
                                label="Nama"
                                method={methodAddUser}
                                methodName="nama"
                            />
                            <InputForm
                                label="username"
                                method={methodAddUser}
                                methodName="username"
                            />
                            <InputForm
                                type={eye ? "text" : "password"}
                                label="Password"
                                method={methodAddUser}
                                methodName="password"
                                icon={eye ? <HiEye className='w-6 h-6 -mr-3 cursor-pointer mt-3 absolute right-5' onClick={() => setEye(!eye)} /> : <HiEyeSlash className='w-6 h-6 my-auto -mr-3 cursor-pointer mt-3 absolute right-5' onClick={() => setEye(!eye)} />}
                            />
                            <div>
                                <h1 className='font-light text-lg mb-2'>General User</h1>
                                <div className='flex my-auto md:space-x-0 space-x-2'>
                                    <RadioForm method={methodAddUser} methodName="general_user" value="true" label="Iya" />
                                    <RadioForm method={methodAddUser} methodName="general_user" value="false" label="Tidak" />
                                </div>
                                {methodAddUser.formState.errors["general_user"] && (
                                    <p className="text-red-600 mt-1 mx-1">
                                        {methodAddUser.formState.errors["general_user"].message}
                                    </p>
                                )}
                            </div>
                            <button type='button' onClick={() => append({ uuid_sistem: "", uuid_role: "" })} className="border border-sky-400  px-8 py-2 hover:text-sky-800 hover:border-sky-600 transition-colors ease-in-out duration-300 w-full my-5">Tambah Sistem</button>
                        </div>

                        <div className='w-full'>
                            {fields.map((d, id) => (
                                <div key={id}>
                                    <Sistem method={methodAddUser} id={id} show={props.showForm} />
                                    <button type='button' onClick={() => remove(id)} className="border border-red-700 my-1 px-5 py-[2px] hover:border-red-800 transition-colors ease-in-out duration-300 hover:text-red-800">Hapus</button>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='flex justify-end'>
                        <Button type="submit" title="Simpan" />
                    </div>
                </form>
            </div>
        </Dialog>
    )
}

export default AddUser