import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import InputText from "../elements/InputText";
import InputRadio from "../elements/InputRadio";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemaNormalisaiGaji } from "../../constants/schemaInput";
import { useDispatch } from "react-redux";
import { AppThunkDispatch, useAppSelector } from "../../store";
import { getListGaji, postNormalisasi } from "../../store/actions/actionGaji";
import Message from "../Message";
import { useParams } from "react-router-dom";
type FormNormalizeGajiProps = {
  show: boolean;
  onClose: () => void;
};

function FormNormalizeGaji({
  show,

  onClose,
}: FormNormalizeGajiProps) {
  const { uuid } = useParams<{ uuid: string }>();

  const [isMessage, setIsMessage] = useState(false);

  const stateGaji = useAppSelector((state) => state.gaji);
  const dispatch = useDispatch<AppThunkDispatch>();
  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaNormalisaiGaji),
  });

  function submitData(data: any) {
    dispatch(postNormalisasi(data));
    setIsMessage(true);
    onClose();
    setTimeout(() => {
      setIsMessage(false);
    }, 2000);
  }

  useEffect(() => {
    reset({
      uuid: uuid,
    });
  }, [show]);

  useEffect(() => {
    // dispatch(getListGaji());
  }, [isMessage]);

  return (
    <div>
      <Message
        show={isMessage}
        status={stateGaji.status}
        message={stateGaji.message}
      />
      <Modal modalName="Normalisai gaji" show={show} onClose={onClose}>
        <form
          onSubmit={handleSubmit(submitData)}
          className="flex flex-col gap-2"
        >
          <InputText
            placeholder="Nominal normalisasi"
            Name="nominal"
            type="number"
            labelName="Nominal:"
            {...register("nominal")}
            error={errors.nominal?.message}
          />
          <div className="">
            <div className="flex flex-col">
              <label className="label">Tipe Normasilsai:</label>
              <div className="flex flex-row">
                <InputRadio
                  Name="increment"
                  value="true"
                  label="Tambah"
                  control={control}
                />
                <InputRadio
                  Name="increment"
                  value="false"
                  label="Kurangi"
                  control={control}
                />
              </div>
            </div>
          </div>
          <div className="pt-4 flex justify-end">
            <button type="submit" className="btn px-8">
              Simpan
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default FormNormalizeGaji;
