import React from "react";

function Loading(props: any) {
  return (
    <div
      className={`bg-sky-100 h-screen flex justify-center items-center bg-opacity-80 fixed top-0 left-0 w-screen z-50 ${
        props.show ? "visible" : "invisible"
      }`}
    >
      <div className="w-24 h-24 flex justify-center items-center gap-2">
        <div
          className={`w-5 h-5 rounded-full bg-sky-400 opacity-0 animate-[fading_1s_ease-in-out_infinite]`}
        ></div>
        <div
          className={`w-5 h-5 rounded-full bg-sky-400 opacity-0 animate-[fading_1s_ease-in-out_0.30s_infinite]`}
        ></div>
        <div
          className={`w-5 h-5 rounded-full bg-sky-400 opacity-0 animate-[fading_1s_ease-in-out_0.60s_infinite]`}
        ></div>
      </div>
    </div>
  );
}

export default Loading;
