import React from 'react'
import HeaderUI from '../components/HeaderUI'
import TableApprovalGaji from '../components/tables/TableApprovalGaji'

function ApprovalGaji() {
  return (
    <div>
        <HeaderUI title='Approval Gaji'/>
        <TableApprovalGaji/>
    </div>
  )
}

export default ApprovalGaji