import { createSlice } from "@reduxjs/toolkit"
import { ApiStatus } from "../../constants/apiStatus"
import { getAllTitipan, getTitipanByFilter, postTitipan, postTitipanFromExcel, updateTitipan } from "../actions/actionTitipan"

type titipanState = {
    isLoading:boolean
    status: ApiStatus
    message:any
    pegawai:TTitipanPinjaman[]
}

const initialState:titipanState = {
    isLoading:false,
    status:"IDLE",
    message:"",
    pegawai:[]
}

export const titipanSlice = createSlice({
    name:"Titipan",
    initialState,
    reducers:{},
    extraReducers: builder=>{
        builder
        .addCase(getAllTitipan.pending,(state)=>{
            state.isLoading = true
            state.status = "PENDING"
            
        })
        .addCase(getAllTitipan.fulfilled,(state, action)=>{
            state.isLoading=false
            state.status = "SUCCESS"
            state.pegawai = action.payload
        })
        .addCase(getAllTitipan.rejected,(state, action)=>{
            state.isLoading=false
            state.status = "ERROR" 
            state.message = action.error.message 
        })
        .addCase(getTitipanByFilter.pending,(state)=>{
            state.isLoading = true
            state.status = "PENDING"
            
        })
        .addCase(getTitipanByFilter.fulfilled,(state, action)=>{
            state.isLoading=false
            state.status = "SUCCESS"
            state.pegawai = action.payload
        })
        .addCase(getTitipanByFilter.rejected,(state, action)=>{
            state.isLoading=false
            state.status = "ERROR" 
            state.message = action.error.message 
        })
        .addCase(postTitipan.pending,(state)=>{
            state.isLoading = true
            state.status = "PENDING"
            
        })
        .addCase(postTitipan.fulfilled,(state, action)=>{
            state.isLoading=false
            state.status = "SUCCESS"
            state.message = action.payload.msg
        })
        .addCase(postTitipan.rejected,(state, action)=>{
            state.isLoading=false
            state.status = "ERROR"
            state.message = action.error.message 
        })
        .addCase(postTitipanFromExcel.pending,(state)=>{
            state.isLoading = true
            state.status = "PENDING"
            
        })
        .addCase(postTitipanFromExcel.fulfilled,(state, action)=>{
            state.isLoading=false
            state.status = "SUCCESS"
            state.message = action.payload.msg
        })
        .addCase(postTitipanFromExcel.rejected,(state, action)=>{
            state.isLoading=false
            state.status = "ERROR"
            state.message = action.error.message 
        })
        .addCase(updateTitipan.pending,(state)=>{
            state.isLoading = true
            state.status = "PENDING"
            
        })
        .addCase(updateTitipan.fulfilled,(state, action)=>{
            state.isLoading=false
            state.status = "SUCCESS"
            state.message = action.payload.msg
        })
        .addCase(updateTitipan.rejected,(state, action)=>{
            state.isLoading=false
            state.status = "ERROR"
            state.message = action.error.message 
        })

    }
})

export default titipanSlice.reducer