import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { AppThunkDispatch, useAppSelector } from "../../store";
import { allSakter } from "../../store/actions/actionSatker";
import {
  allListPenggajianApprove,
  getListPenggajian,
} from "../../store/actions/actionGaji";
import { optionsGenderApproval } from "../../constants/options";
import TableContainer from "../TableContainer";
import { formatCurrency } from "../../helpers/formatCurrency";
import { addShowMobile, allApprovalByFilter } from "../../store/actions/actionsApprovalGaji";
import Loading from "../Loading";
import Button from "../Button";
import { parafExcel, thpExcel } from "../../helpers/exportToExcel";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import Message from "../Message";

function TableApprovalGaji() {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { allApprovalGaji, loadingApprovalGaji,messageApprovalGaji,status } = useAppSelector(
    (state) => state.approvalGaji
  );
  const { satkerAll } = useAppSelector((state) => state.satker);
  const { listPenggajian } = useAppSelector((state) => state.gaji);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const [bulan, setBulan] = useState<any>({});
  const [satker, setSatker] = useState<any>({});
  const [gender, setGender] = useState<any>({});
  const [showMsgMobile,setShowMsgMobile] = useState(false)
  const optionsBulan = listPenggajian.map((i) => {
    return { value: i.bulan, label: i.bulan };
  });
  const optionSatker = satkerAll.map((i) => {
    return { value: i.kode_satker, label: i.nama_satker };
  });
  optionSatker.splice(0, 0, { value: 0, label: "SEMUA" });
  useEffect(() => {
    dispatch(allSakter());
    dispatch(allListPenggajianApprove());
  }, []);
  const downloadParaf = async () => {
    if (Object.keys(allApprovalGaji).length !== 0) {
      const excelThp: any = await thpExcel(allApprovalGaji);
      const excelParaf: any = await parafExcel(allApprovalGaji);
      const ws1 = XLSX.utils.json_to_sheet(excelThp);
      const ws2 = XLSX.utils.json_to_sheet(excelParaf);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws1, "Thp");
      XLSX.utils.book_append_sheet(wb, ws2, "Paraf");
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(
        data,
        `THP ${satker.label}_${gender.label}_${bulan.value}` + fileExtension
      );
    }
  };
  const downloadFile = (e: any) => {
    window.open(e);
  };
  const showMobile = () => {
    dispatch(addShowMobile(bulan.label))
    setShowMsgMobile(true)
  }
  useEffect(()=>{
    if(status !== "IDLE" && status !== "PENDING"){
      setTimeout(()=>{
        setShowMsgMobile(false)
      },2000)
    }
  },[status])
  useEffect(() => {
    if (
      Object.keys(bulan).length !== 0 &&
      Object.keys(satker).length !== 0 &&
      Object.keys(gender).length !== 0
    ) {
      dispatch(
        allApprovalByFilter({
          bulan: bulan.value,
          satker: satker.value,
          gender: gender.value,
        })
      );
    }
  }, [bulan, satker, gender]);
  return (
    <div className="container-table">
      <Loading show={loadingApprovalGaji} />
      <Message show={showMsgMobile} message={messageApprovalGaji} status={status} />
      <div className="shadow-[0_3px_10px_rgb(0,0,0,0.2)] mx-2 mb-5 rounded-md py-2">
        <div className="flex flex-col md:flex-row justify-between gap-4 p-2 w-full">
          <div className=" mb-2 w-full flex- flex flex-col md:flex-row gap-2 md:gap-3 md:w-[80%]">
            <div className="w-full z-10">
              <label htmlFor="bulan">Pilih bulan:</label>
              <Select
                id="bulan"
                placeholder="Pilih bulan"
                options={optionsBulan}
                value={bulan}
                onChange={(i) => setBulan(i)}
              />
            </div>
            <div className="w-full z-10">
              <label htmlFor="satker">Pilih satker:</label>
              <Select
                id="satker"
                placeholder="Pilih satker"
                options={optionSatker}
                value={satker}
                onChange={(i) => setSatker(i)}
              />
            </div>
            <div className="w-full z-10">
              <label htmlFor="gender">Pilih Gender:</label>
              <Select
                id="gender"
                placeholder="Pilih gender"
                options={optionsGenderApproval}
                value={gender}
                onChange={(i) => setGender(i)}
              />
            </div>
          </div>
          <div className={`w-full flex-1 flex items-end justify-end md:pb-2 gap-4 ${Object.keys(bulan).length === 0 ? "hidden" : "block"}`}>
            <Button
              title="Tampilkan Mobile"
              onClick={showMobile}
              type="button"
            />
          </div>
        </div>
        <div
          className={`flex gap-4 ml-2 ${Object.keys(bulan).length !== 0 ? "block" : "hidden"
            }`}
        >
          <Button
            title="download Amplop"
            onClick={() =>
              downloadFile(
                `https://upload-ppdb.ponpesabuhurairah.id/redagsi/${bulan.label}.pdf`
              )
            }
            type="button"
          />
          <Button
            title="download Receh"
            onClick={() =>
              downloadFile(
                `https://upload-ppdb.ponpesabuhurairah.id/redagsi/receh-${bulan.label}.xlsx`
              )
            }
            type="button"
          />
          <Button
            title="download Report"
            onClick={() =>
              downloadFile(
                `https://upload-ppdb.ponpesabuhurairah.id/redagsi/report_${bulan.label}.pdf`
              )
            }
            type="button"
          />
          <Button
            title="Download Paraf"
            onClick={downloadParaf}
            type="button"
          />
        </div>
      </div>
      <TableContainer>
        <table className="table">
          <thead className="thead ">
            <tr>
              <th className="th">No</th>
              <th className="th">nupy</th>
              <th className="th">Nama</th>
              <th className="th">Tugas pokok</th>
              <th className="th">Gol</th>
              <th className="th">MK</th>
              <th className="th">nominal tugas pokok</th>
              <th className="th">Tugas Tambahan</th>
              <th className="th">Total Nominal tugas tambahan</th>
              <th className="th">gapok</th>
              <th className="th">Kehadiran</th>
              <th className="th">Pinjaman</th>
              <th className="th">Titipan</th>
              <th className="th">Total Potongan</th>
              <th className="th">total gaji</th>
              <th className="th">thp</th>
            </tr>
          </thead>
          <tbody className="tbody">
            {allApprovalGaji?.map((d: any, i: number) => (
              <tr className="tr" key={i}>
                <td className="td">{i + 1}</td>
                <td className="td">{d.ptk?.nupy}</td>
                <td className="td">{d.ptk?.nama}</td>
                <td className="td">
                  {d.ptk?.statusPtk?.listTugasPokok?.nama_tugasPokok}
                </td>
                <td className="td">{d.ptk?.statusPtk?.golongan}</td>
                <td className="td">{d.ptk?.statusPtk?.masa_kerja}</td>
                <td className="td">{formatCurrency(d.tugas_pokok)}</td>
                <td className="td">
                  {d?.ptk?.tugasTambahans?.length > 0
                    ? d?.ptk?.tugasTambahans?.map((t: any, index: number) => (
                      <div className="flex gap-3" key={index}>
                        <ul>
                          <li>
                            {" "}
                            {t?.listTugasTambahan?.nama_tugasTambahan} -{" "}
                            <b>
                              {formatCurrency(t?.listTugasTambahan?.nominal)}
                            </b>
                          </li>
                        </ul>
                      </div>
                    ))
                    : "-"}
                </td>
                <td className="td">{formatCurrency(d.total_tugas_tambahan)}</td>
                <td className="td">{formatCurrency(d.gapok)}</td>
                <td className="td">{formatCurrency(d.absensi)}</td>
                <td className="td">
                  {d?.ptk?.pinjaman_ptks?.length > 0
                    ? d?.ptk?.pinjaman_ptks?.map((t: any, index: number) => (
                      <div className="flex gap-3" key={index}>
                        <ul>
                          <li>
                            {t.ket} -{" "}
                            <b>
                              {t.pembayaran_ptks?.length > 0
                                ? formatCurrency(
                                  t.pembayaran_ptks[0]?.nominal
                                )
                                : formatCurrency(0)}
                            </b>
                          </li>
                        </ul>
                      </div>
                    ))
                    : "-"}
                </td>
                <td className="td">
                  {d?.ptk?.titipan_potongans?.length > 0
                    ? d?.ptk?.titipan_potongans?.map(
                      (t: any, index: number) => (
                        <div className="flex gap-3" key={index}>
                          <ul>
                            <li>
                              {t.ket} - <b>{formatCurrency(t.nominal)}</b>
                            </li>
                          </ul>
                        </div>
                      )
                    )
                    : "-"}
                </td>
                <td className="td">{formatCurrency(d.total_potongan)}</td>
                <td className="td">{formatCurrency(d.thp)}</td>
                <td className="td">{formatCurrency(d.finalTHP)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>
    </div>
  );
}

export default TableApprovalGaji;
