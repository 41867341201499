type TableContainerProps = {
  tableTitle?: string;
  btnText?: string;
  btnText2?: string;
  btnText3?: string;
  handleAdd?: () => void;
  button2?: () => void;
  button3?: () => void;
  handleSearch?: (query: string) => void;
  searchQuery?: string;
  children: React.ReactNode;
};

function TableContainer({
  tableTitle,
  handleAdd,
  button2,
  button3,
  handleSearch,
  btnText,
  btnText2,
  btnText3,
  searchQuery,
  children,
}: TableContainerProps) {
  return (
    <div className="z-10 m-2">
      <div className="overflow-x-auto shadow-[0_3px_10px_rgb(0,0,0,0.2)]  rounded-md ">
        <div className="overflow-hidden">
          <div className="px-4 py-5  flex justify-between items-center">
            <div className="max-w-full">
              <p
                className={`font-semibold text-lg ${
                  tableTitle ? "min-w-[200px]" : ""
                } `}
              >
                {tableTitle}
              </p>
            </div>
            <div className="flex  md:flex-row w-full justify-between">
              {handleSearch && (
                <div className=" w-full md:w-[300px] gap-2 flex flex-col">
                  <label
                    className="text-sm text-slate-800 font-light"
                    htmlFor=""
                  >
                    Masukkan nama/nupy:
                  </label>
                  <input
                    type="text"
                    placeholder="Search...."
                    value={searchQuery}
                    onChange={(e) => handleSearch(e.target.value)}
                    className="input-text"
                  />
                </div>
              )}
              <div className="flex gap-2 w-full items-end justify-end">
                {btnText && (
                  <div className="flex  ">
                    <button
                      className="border px-8 py-1 border-[#1e81b0] rounded-lg  text-slate-800 hover:text-white hover:bg-[#1e81b0]"
                      onClick={handleAdd}
                    >
                      {btnText}
                    </button>
                  </div>
                )}
                {btnText2 && (
                  <div className="flex">
                    <button
                      className="border px-8 py-1 border-[#1e81b0] rounded-lg  text-slate-800 hover:text-white hover:bg-[#1e81b0]"
                      onClick={button2}
                    >
                      {btnText2}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className=" overflow-auto scrollbar-hide">
            <div className="max-w-[60vh] min-w-full max-h-[60vh] px-2">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TableContainer;
