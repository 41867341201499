import { createSlice } from "@reduxjs/toolkit";
import { getAllLocation } from "../actions/actionLocation";
import { ApiStatus } from "../../constants/apiStatus";


type Location = {
    allLocation : any;
    msgLocation : any;
    loadingLocation : boolean;
    statusLocation : ApiStatus
}

const initialState : Location = {
    allLocation : [],
    loadingLocation : false,
    msgLocation : "",
    statusLocation : "IDLE"
}

export const LocationStore = createSlice({
    name:"location",
    initialState,
    reducers:{
        clearLocation : (state)=>{
            state.allLocation = []
            state.msgLocation = ""
        }
    },
    extraReducers: builder =>{
        builder
        .addCase(getAllLocation.pending,(state)=>{
            state.loadingLocation = true
            state.statusLocation = "PENDING"
        })
        .addCase(getAllLocation.fulfilled,(state,action)=>{
            state.loadingLocation = false
            state.allLocation = action.payload
            state.statusLocation = "SUCCESS"
        })
        .addCase(getAllLocation.rejected,(state,action)=>{
            state.loadingLocation =false
            state.allLocation = []
            state.msgLocation = action.payload
            state.statusLocation = "ERROR"
        })
    }
})

export const locationActions = LocationStore.actions
export default LocationStore.reducer