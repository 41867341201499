import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Modal from "../Modal";
import { schemaTugasPokok } from "../../constants/schemaInput";
import InputText from "../elements/InputText";
import { useDispatch } from "react-redux";
import { AppThunkDispatch, useAppSelector } from "../../store";
import {
  addTugasPokok,
  getTugasPokok,
  updateTugasPokok,
} from "../../store/actions/actionTugasPokok";
import { useEffect, useState } from "react";
import Message from "../Message";

type FormListTugasPokokProps = {
  show: boolean;
  onClose: () => void;
  dataToUpdate: any;
};

function FormListTugasPokok({
  show,
  onClose,
  dataToUpdate,
}: FormListTugasPokokProps) {
  const [isMessage, setIsMessage] = useState(false);

  const dispatch = useDispatch<AppThunkDispatch>();
  const data = useAppSelector((state) => state.tugasPokok);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaTugasPokok),
  });
  const onSubmitData = (data: any) => {
    if (dataToUpdate) {
      dispatch(updateTugasPokok({ id: dataToUpdate.kode_tugasPokok, data }));
    } else {
      dispatch(addTugasPokok(data));
    }
    setIsMessage(true);
    onClose();
    setTimeout(() => {
      setIsMessage(false);
    }, 2000);
  };
  useEffect(() => {
    dispatch(getTugasPokok());
    if (show) {
      if (dataToUpdate) {
        reset(dataToUpdate);
      } else {
        setValue("nama_tugasPokok", "");
        setValue("nominal", 0);
      }
    }
  }, [isMessage, show]);

  return (
    <div>
      <Modal onClose={onClose} show={show} modalName="Input Tugas Pokok">
        <form action="" onSubmit={handleSubmit(onSubmitData)}>
          <InputText
            placeholder="Masukkan nama tugas pokok"
            {...register("nama_tugasPokok")}
            Name="nama_tugasPokok"
            labelName="Nama tugas pokok:"
            error={errors.nama_tugasPokok?.message}
          />
          <InputText
            placeholder="Nominal"
            {...register("nominal")}
            Name="nominal"
            type="number"
            labelName="Nominal:"
            error={errors.nominal?.message}
          />
          <hr />
          <div className="pt-4">
            <button className="btn" type="submit">
              {dataToUpdate ? "Update" : "Submit"}
            </button>
          </div>
        </form>
      </Modal>
      <Message message={data.message} show={isMessage} status={data.status} />
    </div>
  );
}

export default FormListTugasPokok;
