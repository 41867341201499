import React from "react";
import { HiOutlineArrowRightOnRectangle, HiOutlineBars2, HiOutlineListBullet, HiOutlinePencilSquare } from "react-icons/hi2";

function Header(props:any) {
  return (
    <header className='bg-[#1e81b0] py-4 md:px-5 px-4 flex justify-between  w-full'>
      <div className='flex md:space-x-2 space-x-4'>
        <HiOutlineListBullet onClick={props.sideMenu} className='md:w-9 md:h-9 w-8 h-8 my-auto block bg-slate-200 md:mt-1 rounded-full p-1 cursor-pointer' />
        <div className='flex items-center md:mt-1'>
          <h1 className='text-3xl text-white my-auto'>{props.title}</h1>
        </div>
      </div>
      <div>
        <div className=' my-auto mx-auto relative'>
          <HiOutlineBars2 className='w-10 h-10 bg-slate-200 rounded-full p-1 cursor-pointer ' onClick={props.userDrop} />
          <div className={`absolute -left-[5rem] transition-all ease-in-out duration-300 z-50 ${props.dropUser ? "-bottom-24 visible text-opacity-100 bg-opacity-100" : "-bottom-20 invisible text-transparent bg-opacity-0"}  bg-slate-200 rounded-md`}>
            <div className='flex px-4 py-2 md:space-x-3 hover:bg-sky-300 cursor-pointer w-32' onClick={props.userMenu}>
              <HiOutlinePencilSquare className='my-auto w-5 h-5' />
              <h1 className='text-xl my-auto'>Edit</h1>
            </div>
            <div className='flex px-4 py-2 md:space-x-3 hover:bg-sky-300 cursor-pointer w-32' onClick={props.logout}>
              <HiOutlineArrowRightOnRectangle className='my-auto w-5 h-5' />
              <h1 className='text-xl my-auto'>Logout</h1>
            </div>
          </div>
        </div>
      </div>
    </header>

  );
}

export default Header;
