import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppThunkDispatch, useAppSelector } from "../../store";
import {
  allSakter,
  deleteSatker,
  postSatker,
  updateSatker,
} from "../../store/actions/actionSatker";
import { DocumentTextIcon, TrashIcon } from "@heroicons/react/24/solid";
import TableContainer from "../TableContainer";
import FormListSatker from "../forms/FormListSatker";
import Confirm from "../Confirm";
import Message from "../Message";

function TableSatker() {
  const [selectedData, setSelectedData] = useState<any>(null);
  const [isMessage, setIsMessage] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [idToDelete, setIdToDelete] = useState<string>("");
  const dispatch = useDispatch<AppThunkDispatch>();
  const satkerState = useAppSelector((state) => state.satker);

  const handleAddSatker = () => {
    setModalShow(true);
  };
  const handleDelete = (id: string) => {
    setIsConfirm(true);
    setIdToDelete(id);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteSatker(idToDelete));
    setIsMessage(true);
    setIsConfirm(false);
    setTimeout(() => {
      setIsMessage(false);
    }, 2000);
  };
  useEffect(() => {
    dispatch(allSakter());
  }, [isMessage]);

  return (
    <div>
      <div className="md:px-3 py-3">
        <TableContainer btnText="Tambah" handleAdd={handleAddSatker}>
          <table className="table">
            <thead className="thead">
              <tr className="tr">
                <th className="th">No</th>
                <th className="th">Kode satker</th>
                <th className="th">Nama satker</th>
                <th className="th">Edit</th>
                <th className="th">Hapus</th>
              </tr>
            </thead>
            <tbody className="tbody">
              {satkerState.satkerAll.map((d, index) => (
                <tr className="tr" key={index}>
                  <td className="td">{index + 1}</td>
                  <td className="td">{d.kode_satker}</td>
                  <td className="td">{d.nama_satker}</td>
                  <td className="td">
                    <button
                      onClick={() => {
                        setModalShow(true);
                        setSelectedData(d);
                      }}
                    >
                      <DocumentTextIcon className="icon" />
                    </button>
                  </td>
                  <td className="td">
                    <button onClick={() => handleDelete(d.kode_satker)}>
                      <TrashIcon className="icon" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableContainer>
      </div>
      <Confirm
        onClose={() => setIsConfirm(false)}
        show={isConfirm}
        text="Yakin satker ini dihapus?"
      >
        <div className="flex gap-1 items-center justify-center">
          <button className="btn w-full" onClick={handleConfirmDelete}>
            Ya
          </button>
        </div>
      </Confirm>
      <FormListSatker
        show={modalShow}
        onClose={() => {
          setModalShow(false);
          setSelectedData(null);
        }}
        dataToUpdate={selectedData}
      />
      <Message message={satkerState.msgSatker} show={isMessage} status="" />
    </div>
  );
}

export default TableSatker;
