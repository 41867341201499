import moment from "moment";
import "moment/locale/id"

export function formatNormalDate(date:Date):string{
    return moment(date).format("YYYY-MM-DD HH:mm:ss")
}

export function formatWithDay(date:Date):string{
    return moment(date).format("dddd, DD MMMM YYYY HH:mm:ss")
}

export function formatWithoutDay(date:Date):string{
    return moment(date).format("DD MMMM YYYY")
}

export function formatTimeOnly(date:Date|string):string{
    return moment(date).format("HH:mm:ss")
}