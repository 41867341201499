import React from 'react'

function InputForm(props:any) {
    const { error } = props.method.getFieldState(props.methodName)
    return (
        <div className={` ${props.className} flex flex-col w-full relative`}>
            <label className='font-sans text-gray-700'>{props.label}</label>
            <div className='flex'>
                <input {...props.method.register(props.methodName)} max={props.max} step={props.step} type={props.type} className={`py-[7px] px-2 outline-none border border-gray-400 rounded-md mt-1 w-full text-gray-800 ${props.method.formState.errors[props.methodName] ? "border-red-600" : "border-[#624d4d] hover:border-gray-400"}`} readOnly={props.read}/>
                {props.icon}
            </div>
            {error && <p className='text-red-600 mt-1 mx-[2px] text-sm font-light'>{error.message}</p>}
        </div>
    )
}

export default InputForm