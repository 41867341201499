import React, { useEffect, useState } from "react";
import HeaderUI from "../components/HeaderUI";
import TableContainer from "../components/TableContainer";
import FormAddKegiatan from "../components/forms/FormAddKegiatan";
import FormAddSesiKegiatan from "../components/forms/FormAddSesiKegiatan";
import { useDispatch } from "react-redux";
import { AppThunkDispatch, useAppSelector } from "../store";
import {
  eventDelete,
  eventGetAll,
  eventSesiAll,
} from "../store/actions/actionEvent";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  DocumentTextIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import Message from "../components/Message";
import Loading from "../components/Loading";

function KegiatanPage() {
  const [isMessage, setIsMessage] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalAddSesi, setIsModalAddSesi] = useState(false);
  const [showSesi, setShowSesi] = useState(false)
  const [idToAddSesi, setIdToAddSesi] = useState<string>("");
  const [dataToUpdate, setDataToUpdate] = useState<any>(null);
  const [sesiToUpdate, setSesiToUpdate] = useState<any>(null);
  const [eventId, setEventId] = useState("")

  const {
    kegiatan,
    sesiKegiatan: absensi,
    status,
    message,
    isLoading
  } = useAppSelector((state) => state.event);

  const dispatch = useDispatch<AppThunkDispatch>();

  function getSesiByid(uuid: string) {
    dispatch(eventSesiAll(uuid));
  }

  useEffect(() => {
    dispatch(eventGetAll());
  }, [isMessage]);
  return (
    <div>
      <Loading show={isLoading} />
      <HeaderUI title="Kegiatan" />
      <Message show={isMessage} message={message} status={status} />
      <FormAddKegiatan
        onClose={() => setIsModalOpen(false)}
        show={isModalOpen}
        oneData={dataToUpdate}
      />
      <FormAddSesiKegiatan
        idToEdit={idToAddSesi}
        onClose={() => setIsModalAddSesi(false)}
        show={isModalAddSesi}
        oneData={sesiToUpdate}
      />
      <TableContainer btnText="Tambah" handleAdd={() => setIsModalOpen(true)}>
        <div className="">
          <div className="w-full">
            <ul className="grid grid-cols-7 thead">
              <li className="th">No</li>
              <li className="th">Rincian</li>
              <li className="th">Judul</li>
              <li className="th">mulai</li>
              <li className="th">selesai</li>
              <li className="th">edit</li>
              <li className="th">hapus</li>
            </ul>
          </div>
          {kegiatan?.map((d, i) => (
            <div className="w-full">
              <ul className="tr grid grid-cols-7" key={i}>
                <li className="td">{i + 1}</li>
                <li className="td cursor-pointer">
                  {eventId === d.uuid ?
                    <ArrowUpIcon
                      className="icon"
                      onClick={() => {
                        setShowSesi(false)
                        setEventId('')
                      }}
                    />
                    :
                    <ArrowDownIcon
                      className="icon"
                      onClick={() => {
                        getSesiByid(d.uuid);
                        setShowSesi(true)
                        setEventId(d.uuid)
                      }}
                    />

                  }
                </li>
                <li className="td">{d.kegiatan}</li>
                <li className="td">{d.start_date}</li>
                <li className="td">{d.end_date}</li>
                <li
                  className="td cursor-pointer"
                  onClick={() => {
                    setIsModalOpen(true);
                    setDataToUpdate(d);
                  }}
                >
                  <DocumentTextIcon className="icon" />
                </li>
                <li
                  className="td cursor-pointer"
                  onClick={() => {
                    dispatch(eventDelete(d.uuid));
                    setIsMessage(true);
                    setTimeout(() => {
                      setIsMessage(false);
                    }, 2000);
                  }}
                >
                  <TrashIcon className="icon" />
                </li>
              </ul>
              {showSesi && eventId === d.uuid ?
                <table className=" table">
                  <thead className="thead">
                    <tr className="tr">
                      <th
                        className="th cursor-pointer hover:text-blue-400"
                        onClick={() => {
                          setIsModalAddSesi(true);
                          setIdToAddSesi(d.uuid);
                          setSesiToUpdate(null)
                        }}
                      >
                        Tambah
                      </th>
                      <th className="th">No</th>
                      <th className="th">Keterangan</th>
                      <th className="th">Mulai</th>
                      <th className="th">selesai</th>
                      <th className="th">edit</th>
                    </tr>
                  </thead>
                  <tbody className="tbody">
                    {absensi?.map((d, i) => (
                      <tr className="tr" key={i}>
                        <td className="td">{}</td>
                        <td className="td">{i + 1}</td>
                        <td className="td">{d.ket}</td>
                        <td className="td">{d.start_time}</td>
                        <td className="td">{d.end_time}</td>
                        <td
                          className="td cursor-pointer"
                          onClick={() => {
                            setIsModalAddSesi(true);
                            setSesiToUpdate(d);
                          }}
                        >
                          <DocumentTextIcon className="icon" />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                :
                <></>
              }
            </div>
          ))}
        </div>
      </TableContainer>
    </div>
  );
}

export default KegiatanPage;
