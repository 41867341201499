import React from "react";
import TableTugasPokok from "../components/tables/TableTugasPokok";
import { useAppSelector } from "../store";
import Loading from "../components/Loading";
import HeaderUI from "../components/HeaderUI";

function ListTugasPokok() {
  const loading = useAppSelector((state) => state.tugasPokok.isLoading);

  return (
    <div className="">
      <Loading show={loading} />
      <HeaderUI title="Tugas Pokok" />

      <TableTugasPokok />
    </div>
  );
}

export default ListTugasPokok;
