import api from "./api"


const apiTugasPokok = {
    get:()=>{
        return api.get("/ptk/list-tugas-pokok")
    },
    post:(data:TListTugasPokok)=>{
        return api.post("/ptk/list-tugas-pokok", data)
    },
    delete:(uuid:string)=>{
        return api.delete(`/ptk/list-tugas-pokok/${uuid}`)
    },
    put:(uuid:string, data:TListTugasPokok)=>{
        return api.update(`/ptk/list-tugas-pokok/${uuid}`, data)
    },

}
export default apiTugasPokok