import api from "./api"

const apiApproval = {
    pokokGet: ()=>{
        return api.get("ptk/approve-tugas-pokok")
    },
    pokokPost: (data:any)=>{
        return api.post("ptk/approve-tugas-pokok", data)
    },
    tambahanGet: ()=>{
        return api.get("ptk/approve-tugas-tambahan")
    },
    tambahanPost: (data:any)=>{
        return api.post("ptk/approve-tugas-tambahan", data)
    }
}

export default apiApproval