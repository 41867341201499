import React from "react";
import { useLocation } from "react-router-dom";

type HeaderUIProps = {
  title: string;
  rightTitle?: any | null;
};

function HeaderUI({ title, rightTitle }: HeaderUIProps) {
  const getLocation = useLocation();
  const location = getLocation.pathname.replace("/", "");

  return (
    <div className="">
      <header className="shadow-md bg-white w-full px-6 py-4 flex flex-col justify-between h-32">
        <h1 className="font-light text-lg tracking-widest">{location}</h1>
        <div className="flex flex-col md:flex-row justify-between">
          <h1 className="font-normal text-3xl mb-1">{title}</h1>
          <h1 className="font-normal text-3xl mb-1">{rightTitle}</h1>
        </div>
      </header>
    </div>
  );
}

export default HeaderUI;
