import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiAbsensi } from "../../api/apiAbsensi";


export const rekapAbsensi = createAsyncThunk(
    'absensi/rekap',
    async(_,{rejectWithValue}:any)=>{
        try{
            const res = await ApiAbsensi.getRekapAbsensi()
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue(err.response.data.msg)
        }
    }
)
export const rekapAbsensiByKey = createAsyncThunk(
    'absensi/rekapByKey',
    async(kode_sateker:any,{rejectWithValue}:any)=>{
        try{
            const res = await ApiAbsensi.getRekapAbsensiByKey(kode_sateker)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue(err.response.data.msg)
        }
    }
)

export const updateAbsensiByExcel = createAsyncThunk(
    'absensi/update/excel',
    async(data:any,{rejectWithValue}:any)=>{
        try{
            const res = await ApiAbsensi.postUpdateAbsensiByExcel(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue(err.response.data.msg)
        }
    }
)

export const getAllAbsensi = createAsyncThunk(
    'ptk/absensi/all',
    async(data:any,{rejectWithValue}:any)=>{
        try{
            const res = await ApiAbsensi.getAbsensi(data)
            if(res.status ===200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue(err.response.data.msg)
        }
    }
)

export const getRekapAbsensi_ = createAsyncThunk(
    'ptk/result/rekap',
    async(data:any,{rejectWithValue})=>{
        try{
            const res = await ApiAbsensi.getRekapAbsensiAll(data)
            if(res.status === 200){
                return res.data
            }
        }catch(err:any){
            return rejectWithValue(err.response.data?.msg)
        }
    }
)