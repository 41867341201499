import Button from "../components/Button";
import HeaderUI from "../components/HeaderUI";
import Loading from "../components/Loading";
import { AppThunkDispatch, useAppSelector } from "../store";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { getListPenggajian } from "../store/actions/actionGaji";
import Select from "react-select";
import {
  rekapAbsensi,
  rekapAbsensiByKey,
  updateAbsensiByExcel,
} from "../store/actions/actionAbsensi";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { allSakter } from "../store/actions/actionSatker";
import TableContainer from "../components/TableContainer";
import { formatCurrency } from "../helpers/formatCurrency";
import { CloudArrowUpIcon } from "@heroicons/react/24/solid";
import Message from "../components/Message";
import { absensiEventActions } from "../store/slices/absensiSlice";

function Laporan() {
  const refFileInput = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch<AppThunkDispatch>();
  const { listPenggajian } = useAppSelector((state) => state.gaji);
  const listSatker = useAppSelector((state) => state.satker.satkerAll);
  const { allRekapAbsensi, loadingAbsensi, messageAbsensi, status } =
    useAppSelector((state) => state.absensi);
  const [bulan, setBulan] = useState<any>({});
  const [jsonFromExcel, setJsonFormExcel] = useState<any>([]);
  const [satker, setSatker] = useState<any>({ value: "", label: "SEMUA" });
  const [hasilRekapAbsensi, setHasilRekapAbsensi] = useState([]);
  const [loadingUploadExcel, setLoadingUploadExcel] = useState(false);
  const [alertUpdateAbsensi, setAlertUpdateAbsensi] = useState(false);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const bulanOpt = listPenggajian.map((d: any) => {
    return { value: d.bulan, label: d.bulan };
  });
  const satkerOpt = listSatker.map((i) => {
    return { value: i.kode_satker, label: i.nama_satker };
  });
  satkerOpt.splice(0, 0, { value: "", label: "SEMUA" });

  const downloadExcel = async () => {
    if (hasilRekapAbsensi.length > 0) {
      const ws = XLSX.utils.json_to_sheet(hasilRekapAbsensi);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(
        data,
        `Rekap Absensi ${satker.label}_${bulan.value}` + fileExtension
      );
    }
  };
  const readExcel = (e: any) => {
    if (e.target.files) {
      setLoadingUploadExcel(true);
      const reader = new FileReader();
      reader.onload = (i) => {
        const data = i.target?.result;
        const wb = XLSX.read(data, { type: "array" });
        const sheetName = wb.SheetNames[0];
        const ws = wb.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(ws);
        setJsonFormExcel(json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
      setLoadingUploadExcel(false);
    }
  };
  const uploadAbsensi = () => {
    dispatch(updateAbsensiByExcel({ file: jsonFromExcel }));
  };
  useEffect(() => {
    if (status !== "IDLE") {
      setAlertUpdateAbsensi(true);
      setTimeout(() => {
        setAlertUpdateAbsensi(false);
        dispatch(absensiEventActions.clearRekapAbsensi());
      }, 3000);
    }
  }, [status]);
  useEffect(() => {
    dispatch(getListPenggajian());
    dispatch(allSakter());
  }, []);
  useEffect(() => {
    if (Object.keys(satker).length !== 0) {
      if (satker.value === "") {
        dispatch(rekapAbsensi());
      } else {
        dispatch(rekapAbsensiByKey(satker.value));
      }
    }
  }, [satker]);
  useEffect(() => {
    if (Object.keys(bulan).length !== 0) {
      let rekapSementara: any = [];
      let objectRekapSementara: any = {
        no: 0,
        nupy: "",
        nama: "",
        satker: "",
        nominal: 0,
        bulan: "",
      };
      let no = 0;
      allRekapAbsensi.map((d: any) => {
        no += 1;
        objectRekapSementara.no = no;
        objectRekapSementara.nupy = d.nupy;
        objectRekapSementara.nama = d.nama;
        objectRekapSementara.satker = d.satker;
        objectRekapSementara.bulan = bulan.value;
        rekapSementara.push(objectRekapSementara);
        objectRekapSementara = {
          no: 0,
          nupy: "",
          nama: "",
          satker: "",
          nominal: 0,
          bulan: "",
        };
      });
      setHasilRekapAbsensi(rekapSementara);
      rekapSementara = [];
    }
  }, [bulan, satker, allRekapAbsensi]);
  return (
    <div className="relative w-full">
      <Message
        status={status}
        show={alertUpdateAbsensi}
        message={messageAbsensi}
      />
      <Loading show={loadingUploadExcel || loadingAbsensi} />
      <HeaderUI title="Laporan" />
      <div className=" bg-white shadow-[0_3px_10px_rgb(0,0,0,0.2)] p-4 mx-7 rounded-md mt-4">
        <div className="flex flex-col md:flex-row w-full gap-3 justify-between">
          <div className="flex flex-col w-full md:flex-row flex-start gap-3">
            <div className="md:w-80  w-full">
              <label htmlFor="bulan">Pilih bulan:</label>
              <Select
                id="bulan"
                placeholder="Pilih bulan"
                options={bulanOpt}
                value={bulan}
                onChange={(i: any) => setBulan(i)}
              />
            </div>
            <div className="md:w-80  w-full">
              <label htmlFor="satker">Pilih Satker:</label>
              <Select
                id="satker"
                placeholder="Pilih satker"
                options={satkerOpt}
                value={satker}
                onChange={(i: any) => setSatker(i)}
              />
            </div>
          </div>
          <div className="flex w-full items-end justify-end">
            <Button
              type="button"
              title="Donwload Excel"
              onClick={downloadExcel}
            />
          </div>
        </div>
      </div>
      <div className="container-table">
        <TableContainer>
          {jsonFromExcel.length !== 0 ? (
            <div className="px-4">
              <div className="flex gap-3">
                <div className="mb-2">
                  <Button
                    type="button"
                    title="Upload"
                    onClick={uploadAbsensi}
                  />
                </div>
                <div className="mb-2">
                  <Button
                    type="button"
                    title="Ganti FIle"
                    onClick={() => setJsonFormExcel([])}
                  />
                </div>
              </div>
              <table className="table">
                <thead className="thead">
                  <tr>
                    <th className="th">No</th>
                    <th className="th">Nupy</th>
                    <th className="th">Nama</th>
                    <th className="th">Satker</th>
                    <th className="th">Nominal</th>
                    <th className="th">Bulan</th>
                  </tr>
                </thead>
                <tbody className="tbody">
                  {jsonFromExcel?.map((d: any) => (
                    <tr className="tr" key={d.no}>
                      <td className="td">{d?.no}</td>
                      <td className="td">{d?.nupy}</td>
                      <td className="td">{d?.nama}</td>
                      <td className="td">{d?.satker}</td>
                      <td className="td">
                        {d?.nominal
                          ? formatCurrency(d.nominal)
                          : formatCurrency(0)}
                      </td>
                      <td className="td">{d?.bulan}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div
              className="p-4 mb-3 flex flex-col items-center gap-2 bg-violet-50 text-slate-800 rounded-lg hover:bg-violet-100 cursor-pointer"
              onClick={() => refFileInput.current?.click()}
            >
              <CloudArrowUpIcon className="w-6 h-6" />
              <span>Upload Excel Absensi</span>
              <input
                type="file"
                ref={refFileInput}
                className="hidden"
                onChange={readExcel}
              />
            </div>
          )}
        </TableContainer>
      </div>
    </div>
  );
}

export default Laporan;
