import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Modal from "../Modal";
import InputText from "../elements/InputText";
import { useDispatch } from "react-redux";
import { AppThunkDispatch, useAppSelector } from "../../store";
import {
  allSakter,
  postSatker,
  updateSatker,
} from "../../store/actions/actionSatker";
import Message from "../Message";

const schemaSatker = yup.object().shape({
  nama_satker: yup.string().required("Nama satuan kerja harus diisi"),
});

type FormListSatkerProps = {
  show: boolean;
  onClose: () => void;
  dataToUpdate: any;
};

function FormListSatker({ show, onClose, dataToUpdate }: FormListSatkerProps) {
  const [isMessage, setIsMessage] = useState(false);
  const satkerState = useAppSelector((state) => state.satker);
  const dispatch = useDispatch<AppThunkDispatch>();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaSatker),
  });

  const submitData = (data: any) => {
    if (dataToUpdate) {
      dispatch(updateSatker({ id: data.kode_satker, data }));
    } else {
      dispatch(postSatker(data));
    }
    setIsMessage(true);
    onClose();
    setTimeout(() => {
      setIsMessage(false);
    }, 2000);
    reset();
  };

  useEffect(() => {
    dispatch(allSakter());
    if (show) {
      reset();
      if (dataToUpdate !== null) {
        reset(dataToUpdate);
      } else {
        setValue("nama_satker", "");
      }
    }
  }, [isMessage, show]);

  return (
    <div>
      <Modal show={show} onClose={onClose} modalName="Input Satker">
        <form action="" onSubmit={handleSubmit(submitData)}>
          <InputText
            {...register("nama_satker")}
            placeholder="Nama satuan kerja"
            Name="nama_satker"
            labelName="Nama Satker:"
            error={errors.nama_satker?.message}
          />
          <div className="pt-4">
            <button className="btn" type="submit">
              {dataToUpdate ? "Update" : "Submit"}
            </button>
          </div>
        </form>
      </Modal>

      <Message message={satkerState.msgSatker} show={isMessage} status="" />
    </div>
  );
}

export default FormListSatker;
