import { createSlice } from "@reduxjs/toolkit"
import { ApiStatus } from "../../constants/apiStatus"
import { getDaftarHadirByFilter } from "../actions/actionDaftarHadir"

type DaftarHadirState = {
    allKehadiran : any,
    isLoading:boolean,
    status: ApiStatus,
    message:any,
}

const initialState:DaftarHadirState = {
    allKehadiran : [],
    isLoading:false,
    status: "IDLE",
    message:"",
}

export const daftarHadirSlice = createSlice({
    name: "daftarHadir",
    initialState,
    reducers:{},
    extraReducers:builder => {
        builder
        .addCase(getDaftarHadirByFilter.pending,(state)=>{
            state.isLoading=true
            state.status="PENDING"
        })
        .addCase(getDaftarHadirByFilter.fulfilled,(state, action)=>{
            state.isLoading=false
            state.status="SUCCESS"
            state.allKehadiran = action.payload
        })
        .addCase(getDaftarHadirByFilter.rejected,(state, action)=>{
            state.isLoading=false
            state.status="ERROR"
            state.allKehadiran = []
            state.message=action.payload
        })
    }
})

export default daftarHadirSlice.reducer