export const optionsGander = [
  { value: "L", label: "Laki-laki" },
  { value: "P", label: "Perempuan" },
];
export const optionsGenderApproval = [
  { value: "-", label: "SEMUA" },
  { value: "L", label: "Laki-laki" },
  { value: "P", label: "Perempuan" },
];
export const optionsStatusPernikahan = [
  { value: "Menikah", label: "Menikah" },
  { value: "Belum menikah", label: "Belum menikah" },
];

export const optionsPendidikan = [
  { value: "S3", label: "S3" },
  { value: "S2", label: "S2" },
  { value: "S1", label: "S1" },
  { value: "SMA/Sederajat", label: "SMA/Sederajat" },
  { value: "SMP/Sederajat", label: "SMP/Sederajat" },
  { value: "SD/Sederajat", label: "SD/Sederajat" },
  { value: "D4", label: "D4" },
  { value: "D3", label: "D3" },
  { value: "D2", label: "D2" },
  { value: "D1", label: "D1" },
  { value: "Informal", label: "Informal" },
  { value: "Non Formal", label: "Non Formal" },
  { value: "Paket A", label: "Paket A" },
  { value: "Paket B", label: "Paket B" },
  { value: "Paket C", label: "paket C" },
  { value: "Paud", label: "Paud" },
  { value: "RK/Sederajat", label: "RK/Sederajat" },
  { value: "Putus SD", label: "Putus SD" },
  { value: "Tidak Sekolah", label: "Tidak Sekolah" },
  { value: "Lainnya", label: "Lainnya" },
];
export const optionsGolDarah = [
  { value: "AB", label: "AB" },
  { value: "A", label: "A" },
  { value: "B", label: "B" },
  { value: "O", label: "O" },
];
export const optionsStatusKepegawian = [
  { value: 1, label: "Tetap" },
  { value: 2, label: "Kontrak" },
  { value: 3, label: "Percobaan" },
];

export const optionsBulan = [
  { value: "jan", label: "jan" },
  { value: "feb", label: "feb" },
  { value: "mar", label: "mar" },
  { value: "apr", label: "apr" },
  { value: "mei", label: "mei" },
  { value: "jun", label: "jun" },
  { value: "jul", label: "jul" },
  { value: "agus", label: "agus" },
  { value: "sept", label: "sept" },
  { value: "okt", label: "okt" },
  { value: "nov", label: "nov" },
  { value: "des", label: "des" },
];

export const optionsBulanExcel = [
  { value: "1", label: "Desember - Januari" },
  { value: "2", label: "Januari - Feburari" },
  { value: "3", label: "Februari - Maret" },
  { value: "4", label: "Maret - April" },
  { value: "5", label: "April - Mei" },
  { value: "6", label: "Mei - Juni" },
  { value: "7", label: "Juni - Juli" },
  { value: "8", label: "Juli - Agustus" },
  { value: "9", label: "Agustus - September" },
  { value: "10", label: "September - Oktober" },
  { value: "11", label: "Oktober - November" },
  { value: "12", label: "November - Desember" },
];

export const dayOptions : any = [
  {value:0,label:"Ahad"},
  {value:1,label:"Senin"},
  {value:2,label:"Selasa"},
  {value:3,label:"Rabu"},
  {value:4,label:"Kamis"},
  {value:5,label:"Jumat"},
  {value:6,label:"Sabtu"},
]