import React, { SetStateAction, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { AppThunkDispatch, useAppSelector } from "../../store";
import {
  getListGaji,
  getListGajiBykey,
  getListPenggajian,
  getTaskStatus,
} from "../../store/actions/actionGaji";
import TableContainer from "../TableContainer";
import { currency } from "../../helpers/currency";
import Dropdown from "../elements/Dropdown";
import { formatCurrency } from "../../helpers/formatCurrency";
import Info from "../Info";
import Select from "react-select";
import { allSakter } from "../../store/actions/actionSatker";
import { getTugasPokok } from "../../store/actions/actionTugasPokok";
import FormApproveGaji from "../forms/FormApproveGaji";
import { toExcel } from "../../helpers/exportToExcel";
import Loading from "../Loading";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

function TableGaji({ setTunjangan }: { setTunjangan: SetStateAction<any> }) {
  const authState = useAppSelector((state) => state.auth);
  const [isApproveGaji, setIsApproveGaji] = useState(false);
  const [bulan, setBulan] = useState<any>({});
  const [satker, setSatker] = useState<any>({});
  const [tugasPokok, setTugasPokok] = useState<any>({});
  const [nama, setNama] = useState<string>("");
  const [valueAnggaran, setvalueAnggaran] = useState();
  const [anggaran, setAnggaran] = useState(0);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const stateKeauangan = useAppSelector((state) => state.gaji);
  const listSatker = useAppSelector((state) => state.satker.satkerAll);
  const { tugasPokokAll } = useAppSelector((state) => state.tugasPokok);
  const gajiPtk: any = stateKeauangan.keuangan?.ptk;
  const listBulan = stateKeauangan.listPenggajian;
  const statusTask = stateKeauangan.bgTaskStatus?.status;
  const dispatch = useDispatch<AppThunkDispatch>();
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const optionsBulan = listBulan.map((i) => {
    return { value: i.bulan, label: i.bulan };
  });
  const optionSatker = listSatker.map((i) => {
    return { value: i.kode_satker, label: i.nama_satker };
  });
  optionSatker.splice(0, 0, { value: 0, label: "SEMUA" });
  const optionTugasPokok = tugasPokokAll.map((d) => {
    return { value: d.kode_tugasPokok, label: d.nama_tugasPokok };
  });
  optionTugasPokok.splice(0, 0, { value: 0, label: "SEMUA" });

  const handleInput = (e: any) => {
    setvalueAnggaran(e.target.value);
    if (stateKeauangan?.keuangan?.total) {
      setAnggaran(parseInt(e?.target?.value) - stateKeauangan?.keuangan?.total);
    }
  };
  const downloadExcel = async () => {
    if (Object.keys(gajiPtk).length !== 0) {
      setLoadingExcel(true);
      const awaitExcel: any = await toExcel(gajiPtk);
      const ws = XLSX.utils.json_to_sheet(awaitExcel);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(
        data,
        `Rekap Simulasi ${satker.label}_${bulan.value}` + fileExtension
      );
      setLoadingExcel(false);
    }
  };
  useEffect(() => {
    if (!statusTask) {
      dispatch(getTugasPokok());
      dispatch(allSakter());
      dispatch(getListPenggajian());
    }
  }, [statusTask, bulan]);
  useEffect(() => {
    dispatch(getTaskStatus());
  }, [isApproveGaji, statusTask]);
  useEffect(() => {
    if (
      Object.keys(bulan).length !== 0 &&
      Object.keys(satker).length !== 0 &&
      Object.keys(tugasPokok).length !== 0
    ) {
      dispatch(
        getListGajiBykey({
          bulan: bulan.value,
          satker: satker.value,
          tugas_pokok: tugasPokok.value,
          nama: nama,
        })
      );
    }
  }, [bulan, satker, tugasPokok, nama]);
  const refFileInput = useRef<any>()
  const [jsonFromExcel, setJsonFormExcel] = useState<any>([])
  const readExcel = (e: any) => {
    setLoadingExcel(true)
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (i) => {
        const data = i.target?.result;
        const wb = XLSX.read(data, { type: "array" });
        const sheetName = wb.SheetNames[0];
        const ws = wb.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(ws);
        if (json.length > 0) {
          setJsonFormExcel(json);
          setTunjangan(json)
        }
      };
      reader.readAsArrayBuffer(e.target.files[0]);
      setLoadingExcel(false)
    }
  };
  return (
    <div className="container-table">
      <Loading show={loadingExcel} />
      {stateKeauangan.bgTaskStatus?.status === true ? (
        <Info />
      ) : (
        <div className=" ">
          <FormApproveGaji
            show={isApproveGaji}
            onClose={() => setIsApproveGaji(false)}
          />
          <div className="shadow-[0_3px_10px_rgb(0,0,0,0.2)] mx-2 mb-5 rounded-md py-2">
            <div className="flex flex-col justify-between gap-4 w-[100%] py-2 px-3 md:px-2 ">
              <div className=" mb-2 flex flex-col md:flex-row gap-2 w-full">
                <div className="w-full z-10">
                  <label htmlFor="bulan">Pilih bulan:</label>
                  <Select
                    id="bulan"
                    placeholder="Pilih bulan"
                    options={optionsBulan}
                    value={bulan}
                    onChange={(i) => setBulan(i)}
                  />
                </div>
                <div className="w-full z-10">
                  <label htmlFor="satker">Pilih satker:</label>
                  <Select
                    id="satker"
                    placeholder="Pilih satker"
                    options={optionSatker}
                    value={satker}
                    onChange={(i) => setSatker(i)}
                  />
                </div>
                <div className="w-full z-10">
                  <label htmlFor="satker">Pilih Tugas Pokok:</label>
                  <Select
                    id="satker"
                    placeholder="Pilih satker"
                    options={optionTugasPokok}
                    value={tugasPokok}
                    onChange={(i) => setTugasPokok(i)}
                  />
                </div>
                <div className="w-full z-10 flex flex-col">
                  <label htmlFor="nama">Cari nama:</label>
                  <input
                    id="nama"
                    className="border border-gray-300 py-[6px] px-3 rounded-md"
                    type="text"
                    value={nama}
                    onChange={(i) => setNama(i.target.value)}
                  />
                </div>
                <div className=" w-full z-10 flex flex-col ">
                  <label htmlFor="nama">Masukkan anggaran:</label>
                  <input
                    type="number"
                    placeholder="Anggaran"
                    className="border border-gray-300 py-[6px] px-3 rounded-md"
                    value={valueAnggaran}
                    onChange={handleInput}
                  />
                </div>
              </div>
              {anggaran ? (
                <p className="-mt-5">
                  {" "}
                  Selisih anggaran: {formatCurrency(anggaran)}
                </p>
              ) : (
                <p className="-mt-5"> Selisih anggaran: {formatCurrency(0)}</p>
              )}
            </div>
            <div className="px-3 my-3 flex w-full justify-end  items-center">
              <div className="flex gap-4">
                {authState.super_admin && authState.role === "superAdmin" && bulan.value !== "thr_gaji13" ? (
                  <button
                    className="btn"
                    onClick={() => setIsApproveGaji(true)}
                  >
                    Setujui gaji
                  </button>
                ) : (
                  ""
                )}

                <button className="btn" onClick={downloadExcel}>
                  Download Excel
                </button>
                {jsonFromExcel.length > 0 ?
                  <button className="btn" onClick={() => {
                    setJsonFormExcel([])
                    setTunjangan([])
                  }}>Hapus Tunjangan</button>
                  :
                  <button className={`btn ${bulan.value === 'thr_gaji13' ? 'block' : 'hidden'}`} onClick={() => refFileInput.current?.click()}>
                    Tambah tunjangan
                  </button>
                }
                <input type="file" ref={refFileInput} className="hidden" onChange={readExcel} />
              </div>
            </div>
          </div>
          <TableContainer>
            <table className="table">
              <thead className="thead ">
                <tr>
                  <th className="th">No</th>
                  <th className="th">nupy</th>
                  <th className="th">Nama</th>
                  <th className="th">Tugas pokok</th>
                  <th className="th">Gol</th>
                  <th className="th">MK</th>
                  <th className="th">nominal tugas pokok</th>
                  <th className="th">Tugas Tambahan</th>
                  <th className="th">Total Nominal tugas tambahan</th>
                  <th className="th">gapok</th>
                  <th className="th">Normalisasi</th>
                  <th className="th">Kehadiran</th>
                  <th className="th">thp</th>
                </tr>
              </thead>
              <tbody className="tbody">
                {gajiPtk?.map((d: any, i: number) => (
                  <tr className="tr" key={i}>
                    <td className="td">{i + 1}</td>
                    <td className="td">{d.nupy}</td>
                    <td className="td">{d.nama}</td>
                    <td className="td">{d.tugas_pokok}</td>
                    <td className="td">{d.golongan}</td>
                    <td className="td">{d.mk}</td>
                    <td className="td">{d.tugas_pokok_nominal ? currency(d.tugas_pokok_nominal):"-"}</td>

                    <td className="td">
                      {d?.tugas_tambahan
                        ? d?.tugas_tambahan?.tugasTambahan?.map(
                          (t: any, index: number) => (
                            <div className="flex gap-3" key={index}>
                              <ul>
                                <li>
                                  {" "}
                                  {t.nama} - <b>{t.nominal ? currency(t.nominal) : "-"}</b>
                                </li>
                              </ul>
                            </div>
                          )
                        )
                        : "-"}
                    </td>

                    <td className="td">
                      {d.tugas_tambahan !== null
                        ? formatCurrency(d.tugas_tambahan?.total)
                        : "-"}
                    </td>
                    <td className="td">{d.gapok ? formatCurrency(d.gapok) : "-"}</td>
                    <td className="td">{d.normalisasi ? formatCurrency(d.normalisasi) : "-"}</td>
                    <td className="td">{d.kehadiran ? formatCurrency(d.kehadiran) : "-"}</td>
                    <td className="td">{jsonFromExcel.length > 0 && jsonFromExcel.find((e: any) => `${e.nupy}` === d.nupy)?.nominal ? formatCurrency(d.thp + parseInt(jsonFromExcel.find((e: any) => `${e.nupy}` === d.nupy)?.nominal)) : d.thp ? currency(d.thp):"-"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TableContainer>
        </div>
      )}
    </div>
  );
}

export default TableGaji;
