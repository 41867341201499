import api from "./api"
export const apiAddress = {
    getProvinsi: ()=>{
        return api.get("/provinsi")
    },
    getKabupaten: (id:string)=>{
        return api.get(`/kabupaten/${id}`)
    },
    getKecamatan: (id:string)=>{
        return api.get(`/kecamatan/${id}`)
    }
}