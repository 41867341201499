import { createSlice } from "@reduxjs/toolkit";
import { allSakter,postSatker,updateSatker,deleteSatker } from "../actions/actionSatker";

type satkerSlice = {
    satkerAll : any[],
    satkerOne : {},
    msgSatker : any,
    loadingSatker : boolean
}

const initialState : satkerSlice = {
    satkerAll : [],
    satkerOne : {},
    msgSatker : "",
    loadingSatker : false
}

export const satkerStore = createSlice({
    name:"satker",
    initialState,
    reducers : {
        clearSatker : (state) =>{
            state.satkerAll = []
            state.msgSatker = ""
        }
    },
    extraReducers : builder => {
        builder
        .addCase(allSakter.pending,(state)=>{
            state.loadingSatker = true
        })
        .addCase(allSakter.fulfilled,(state,action)=>{
            state.loadingSatker = false
            state.satkerAll = action.payload
        })
        .addCase(allSakter.rejected,(state,action)=>{
            state.loadingSatker = false
            state.satkerAll = []
            state.msgSatker = action.payload
        })
        .addCase(postSatker.pending,(state)=>{
            state.loadingSatker = true
        })
        .addCase(postSatker.fulfilled,(state,action)=>{
            state.loadingSatker = false
            state.msgSatker = action.payload.msg
        })
        .addCase(postSatker.rejected,(state,action)=>{
            state.loadingSatker = false
            state.msgSatker = action.payload
        })
        .addCase(updateSatker.pending,(state)=>{
            state.loadingSatker = true
        })
        .addCase(updateSatker.fulfilled,(state,action)=>{
            state.loadingSatker = false
            state.msgSatker = action.payload.msg
        })
        .addCase(updateSatker.rejected,(state,action)=>{
            state.loadingSatker = false
            state.msgSatker = action.payload
        })
        .addCase(deleteSatker.pending,(state)=>{
            state.loadingSatker = true
        })
        .addCase(deleteSatker.fulfilled,(state,action)=>{
            state.loadingSatker = false
            state.msgSatker = action.payload.msg
        })
        .addCase(deleteSatker.rejected,(state,action)=>{
            state.loadingSatker = false
            state.msgSatker = action.error.message
        })
    }
})

export const satkerActions = satkerStore.actions
export default satkerStore.reducer