import { createSlice } from "@reduxjs/toolkit";
import {
  addShowMobile,
  allApprovalByFilter,
} from "../actions/actionsApprovalGaji";
import { ApiStatus } from "../../constants/apiStatus";

type ApprovalGaji = {
  status: ApiStatus;
  allApprovalGaji: string[];
  messageApprovalGaji: any;
  loadingApprovalGaji: boolean;
};

const initialState: ApprovalGaji = {
  status: "IDLE",
  allApprovalGaji: [],
  loadingApprovalGaji: false,
  messageApprovalGaji: "",
};

export const ApprovalGajiStore = createSlice({
  name: "approval_gaji",
  initialState,
  reducers: {
    clearApprovalGaji: (state) => {
      state.messageApprovalGaji = "";
      state.allApprovalGaji = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(allApprovalByFilter.pending, (state) => {
        state.loadingApprovalGaji = true;
        state.status = "PENDING";
      })
      .addCase(allApprovalByFilter.fulfilled, (state, action) => {
        state.loadingApprovalGaji = false;
        state.status = "SUCCESS";
        state.allApprovalGaji = action.payload;
      })
      .addCase(allApprovalByFilter.rejected, (state, action) => {
        state.loadingApprovalGaji = false;
        state.status = "ERROR";
        state.messageApprovalGaji = action.payload;
      })
      .addCase(addShowMobile.pending, (state) => {
        state.loadingApprovalGaji = true;
        state.status = "PENDING";
      })
      .addCase(addShowMobile.fulfilled, (state, action) => {
        state.loadingApprovalGaji = false;
        state.status = "SUCCESS";
        state.messageApprovalGaji = action.payload?.msg;
      })
      .addCase(addShowMobile.rejected, (state, action) => {
        state.loadingApprovalGaji = false;
        state.status = "ERROR";
        state.messageApprovalGaji = action.payload;
      });
  },
});

export const ApprovalGajiActions = ApprovalGajiStore.actions;
export default ApprovalGajiStore.reducer;
