import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiApprovalGaji } from "../../api/apiApprovalGaji";

export const allApprovalByFilter = createAsyncThunk(
    'gaji/approval/filter',
    async(data:TPostApprovalGaji,{rejectWithValue}:any)=>{
        try{
            const res = await ApiApprovalGaji.getByFilter(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue(err.response.data.msg)
        }
    }
) 

export const addShowMobile = createAsyncThunk(
    'gaji/approval/show-mobile',
    async(bulan:string,{rejectWithValue})=>{
        try{
            const res = await ApiApprovalGaji.postShowMobile(bulan)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue(err.response.data.msg)
        }
    }
)