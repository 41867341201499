import { createSlice } from "@reduxjs/toolkit";
import { allUser,addSistem,addUser,dropSistem,dropUser,editByAdmin,editSelf,editSistemOne } from "../actions/actionUser";
import { ApiStatus } from "../../constants/apiStatus";

type User ={
    userAll: any,
    userOne: any,
    loadingUser: boolean,
    msgUser: any
    status : ApiStatus
}

const initialState : User = {
    userAll : [],
    userOne : {},
    loadingUser : false,
    msgUser : "",
    status : "IDLE"
}
export const userStore = createSlice({
    name:"user",
    initialState,
    reducers : {
        clearUser : (state)=>{
            state.userAll = []
            state.msgUser = ""
        },
        clearOneUser : (state)=>{
            state.userOne = {}
            state.msgUser = ""
        }
    },
    extraReducers:builder=>{
        builder
        .addCase(allUser.pending,(state)=>{
            state.loadingUser = true
            state.status = "PENDING"
        })
        .addCase(allUser.fulfilled,(state,action)=>{
            state.loadingUser = false
            state.userAll = action.payload
            state.status = "SUCCESS"
        })
        .addCase(allUser.rejected,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload
            state.status = "ERROR"
        })
        .addCase(addUser.pending,(state)=>{
            state.loadingUser = true
            state.status = "PENDING"
        })
        .addCase(addUser.fulfilled,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload.msg
            state.status = "SUCCESS"
        })
        .addCase(addUser.rejected,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload
            state.status = "ERROR"
        })
        .addCase(editSelf.pending,(state)=>{
            state.loadingUser = true
            state.status = "PENDING"
        })
        .addCase(editSelf.fulfilled,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload.msg
            state.status = "SUCCESS"
        })
        .addCase(editSelf.rejected,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload
            state.status = "ERROR"
        })
        .addCase(editByAdmin.pending,(state)=>{
            state.loadingUser = true
            state.status = "PENDING"
        })
        .addCase(editByAdmin.fulfilled,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload.msg
            state.status = "SUCCESS"
        })
        .addCase(editByAdmin.rejected,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload
            state.status = "ERROR"
        })
        .addCase(dropUser.pending,(state)=>{
            state.loadingUser = true
            state.status = "PENDING"
        })
        .addCase(dropUser.fulfilled,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload.msg
            state.status = "SUCCESS"
        })
        .addCase(dropUser.rejected,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload
            state.status = "ERROR"
        })
        .addCase(addSistem.pending,(state)=>{
            state.loadingUser = false
            state.status = "PENDING"
        })
        .addCase(addSistem.fulfilled,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload.msg
            state.status = "SUCCESS"
        })
        .addCase(addSistem.rejected,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload
            state.status = "ERROR"
        })
        .addCase(dropSistem.pending,(state)=>{
            state.loadingUser = false
            state.status = "PENDING"
        })
        .addCase(dropSistem.fulfilled,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload.msg
            state.status = "SUCCESS"
        })
        .addCase(dropSistem.rejected,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload
            state.status = "ERROR"
        })
        .addCase(editSistemOne.pending,(state)=>{
            state.loadingUser = false
            state.status = "PENDING"

        })
        .addCase(editSistemOne.fulfilled,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload.msg
            state.status = "SUCCESS"
        })
        .addCase(editSistemOne.rejected,(state,action)=>{
            state.loadingUser = false
            state.msgUser = action.payload
            state.status = "ERROR"
        })
    }
})
export const userActions =userStore.actions
export default userStore.reducer