import { createSlice } from "@reduxjs/toolkit"
import { deletePegawai, detailPegawai, getGolongan, getPegawai, postPegawai, searchPegawai, updatePegwawai, updateStatusPTK } from "../actions/actionPTK"

type ApiStatus = 'IDLE' | 'PENDING' | 'SUCCESS' | 'ERROR'
type PTKState = {
    pegawai:TPegawaiType[]
    selectedPegawai: TPegawaiType |null
    statusPegawai: TStatusPTK | null
    golongan: TGolongan[] 
    isLoading:boolean
    status: ApiStatus
    message:any
}

const initialState:PTKState = {
    pegawai:[],
    statusPegawai:null,
    selectedPegawai:null,
    golongan:[],
    isLoading:false,
    status: "IDLE",
    message:[]
}

export const ptkSlice = createSlice({
    name:"ptk",
    initialState,
    reducers:{
        clearPtk: (state)=>{
            state.pegawai=[]
        }
    },
    extraReducers: builder => {
        builder
        .addCase(getPegawai.pending,(state)=>{
            state.isLoading=true
            state.status = "PENDING"
        })
        .addCase(getPegawai.fulfilled,(state,action)=>{
            state.isLoading = false
            state.pegawai = action.payload
            state.status = "SUCCESS"
        })
        .addCase(getPegawai.rejected,(state, action)=>{
            state.isLoading = false
            state.status = "ERROR"
            state.message = action.error.message || "Failed to fetch data"
        })
        .addCase(postPegawai.pending,(state)=>{
            state.isLoading = true
            state.status = "PENDING"
        })
        .addCase(postPegawai.fulfilled,(state, action)=>{
            state.isLoading = false
            state.message =action.payload
            state.status = "SUCCESS"
        })
        .addCase(postPegawai.rejected,(state, action)=>{
            state.isLoading = false
            state.message = action.error.message
            state.status = "ERROR"
        })
        .addCase(deletePegawai.pending,(state)=>{
            state.isLoading = true
            state.status = "PENDING"
        })
        .addCase(deletePegawai.fulfilled,(state, action)=>{
            state.isLoading = false
            state.message = action.payload
            state.status = "SUCCESS" 
        })
        .addCase(deletePegawai.rejected, (state, action)=>{
            state.isLoading = false
            state.message = action.error.message || "Failed to fetch data"
            state.status = "ERROR"
        })
        .addCase(searchPegawai.pending,(state)=>{
            state.isLoading = true
            state.status = "PENDING"
        })
        .addCase(searchPegawai.fulfilled,(state, action)=>{
            state.isLoading = false
            state.pegawai = action.payload
            state.status = "SUCCESS"
        })
        .addCase(searchPegawai.rejected,(state, action)=>{
            state.isLoading=false
            state.message= action.error.message
            state.status = "ERROR"
        })
        .addCase(detailPegawai.pending,(state)=>{
            state.isLoading = true
            state.status = "PENDING"
        })
        .addCase(detailPegawai.fulfilled,(state, action)=>{
            state.isLoading = false
            state.selectedPegawai = action.payload
            state.status = "SUCCESS"
        })
        .addCase(detailPegawai.rejected, (state, action)=>{
            state.isLoading = false
            state.message = action.error.message
            state.status = "ERROR"
        })
        .addCase(updatePegwawai.pending,(state)=>{
            state.isLoading = true
            state.status = "PENDING"
        })
        .addCase(updatePegwawai.fulfilled,(state, action)=>{
            state.isLoading = false
            state.status = "SUCCESS"
            state.message = action.payload
        })
        .addCase(updatePegwawai.rejected,(state, action)=>{
            state.isLoading = false
            state.status = "ERROR"
            state.message = action.error.message
        })
        .addCase(updateStatusPTK.pending,(state)=>{
            state.isLoading = true
            state.status = "PENDING"
        })
        .addCase(updateStatusPTK.fulfilled,(state, action)=>{
            state.isLoading = false
            state.message = action.payload.msg
            state.status = "SUCCESS"
        })
        .addCase(updateStatusPTK.rejected,(state, action)=>{
            state.isLoading = false
            state.status = "ERROR"
            state.message = action.error.message
        })
        .addCase(getGolongan.pending,(state)=>{
            state.isLoading = true
            state.status = "PENDING"
        })
        .addCase(getGolongan.fulfilled,(state, action)=>{
            state.isLoading = false
            state.status = "SUCCESS"
            state.golongan = action.payload
        })
        .addCase(getGolongan.rejected,(state, action)=>{
            state.isLoading = false
            state.status = "ERROR"
            state.message = action.error.message
        })
    }
})

export const clearStatePtk = ptkSlice.actions

export default ptkSlice.reducer