import React, { useEffect, useState } from "react";
import HeaderUI from "../components/HeaderUI";
import TableContainer from "../components/TableContainer";
import Select from "react-select";
import { AppThunkDispatch, useAppSelector } from "../store";
import InputText from "../components/elements/InputText";
import { useDispatch } from "react-redux";
import { allLembaga } from "../store/actions/actionOptions";
import Button from "../components/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputSelect from "../components/elements/InputSelect";
import { getDaftarHadirByFilter } from "../store/actions/actionDaftarHadir";
import moment from "moment";
import Loading from "../components/Loading";
import "moment/locale/id";
import { formatWithDay } from "../helpers/formatTIme";

function DaftarHadir() {
  const [lembagaOpt, setLembagaOpt] = useState<any>([]);
  const authState = useAppSelector((state) => state.auth);
  const { lembagaAll } = useAppSelector((state) => state.options);
  const [id, setId] = useState(0)
  const { isLoading, message, allKehadiran } = useAppSelector(
    (state) => state.daftarHarir
  );
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(
      yup.object().shape({
        kode_lembaga: yup.string(),
        start: yup.string().required("tidak boleh kosong"),
        end: yup.string().required("tidak boleh kosong"),
      })
    ),
    defaultValues: { kode_lembaga: "-" },
  });
  const dispatch = useDispatch<AppThunkDispatch>();
  const filterDaftarHadir = (e: any) => {
    dispatch(getDaftarHadirByFilter(e));
  };
  let exceptLembaga: any = [
    "DAPUR",
    "KEAMANAN",
    "UKS",
    "ASRAMA PUTRA",
    "ASRAMA PUTRI",
  ];

  useEffect(() => {
    let lembaga: any = [];
    lembagaAll.map((d: any) => {
      if (!exceptLembaga.includes(d.nama)) {
        lembaga.push({ value: d.kode_lembaga, label: d.nama });
      }
    });
    setLembagaOpt(lembaga);
    lembaga = [];
  }, [lembagaAll]);

  useEffect(() => {
    dispatch(allLembaga());
  }, []);
  useEffect(() => { }, []);
  let no = 0
  return (
    <div>
      <HeaderUI title="Daftar Hadir" />
      <Loading show={isLoading} />
      <div className="container-table flex flex-col gap-4">
        <form
          onSubmit={handleSubmit(filterDaftarHadir)}
          className="flex flex-col md:flex-row shadow-md rounded-md md:gap-4 py-2 px-3 md:px-4 mx-2 md:mx-3"
        >
          {authState.role === "superAdmin" ||
            authState.role === "personalia" ?
            <div className="w-full ">
              <label htmlFor="bulan">Pilih Lembaga:</label>
              <InputSelect
                placeholder="Lembaga"
                options={lembagaOpt}
                control={control}
                Name="kode_lembaga"
                error={errors.kode_lembaga?.message}
              />
            </div>
            :
            <></>
          }
          <InputText
            Name="start"
            type="date"
            labelName="Dari:"
            placeholder="Tanggal"
            {...register("start")}
            error={errors.start?.message}
          />
          <InputText
            Name="end"
            type="date"
            labelName="Sampai:"
            placeholder="Tanggal"
            {...register("end")}
            error={errors.end?.message}
          />

          <div className="mt-4 flex items-end md:pb-1 w-full justify-end">
            <Button title="Filter" type="submit" />
          </div>
        </form>

        <div className="">
          <TableContainer>
            {allKehadiran.length !== 0 ? (
              <table className="table">
                <thead className="thead">
                  <tr>
                    <th className="th">No</th>
                    <th className="th">Masuk</th>
                    <th className="th">Pulang</th>
                    <th className="th">Nupy</th>
                    <th className="th">Nama</th>
                  </tr>
                </thead>
                <tbody className="tbody">
                  {allKehadiran.map((d: any, i: number) => {
                    return d.absensi_mobile_users?.map((e: any, idx: number) => {
                      if (i > 0 && idx === 0) {
                        no += allKehadiran[i - 1].absensi_mobile_users?.length
                      }
                      else if (i === 0) {
                        no = 0
                      }
                      return <tr className="tr" key={idx}>
                        <td className="td">{idx + 1 + no}</td>
                        <td className="td">{e.start
                          ? formatWithDay(e.start)
                          : "Tidak Absen Masuk"
                        }</td>
                        <td className="td">{e.end
                          ? formatWithDay(e.end)
                          : "Tidak Absen Pulang"
                        }</td>
                        <td className="td">{d.nupy}</td>
                        <td className="td">{d.nama}</td>
                      </tr>
                    })
                  })}
                </tbody>
              </table>
            ) : (
              <table className="table">
                <thead className="thead">
                  <tr>
                    <th className="th">No</th>
                    <th className="th">Masuk</th>
                    <th className="th">Pulang</th>
                    <th className="th">Nupy</th>
                    <th className="th">Nama</th>
                  </tr>
                </thead>
                <tbody className="tbody"></tbody>
              </table>
            )}
          </TableContainer>
        </div>
      </div>
    </div>
  );
}

export default DaftarHadir;
