import React from "react";
import TableTugasTambahan from "../components/tables/TableTugasTambahan";
import { useAppSelector } from "../store";
import Loading from "../components/Loading";
import HeaderUI from "../components/HeaderUI";

function TugasTambahan() {
  const loading = useAppSelector((state) => state.tugasTambahan.isLoading);

  return (
    <div>
      <Loading show={loading} />
      <HeaderUI title="Tugas Tambahan" />
      <TableTugasTambahan />
    </div>
  );
}

export default TugasTambahan;
